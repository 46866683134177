import moment from 'moment'

export default function formatDate ({date, format = "DD/MM/YYYY"}) {
  // check if date is not valid return empty string
  if (!date) {
    return ""
  }

  const tempDate = new Date(date);
  return moment(tempDate).format(format)
}