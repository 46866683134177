import React, { useState, createContext, useContext } from 'react';
import BackDropSpinner from '../../backdrop-spinner';
import AlertPopup from '../../popup/alert-popup';
import PromptPopup, { defaultPromptPopupProps } from '../../popup/prompt-popup';

const PopupContext = createContext( {
  showSpinner: () => {},
  hideSpinner: () => {},
  showAlertPopup: () => {},
  showPromptPopup: () => {},
} );

const usePopup = () => useContext(PopupContext);

const PopupProvider = ( {children} ) => {
  // const classes = useStyles();
  const [spinner, setSpinner] = useState( { open: false } );
  const [promptPopup, setPromptPopup] = useState( {
    open: defaultPromptPopupProps.open,
    handleClose: defaultPromptPopupProps.handleClose, 
    handleConfirm: defaultPromptPopupProps.handleConfirm, 
    updateMode: defaultPromptPopupProps.updateMode, 
    Icon: defaultPromptPopupProps.Icon, 
    title: defaultPromptPopupProps.title,
    message: defaultPromptPopupProps.message, 
  } );
  const [alertPopupState, setAlertPopupState] = useState( {
    open: false,
    success: true,
    message: '',
    onExiting: () => {},
  } );

  const showAlertPopup = ( {
    success = true,
    message,
    onExiting = () => {},
  } ) => {
    setAlertPopupState( {
      open: true,
      success,
      message,
      onExiting,
    } );
  };

  const handleCloseAlertPopup = () => {
    setAlertPopupState( { ...alertPopupState, open: false } );
  };

  const showPromptPopup = ( {
    handleClose = defaultPromptPopupProps.handleClose,
    handleConfirm = defaultPromptPopupProps.handleConfirm,
    updateMode = defaultPromptPopupProps.updateMode,
    Icon = defaultPromptPopupProps.Icon,
    title = defaultPromptPopupProps.title,
    message = defaultPromptPopupProps.message,
  } ) => {
    setPromptPopup({
      ...promptPopup,
      handleClose,
      handleConfirm,
      updateMode,
      Icon,
      title,
      message,
      open: true,
    })
  }

  const handleClosePromptPopup = ( ) => {
    if ( typeof promptPopup.handleClose === "function" ) {
      promptPopup.handleClose();
    }
    setPromptPopup({
      ...promptPopup,
      open: false,
    })
  }

  const handleConfirmPromptPopup = ( ) => {
    if ( typeof promptPopup.handleConfirm === "function" ) {
      promptPopup.handleConfirm();
    }
    setPromptPopup({
      ...promptPopup,
      open: false,
    })
  }

  const showSpinner = ( ) => {
    setSpinner( { ...spinner, open: true } );
  };

  const hideSpinner = () => {
    setSpinner( { ...spinner, open: false } );
  };

  return (
    <PopupContext.Provider
      value={{
        showAlertPopup,
        showSpinner,
        hideSpinner,
        showPromptPopup,
      }}
    >
      {children}
      <AlertPopup
        open={alertPopupState.open}
        success={alertPopupState.success}
        message={alertPopupState.message}
        onExiting={alertPopupState.onExiting}
        handleClose={handleCloseAlertPopup}
      />
      <BackDropSpinner open={spinner.open}/>
      <PromptPopup
        open={promptPopup.open}
        handleClose={handleClosePromptPopup}
        handleConfirm={handleConfirmPromptPopup}
        updateMode={promptPopup.updateMode}
        Icon={promptPopup.Icon}
        title={promptPopup.title}
        message={promptPopup.message}
      />
    </PopupContext.Provider>
  );
};

export { usePopup, PopupProvider }