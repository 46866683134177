import DepositSlipTemplate from "../printing-page/default-template/deposit-slip";

const getDepositSlipTemplate = ({
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  image,
}) => {
  return (
    <DepositSlipTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      ref={ref}
      data={data}
      image={image}
    />
  );
};

export { getDepositSlipTemplate }
  