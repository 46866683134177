import PaymentVoucherTemplate from "../printing-page/default-template/payment-voucher";
import GoodsReceivedNoteTemplate from "../printing-page/default-template/goods-received-note";

const getPaymentVoucherTemplate = ({
  name,
  data,
  footHeights,
  contentHeights,
  exchangeRate,
  accountName,
  printScale,
  allTax,
  payment,
  image,
}) => {
  return (
    <PaymentVoucherTemplate
      printScale={printScale}
      data={data}
      footHeights={footHeights}
      contentHeights={contentHeights}
      exchangeRate={exchangeRate}
      accountName={accountName}
      allTax={allTax}
      payment={payment}
      image={image}
    />
  );
}

/**
 * template for goods-received-note
 */ 
const getGoodsReceivedNoteTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  image,
}) => {
  return (
    <GoodsReceivedNoteTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      ref={ref}
      data={data}
      image={image}
    />
  );
};

export { getPaymentVoucherTemplate, getGoodsReceivedNoteTemplate }
