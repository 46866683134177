import React, { useRef, useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Input,
  makeStyles,
  Switch,
  Button,
  TextField,
  Link as MUILink,
  debounce,
  IconButton,
} from "@material-ui/core";
import NumberFormat from "react-number-format";

// Icons
import RefreshIcon from "@material-ui/icons/Refresh";
import PrintIcon from "@material-ui/icons/Print";

import useQuery from "../../components/custom-hook/use-query";
import CustomTable, { paginationConfig } from "../../components/custom-table";
import DateTimeRangePicker from "../../components/date-time-range-picker";
import { usePopup } from "../../components/context/popup-context";
import CreateDeliveryModal from "../invoice/components/create-delivery-modal";
// import CreateDeliveryButton from '../invoice/components/create-delivery-button';
import QuoteActionButton from "./quote-action-button";
import settingApi from "../../api/setting";
import PrintButton from "../../components/print-button";
import { getQuoteTemplate, getPickingSlipTemplate } from "./getTemplates";

const useStyles = makeStyles((theme) => ({
  container: {},
  datePicker: {
    border: 'none',
    borderBottom: '1px solid grey',
    padding: '0.42em',
    fontSize: 16,
    zIndex: 100000000000000,
    width: '100%',
  },
  dataPickerWrapper: {
    width: '100%',
  },
  dataPickerPopper: {
    zIndex: 9,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  scaleSwitch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  switchLabel: {
    lineHeight: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  loadingContainer: {
    borderBottom: 'none',
  },
  printButtonContainer: {
    padding: 2,
  },
  dateFilter: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  printButtonSection: {
    marginTop: theme.spacing(1) / 2, // theme.spacing(1) = 8px
  },
}));

const QuotePage = () => {
  const classes = useStyles();
  const {
    data,
    error,
    query,
    loading: fetchLoading,
  } = useQuery(`${process.env.REACT_APP_SERVER_URI}/quote`);
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const [createDelivery, setCreateDelivery] = useState({ open: false });
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const [companyLogo, setCompanyLogo] = useState(null);
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const invoiceName = JSON.parse(localStorage.getItem("invoiceName") || '""'); // prevent error when don't have "invoiceName" in local storage
  const [selectedQuotes, setSelectedInvoices] = useState([]);
  const isSelectedQuoteAccepted = !selectedQuotes.some(quote => quote.status.toLowerCase() !== 'accepted');
  const [quoteData, setQuoteData] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(
    Number(JSON.parse(localStorage.getItem("exchangeRateInvoicesBills"))) ||
      4000
  );
  const [printScale, setPrintScale] = useState(false);
  const [date, setDate] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });
  const textFieldRef = useRef(null);

  const shouldDisablePrintButton =
    selectedQuotes.length <= 0 || exchangeRate === null || exchangeRate === "";

  const handleOpenCreateDelivery = () => {
    setCreateDelivery((prev) => ({ ...prev, open: true }));
  };
  const handleCloseCreateDelivery = () => {
    setCreateDelivery((prev) => ({ ...prev, open: false }));
  };

  const handlePrintScaleSwitch = (e) => {
    setPrintScale(e.target.checked);
  };

  const handleExchangeRateChange = (values) => {
    setExchangeRate(values.value);
    localStorage.setItem(
      "exchangeRateInvoicesBills",
      JSON.stringify(values.value)
    );
  };

  const handleFetchQuoteData = async ({
    searchProps = null,
    pageProps = pagination.page,
    perPageProps = pagination.rowsPerPage,
    startDateProps = date?.startDate,
    endDateProps = date?.endDate,
  } = {}) => {
    try {
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // preserve searchProps when changing pagination
        ...(isNullish ? {} : { search: searchProps }),
        page: pageProps,
        perPage: perPageProps,
        startDate: startDateProps?.getTime(),
        endDate: endDateProps?.getTime(),
      });
      setPagination((pre) => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }));
    } catch (err) {
      console.error(err);
    }
  };
  const debouncedQuoteResults = useMemo(() => {
    return debounce((text) => {
      handleFetchQuoteData({ searchProps: text, pageProps: 0 });
    }, 500);
  }, []);

  const handleRefreshQuote = async () => {
    try {
      showSpinner();
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/quote/sync`, {
        withCredentials: true,
      });
      handleFetchQuoteData({ searchProps: null, pageProps: 0 });
    } catch (err) {
      console.error(err?.response);
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Invoice`,
        success: false,
      });
    } finally {
      hideSpinner();
    }
  };
  const handleChangeQuotePage = (event, page) => {
    handleFetchQuoteData({ searchProps: null, pageProps: page });
  };
  const handleChangeQuoteRowsPerPage = (event) => {
    handleFetchQuoteData({
      searchProps: null,
      pageProps: 0,
      perPageProps: parseInt(event.target.value, 10),
    });
  };

  const handleBatchInvoiceContent = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/quote/get-selected-quote`,
        {
          withCredentials: true,
          params: {
            quoteID: selectedQuotes.map((quote) => quote.quoteID),
          },
          paramsSerializer: (params) => {
            let queryString = '';
            params.quoteID.forEach((item) => {
              queryString += `quoteID=${item}&`;
            });
            return queryString;
          },
        }
      );
      const { data: response } = await settingApi.getSingleTenant(
        tenant.tenantId
      );
      setCompanyLogo(response?.logo?.tempUrl || null);
      setQuoteData(res.data.quotes);
    } catch (err) {
      console.error("Failed to fetch the selected quote", err);
      throw err; // cancel print event
    }
  };

  // for only one print
  const handlePrintInvoiceContent = async (quoteID) => {
    const arrayQuoteID = [];
    arrayQuoteID.push({ quoteID })
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/quote/get-selected-quote`,
        {
          withCredentials: true,
          params: {
            quoteID: arrayQuoteID.map((quote) => quote.quoteID),
          },
          paramsSerializer: (params) => {
            const queryString = `quoteID=${params.quoteID}`;
            return queryString;
          },
        }
      );
      const { data: response } = await settingApi.getSingleTenant(
        tenant.tenantId
      );
      setCompanyLogo(response?.logo?.tempUrl || null);
      setQuoteData(res.data.quotes);
    } catch (err) {
      console.error("Failed to fetch the selected quote", err);
      throw err; // cancel print event
    }
  };

  useEffect(() => {
    handleFetchQuoteData();
  }, [organization, date, trigger]);
  useEffect(() => {
    if (data) {
      setPagination((pre) => ({ ...pre, count: data.totalDocs }));
    }
  }, [data]);

  const schema = [
    {
      label: 'Quote No.',
      field: 'quoteNumber',
    },
    {
      label: 'Quote Date',
      field: 'date',
      render: (quote) => new Date(quote.date).toLocaleDateString('en-GB'),
    },
    {
      label: 'Contact Name',
      field: 'contact.name',
      render: (quote) => (
        <MUILink
          component={Link}
          to={`/contacts/create/${quote?.contact?._id}`}
          replace
        >
          {quote?.contact?.name}
        </MUILink>
      ),
    },
    {
      label: 'Sub Totals',
      tableCellProps: { align: 'center' },
      field: 'subTotal',
      render: (quote) => quote.subTotal.toLocaleString(),
    },
    {
      label: 'Tax Total',
      tableCellProps: { align: 'center' },
      field: 'totalTax',
      render: (quote) => quote.totalTax.toLocaleString(),
    },
    {
      label: 'Total',
      tableCellProps: { align: 'center' },
      field: 'total',
      render: (quote) => quote.total.toLocaleString(),
    },
    {
      label: 'Status',
      tableCellProps: { align: 'center' },
      field: 'status',
      render: (quote) => quote.status.toLocaleString(),
    },
    {
      label: "Action",
      render: (quote) => {
        return (
          <Grid container display="flex" alignItems="center">
            <PrintButton
              buttonLabel="Quotation"
              onBeforeGetContent={() => handlePrintInvoiceContent(quote.quoteID)}
              disabled={0}
              // showTemplate
              printComponent={() =>
                getQuoteTemplate({
                  contentHeights: [],
                  footerHeight: 0,
                  signatureHeight: 0,
                  data: quoteData,
                  printScale: printScale,
                  exchangeRate: exchangeRate,
                  quoteType: "quote",
                  name: invoiceName,
                  image: companyLogo,
                })
              }
            />
            <QuoteActionButton quoteData={quote} query={handleFetchQuoteData} />
          </Grid>
        );
      },
    },
  ];

  return (
    <Container>
      <h1>QUOTES</h1>
      <Grid container spacing={1}>
        <Grid container item spacing={0} direction="column" sm={12} md={6}>
          <Grid item>
            <Typography>Exchange Rate</Typography>
            <NumberFormat
              customInput={Input}
              thousandSeparator={true}
              suffix={' ៛'}
              placeholder="Exchange Rate (៛)"
              style={{ width: '100%' }}
              value={exchangeRate}
              onValueChange={(values) => {
                handleExchangeRateChange(values);
              }}
              error={
                selectedQuotes.length > 0 &&
                (exchangeRate === null || exchangeRate === '')
              }
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Search :</Typography>
            <TextField
              inputRef={textFieldRef}
              onChange={(event) => debouncedQuoteResults(event.target.value)}
              fullWidth
              placeholder="Filter by No. and Contact"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.dateFilter}>
            <DateTimeRangePicker
              date={date}
              elevation={2}
              setDate={setDate}
              onAfterChange={(props) => {
                return handleFetchQuoteData({
                  pageProps: 0,
                  startDateProps: props?.startDate,
                  endDateProps: props?.endDate,
                });
              }}
            />
          </Grid>

          {/* <Grid container item spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: 'capitalize', }}
                startIcon={<RefreshIcon />}
                onClick={handleRefreshQuote}
              >
                Sync
              </Button>
            </Grid>
            <Grid item>
              <CreateDeliveryButton
                onClick={handleOpenCreateDelivery}
                disabled={selectedQuotes.length <= 0 || organization.apiKey === ''}
              />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item container className={classes.printButtonSection}>
        <PrintButton
          buttonLabel="Quotation"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          // showTemplate
          printComponent={() =>
            getQuoteTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: quoteData,
              printScale: printScale,
              exchangeRate: exchangeRate,
              quoteType: "quote",
              name: invoiceName,
              image: companyLogo,
            })
          }
        />
        {invoiceName === "a-klasse-auto" && (
          <PrintButton
            buttonLabel="Part Picking Slip"
            onBeforeGetContent={() => handleBatchInvoiceContent()}
            disabled={!isSelectedQuoteAccepted || shouldDisablePrintButton}
            printComponent={() =>
              getPickingSlipTemplate({
                contentHeights: [],
                footerHeight: 0,
                signatureHeight: 0,
                data: quoteData,
                image: companyLogo,
              })
            }
          />
        )}
      </Grid>
      <Grid container>
        <Grid container item xs="auto">
          <Typography className={classes.switchLabel}>
            Print With Scale :
          </Typography>
          <Switch
            className={classes.switch}
            color="primary"
            onChange={handlePrintScaleSwitch}
            checked={printScale}
          />
        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs}
        loading={fetchLoading}
        error={error}
        tableSize="medium"
        disableCheckBoxSelectPopup
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeQuotePage,
          onChangeRowsPerPage: handleChangeQuoteRowsPerPage,
        }}
        getSelectedRow={(arrayValue) => setSelectedInvoices(arrayValue)}
      />
      <CreateDeliveryModal
        open={createDelivery.open}
        handleClose={handleCloseCreateDelivery}
        selectedQuotes={selectedQuotes}
      />
    </Container>
  );
};

export default QuotePage;
