import React from 'react';
import { Button } from '@material-ui/core';

const ButtonWithXeroImage = ({ children, variant = 'contained', color = 'primary', onClick, ...other }) => {
  return (
    <Button variant={variant} color={color} disableElevation onClick={onClick} {...other}>
      <img src='/xero_logo.svg' width='40px' style={{ paddingRight: '10px' }} alt='xero_logo' />
      {children}
    </Button>
  );
};

export default ButtonWithXeroImage;
