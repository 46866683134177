import React, { useState } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Grid,
	TextField,
	Chip,
	Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { RHFSelectInput } from '../../../../components/rhf-controlled-input';

const priorityOptions = [
	{ label: 'Regular', value: '0' },
	{ label: 'Express', value: '10' },
	{ label: 'On Demand', value: '100' },
];

const sizeOptions = [
	{ label: 'Small', value: 'SMALL' },
	{ label: 'Medium', value: 'MEDIUM' },
	{ label: 'Large', value: 'LARGE' },
	{ label: 'Extra', value: 'EXTRA' },
];

const yesNoOptions = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

const CreateDeliveryModal = ({ open, handleClose, selectedInvoices = [] }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, control, reset } = useForm({
		defaultValues: {
			priority: 'Delivery Priority',
			size: 'Delivery Size',
			isSenderPaying: 'Sender Paying',
			isCashOnDelivery: 'Cash on Delivery',
			isBulkPickupRequest: 'Bulk Pickup',
			commentToDriver: '',
		},
	});
	const { organization } = useSelector(state => state.organizationReducer);

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async data => {
		try {
			setIsLoading(true);

			data.commentToDriver = `Invoices number: ${selectedInvoices
				.map(invoice => invoice.invoiceNumber)
				.join(', ')}`;

			await axios.post(`${process.env.REACT_APP_TADA_API_URL}/api/delivery`, data, {
				headers: {
					Authorization: organization.apiKey,
				},
			});

			enqueueSnackbar('Create delivery successfully', { variant: 'success' });
			setIsLoading(false);
		} catch (error) {
			console.error('error create delivery :>> ', error);
			enqueueSnackbar('Create delivery fail', { variant: 'error' });
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth='md'
			TransitionProps={{
				onExited: () => reset(),
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>Create Delivery</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography gutterBottom style={{ fontWeight: 'bold' }}>
								Invoices Number
							</Typography>
							<TextField
								variant='outlined'
								size='small'
								fullWidth
								inputProps={{
									readOnly: true,
									disabled: true,
								}}
								InputProps={{
									startAdornment: selectedInvoices.map(invoice => {
										return (
											<Chip
												style={{ marginRight: '0.25rem' }}
												key={invoice.invoiceID}
												label={invoice.invoiceNumber}
												size='small'
											/>
										);
									}),
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<RHFSelectInput
								name='priority'
								label='Delivery Priority'
								rules={{
									validate: value => value !== 'Delivery Priority',
								}}
								control={control}
								options={[{ label: 'Delivery Priority', value: 'Delivery Priority' }, ...priorityOptions]}
							/>
						</Grid>
						<Grid item xs={4}>
							<RHFSelectInput
								name='size'
								label='Delivery Size'
								rules={{
									validate: value => value !== 'Delivery Size',
								}}
								control={control}
								options={[{ label: 'Delivery Size', value: 'Delivery Size' }, ...sizeOptions]}
							/>
						</Grid>
						<Grid item xs={4}>
							<RHFSelectInput
								name='isSenderPaying'
								label='Sender Paying'
								control={control}
								rules={{
									validate: value => value !== 'Sender Paying',
								}}
								options={[{ label: 'Sender Paying', value: 'Sender Paying' }, ...yesNoOptions]}
							/>
						</Grid>
						<Grid item xs={4}>
							<RHFSelectInput
								name='isCashOnDelivery'
								label='Cash on Delivery'
								control={control}
								required={false}
								rules={{
									validate: value => value !== 'Cash on Delivery',
								}}
								options={[{ label: 'Cash On Delivery', value: 'Cash on Delivery' }, ...yesNoOptions]}
							/>
						</Grid>
						<Grid item xs={4}>
							<RHFSelectInput
								name='isBulkPickupRequest'
								label='Bulk Pickup'
								control={control}
								rules={{
									validate: value => value !== 'Bulk Pickup',
								}}
								options={[{ label: 'Bulk Pickup', value: 'Bulk Pickup' }, ...yesNoOptions]}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='secondary' disabled={isLoading}>
						Cancel
					</Button>
					<Button color='primary' variant='contained' type='submit' disabled={isLoading}>
						Create
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default CreateDeliveryModal;
