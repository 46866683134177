import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Slide,
} from '@material-ui/core';
import clsx from 'clsx';
import red from '@material-ui/core/colors/red';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: 'none',
    padding: 5,
  },
  logout: {
    color: '#fff',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  countdown: {
    color: red[700],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
  const classes = useStyles();
  const { loading } = useSelector(state => state.userReducer);

  const seconds = Math.floor(countdown % 60);
  const minutes = Math.floor(countdown / 60);
  const hours = Math.floor(countdown / 3600);

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialogPaper }}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Session Timeout</DialogTitle>
      <DialogContent>
        <Typography variant='body2'>
          The current session is about to expire in <span className={classes.countdown}>{hours}</span> hour(s)
          : <span className={classes.countdown}>{minutes >= 10 ? minutes : `0${minutes}`}</span> minute(s) :{' '}
          <span className={classes.countdown}>{seconds >= 10 ? seconds : `0${seconds}`}</span> second(s).
        </Typography>
        <Typography variant='body2'>{`Would you like to continue the session?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={onLogout}
          variant='contained'
          className={clsx(classes.logout, classes.button)}
        >
          {loading ? 'Disconnecting' : 'Disconnect'}
        </Button>
        <Button
          disabled={loading}
          onClick={onContinue}
          color='primary'
          variant='contained'
          className={classes.button}
        >
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
