import { Divider, Grid, Box, TextField, Tooltip } from '@material-ui/core';

import { RHFTextInput } from '../../components/rhf-controlled-input';

const CompanyInfo = ({ data }) => {
	const { taxNumber, phone, companyRegistrationNumber } = data
	const areaCountry = {
		value: phone?.phoneAreaCode ?? phone?.phoneCountryCode,
		label: phone?.phoneAreaCode ? 'Area' : 'Country'
	}

	return (
		<Box mt={1}>
			<h2>Company Information - Khmer Version</h2>
			<Divider />
			<br />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<RHFTextInput name='company.name' label='Company Name in KH' />
				</Grid>
				<Grid item xs={12}>
					<Tooltip title="Can't be change here, need to change from Xero in Financial Settings">
						<TextField label='Tax ID Number' disabled value={taxNumber} fullWidth/>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.address' label='Address in KH' />
				</Grid>
				<Grid container item xs={12} spacing={1}>
					<Grid item xs={4}>
						<Tooltip title="Can't be change here, need to change from Xero in Organisation Settings" >
							<TextField label={areaCountry.label ?? ' '} disabled value={areaCountry.value ?? ''} fullWidth/>
						</Tooltip>
					</Grid>
					<Grid item xs={8}>
						<Tooltip title="Can't be change here, need to change from Xero in Organisation Settings">
							<TextField label='Phone Number' disabled value={phone?.phoneNumber ?? ''} fullWidth/>
						</Tooltip>
					</Grid>
				</Grid>
				{/* <Grid item xs={12}>
					<RHFTextInput name='company.commercialInvoicePrefix' label='Commercial Invoice Prefix' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.taxInvoicePrefix' label='Tax Invoice Prefix' />
				</Grid> */}
				<Grid item xs={12}>
					<RHFTextInput
						name='company.billEmail'
						label='Xero Bills Email'
						rules={{
							validate: value => {
								if ( !value ) return true // if don't have value we don't have to validate it
								const isEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value);
								return isEmail;
							},
						}}
						helperText='Please enter a valid email address meaning '
					/>
				</Grid>
				<Grid item xs={12}>
					<Tooltip title="Can't be change here, need to change from Xero in Organisation Settings">
						<TextField label='Company Registration Number' disabled value={companyRegistrationNumber} fullWidth/>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.businessActivityInKhmer' label='Business Activity in KH' multiline minRows={3} />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.companyRepresentativeName' label='Company Representative Name' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.companyRepresentativePosition' label='Company Representative Position' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.shareCapitalInUSD' label='Share Capital in USD' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.shareCapitalInRiel' label='Share Capital in Riel' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.numberOfShare' label='Number of Share' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='company.parValue' label='Par Value' />
				</Grid>
			</Grid>
		</Box>
	);
};

export default CompanyInfo;
