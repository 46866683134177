import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { roundDecimal, handleCalculateUnitPrice, handleCalculateAmount, getCurrency } from '../../../utility';
import useMeasure from '../../../../components/custom-hook/use-measure';
import { convertPrice, } from '../../../utility';

const useStyles = makeStyles({
  table: {
    width: '100%',
    fontSize: 11,
    lineHeight: 1.3,
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        // border: '1px solid black',
        padding: '0px 0.5em',
      },
    },
  },
  textRight: {
    textAlign: 'right',
    fontWeight: 'bold',
    border: '1px solid black',
  },
  textCenter: {
    textAlign: 'center',
    fontWeight: 'bold',
    border: '1px solid black',
  },
  noBorder: {
    border: '1px solid white',
    borderRight: '1px solid black',
  },
  tableHead:{
    '& tr': {
      border: '1px solid black',
      padding: '0px',
      '& th': {
        border: '1px solid black',
        padding: '1em 0.5em',
      },
    },
  },
  tableBodyContentList: {
    '& tr': {
      borderWidth: '0px 1px 0px 1px',
      borderColor: 'black',
      borderStyle: 'solid',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 0px 1px',
      },
    },
  },
  tableBodyEmptySpace: {
    '& tr:first-child': {
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: '0px 1px 1px 1px',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
      },
    },
  },
});

const InvoicePTLListing = ({ invoice, exchangeRate, invoiceType }) => {
  const classes = useStyles();

  const { ref: tableBodyRef, size } = useMeasure(invoice.invoiceID);
  const minHeight = 170;

  const additionalHeight = minHeight - (size.height || 0);

  let totalLineAmount = 0;
  let totalVat = 0;
  let totalPublicLightingTax = 0;

  const renderInvoiceItems = amountTypes =>
    invoice.lineItems.map((item, key) => {
      const formatTaxObject = item?.tax?.taxComponents.reduce(
        (accumulator, currentValue) => ({...accumulator, [currentValue.name]: currentValue}),
        {}
      );

      let publicLightingTax = 0;
      let vat = 0
      const unitPrice = handleCalculateUnitPrice({
        amountTypes,
        invoiceType,
        lineAmount: item.lineAmount,
        taxAmount: item.taxAmount,
        quantity: item.quantity,
      })
      const lineAmount = handleCalculateAmount({
        amountTypes,
        invoiceType,
        lineAmount: item.lineAmount,
        taxAmount: item.taxAmount,
      })

      if(formatTaxObject?.PLT) {
        publicLightingTax = lineAmount * (formatTaxObject.PLT.rate / 100)
      }

      if ( formatTaxObject ) {
        Object.keys(formatTaxObject).forEach( taxName => {
          if(taxName !== "PLT") {
            vat = vat + (lineAmount + publicLightingTax) * (formatTaxObject[taxName].rate / 100)
          }
        }) 
      }
      

      totalVat += vat
      totalPublicLightingTax += publicLightingTax

      // if(formatTaxObject.VAT) {
      //   vat = (item.lineAmount + publicLightingTax) * (formatTaxObject.VAT.rate / 100)
      // }

      return (
        <tr key={key}>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {key + 1}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.description}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'center',
              // verticalAlign: 'top',
            }}
          >
            {item.quantity}
          </td>

          {/* unit price field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              // verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: unitPrice,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>

          {/* Amount excl. Tax */}
          <td
            style={{
              textAlign: 'right',
            }}
          >
             {convertPrice({
              price: lineAmount,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>

          {/* Public Lighting Tax */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
            }}
          >
            { publicLightingTax === 0 ? "" : convertPrice({
              price: publicLightingTax,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
           
          </td>

          {/* VAT */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
            }}
          >
            { vat === 0 ? "" : convertPrice({
              price: vat,
              currencyCode: invoice.currencyCode,
              convert: false
            }) }
          </td>

          {/* amount field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              // verticalAlign: 'top',
            }}
          >
             {convertPrice({
              price: lineAmount + vat + publicLightingTax,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
      );
    });

  return (
    <div style={{ padding: '1em 0', }}>
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th style={{ width: '28%' }}>
            បរិយាយ
            <br />
            Description
          </th>
          <th>
            បរិមាណ
            <br />
            Quantity
          </th>
          <th>
            តម្លៃឯកតា
            <br />
            Unit Price
          </th>
          <th>
            តម្លៃមិនរួមអាករ
            <br />
            Amount excl. Tax
          </th>
          <th>
            អាករបំភ្លឺសាធារណៈ
            <br />
            Public Lighting Tax
          </th>
          <th>
            អតប
            <br />
            VAT
          </th>
          <th>
            ថ្លៃរួមអាករ
            <br />
            Amount incl. Tax
          </th>
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderInvoiceItems(invoice?.lineAmountTypes.toLowerCase())}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        <tr style={{height: additionalHeight > 0 ? additionalHeight : 0}}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td 
            colSpan='2' 
            className={classes.textCenter}
          >
            សរុបរួម
          </td>
          <td
            colSpan='2' 
            className={classes.textCenter}
          >
          </td>
          <td
            className={classes.textRight}
          >
            {convertPrice({
               price: invoice.subTotal,
               currencyCode: invoice.currencyCode,
               convert: false
            })}
          </td>
          <td
            className={classes.textRight}
          >
            {convertPrice({
              price: totalPublicLightingTax,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
          <td 
            className={classes.textRight}
          >
            {convertPrice({
              price: totalVat,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
          <td
            className={classes.textRight}
          >
            {convertPrice({
              price: invoice.total,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
        <tr>
          <td colSpan='7' className={clsx([classes.textRight, classes.noBorder])}>
            អត្រាប្តូរប្រាក់/Exchange Rate
          </td>
          <td className={classes.textRight}>{Number(exchangeRate)?.toLocaleString()}៛</td>
        </tr>
        <tr>
          <td colSpan='7' className={clsx([classes.textRight, classes.noBorder])}>
            ចំនួន{`${invoice.currencyCode === 'USD' ? 'រៀល' : 'ដុល្លារ'}`} 
            /Amount ({`${getCurrency({currencyTypeOrCode: invoice.currencyCode, same: true, convert: true})}`})
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: invoice.total,
              currencyCode: invoice.currencyCode,
              exchangeRate,
            })}
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default InvoicePTLListing;
