import { useForm } from 'react-hook-form';
import { makeStyles, Container, Divider, Grid, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { setOrganization } from '../utility';

const useStyles = makeStyles({
  root: {
    margin: '1em 0',
  },
});

const VoucherValidation = ({ voucherValidation }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit } = useForm();

  const tenant = JSON.parse(localStorage.getItem('tenant'));

  const [isLoading, setIsLoading] = useState(false);
  const [validationData, setValidationData] = useState({
    preparedBy: '',
    preparedByPosition: '',
    reviewedBy: '',
    reviewedByPosition: '',
    approvedBy: '',
    approvedByPosition: '',
    receivedBy: '',
    receivedByPosition: '',
  });

  useEffect(() => {
    setValidationData({
      preparedBy: voucherValidation?.preparedBy || '',
      preparedByPosition: voucherValidation?.preparedByPosition || '',
      reviewedBy: voucherValidation?.reviewedBy || '',
      reviewedByPosition: voucherValidation?.reviewedByPosition || '',
      approvedBy: voucherValidation?.approvedBy || '',
      approvedByPosition: voucherValidation?.approvedByPosition || '',
      receivedBy: voucherValidation?.receivedBy || '',
      receivedByPosition: voucherValidation?.receivedByPosition || '',
    });
  }, [voucherValidation]);

  // handle post data to sever when click button 'save'
  const handleSave = async data => {
    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_SERVER_URI}/organizations/update/${tenant.tenantId}`,
        {
          voucherValidation: data,
        },
        { withCredentials: true }
      );
      localStorage.setItem('voucherValidation', JSON.stringify(data));
      enqueueSnackbar('Save Completed', { variant: 'success' });
      setOrganization({ voucherValidation: true });
    } catch (err) {
      console.error('Failed to update company info', err);
      enqueueSnackbar('Failed to update the company info', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const validation = [
    {
      placeholder: 'Prepared by',
      name: 'preparedBy',
      positionName: 'preparedByPosition',
    },
    {
      placeholder: 'Reviewed by',
      name: 'reviewedBy',
      positionName: 'reviewedByPosition',
    },
    {
      placeholder: 'Approved by',
      name: 'approvedBy',
      positionName: 'approvedByPosition',
    },
    {
      placeholder: 'Received by',
      name: 'receivedBy',
      positionName: 'receivedByPosition',
    },
  ];
  return (
    <Container className={classes.root}>
      <h2>Voucher Validation</h2>
      <Divider />
      <br />
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={3}>
          {validation.map((validate, index) => (
            <Grid container spacing={3} key={index}>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  size='small'
                  label={validate.placeholder}
                  name={validate.name}
                  value={validationData[validate.name]}
                  onChange={e =>
                    setValidationData({
                      ...validationData,
                      [validate.name]: e.target.value,
                    })
                  }
                  className={classes.textBox}
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  size='small'
                  label='Position'
                  value={validationData[validate.positionName]}
                  onChange={e =>
                    setValidationData({
                      ...validationData,
                      [validate.positionName]: e.target.value,
                    })
                  }
                  name={validate.positionName}
                  className={classes.textBox}
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} container justifyContent='flex-end'>
            <Button variant='contained' color='primary' type='submit' disabled={isLoading}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default VoucherValidation;
