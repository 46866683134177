import React from 'react';
import { Box } from '@material-ui/core';
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FileContainer from './file-container';
import roundDecimal from '../../../utility/roundDecimal '

const FilesViewer = ( {
  files,
  handleDelete = ( ) => {},
} ) => {

  const convertToMB = ( size ) => {
    if ( typeof size === 'number' ) {
      return size/1000000;
    }
    return size;
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 8
      }}
    >
      {files?.map( ( item, index ) => (
        item?.file?.type.includes( 'pdf' )
          ? <FileContainer
              innerContainerStyle={{alignItems: 'center'}}
              handleDelete={()=> handleDelete(item, index)}
              key={index}
            >
              {/* <PictureAsPdfIcon style={{fontSize: '7rem'}}/> */}
              <iframe 
                title='preview_pdf_bill'
                src={item.preview}
                type="application/pdf"
                height='250px'
                style={{overflow: 'auto'}}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {item?.file?.name} ({item?.file?.displaySize})
              </div>                     
            </FileContainer>
          : <FileContainer key={index} handleDelete={()=> handleDelete(item, index)}>
              <img
                src={item.preview}
                style={{
                  width: 'auto',
                  height: 250,
                  objectFit: 'contain'
                }}
                alt="perview"
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {item?.file?.name} ({roundDecimal( { value: convertToMB(item?.file?.size) } )} MB)
              </div>
              
          </FileContainer>
      ) )}
    </Box>     
  );
};

export default FilesViewer;
