import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    trigger: false,
  },
  reducers: {
    // for trigger re rendering. use after sync data
    triggerFilter: (state, action) => {
      state.trigger = !state.trigger;
    },
  },
});

export const { triggerFilter } = filterSlice.actions;

export default filterSlice.reducer;
