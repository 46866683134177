import React, { useEffect, useRef, useState } from 'react';
import {
  Popover, Box, Grid, InputBase, Divider, Button, Paper, IconButton, makeStyles,
} from '@material-ui/core';
import { DateRangePicker } from 'materialui-daterange-picker';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    '& > div > div:first-child > div:first-child': {
      '&:first-child': {
        width: '0 !important',
        height: '0 !important',
      },
    },
    '& ul.MuiList-root': {
      '&:not([role=listbox])': {
        display: 'none !important',
      },
    },
    '& .MuiGrid-root.MuiGrid-container.MuiGrid-wrap-xs-nowrap': {
      '& >:first-child': {
        justifyContent: 'flex-end',
        display: 'flex !important',
        flexDirection: 'column-reverse',
      },
      '& > div:first-child > .MuiGrid-align-items-xs-center':{
        padding: 0,
        display: 'none',
      },
      
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& hr:not(.date-bottom-divider) + div > div': {
        '&:first-child': {
          width: '100%',
          '& .MuiGrid-justify-xs-center': {
            justifyContent: 'space-around',
          },
        },
        '&:not(first-child)': {
          display: 'none !important',
        },
      },
    },
  },
} ) );

// problem: if `canChangeHour=ture` it not working correct yet
const DateTimeRangePicker = ( {
  setDate, // require
  elevation = 1,
  maxDate,
  canChangeHour = false,
  date = {}, // Object example: { startDate: Date, endDate: Date }
  placeholder = "Date",
  onAfterChange,
} ) => {
  const classes = useStyles();
  const anchorRef = useRef( null );
  const [open, setOpen] = useState( false );
  // const [empty, setEmtpy] = useState( true );
  const formatString = 'DD/MM/yy';
  // const [dateRange, setDateRange] = useState( defaultDateRange );
  const [timeRange, setTimeRange] = useState(
    {
      startTime: '00:00',
      endTime: '23:59',
    },
  );

  useEffect( () => {
    setDate( date );
  }, [] );

  const handleClose = ( clear = true ) => {
    setOpen( false );
  };

  const handleOpen = () => {
    setOpen( true );
  };

  const handleClear = () => {
    setDate( {} );
    if ( typeof onAfterChange === 'function' ) {
      onAfterChange({ startDate: undefined, endDate: undefined });
    }
  };

  const handleChangeRange = ( range ) => {
    const startTimeSplit = timeRange.startTime.split( ':' );
    const startRange = range.startDate;
    startRange.setHours( startTimeSplit[0], startTimeSplit[1], 0 );
    const endTimeSplit = timeRange.endTime.split( ':' );
    const endRange = range.endDate;
    endRange.setHours( endTimeSplit[0], endTimeSplit[1], 0 );
    setDate( { startDate: startRange, endDate: endRange } );
    if ( typeof onAfterChange === 'function' ) {
      onAfterChange({ startDate: startRange, endDate: endRange });
    }
  };

  const handleChangeStartTime = ( e ) => {
    const time = e.target.value;
    setDate( ( prev ) => {
      const newDateTime = new Date( prev.startDate );
      const timeSplit = time.split( ':' );
      newDateTime.setHours( timeSplit[0], timeSplit[1], 0 );
      return { ...prev, startDate: newDateTime };
    } );
    setTimeRange( ( prev ) => ( {
      ...prev, startTime: time,
    } ) );
  };

  const handleChangeEndTime = ( e ) => {
    const time = e.target.value;
    setDate( ( prev ) => {
      const newDateTime = new Date( prev.startDate );
      const timeSplit = time.split( ':' );
      newDateTime.setHours( timeSplit[0], timeSplit[1], 0 );
      return { ...prev, endDate: newDateTime };
    } );
    setTimeRange( ( prev ) => ( {
      ...prev, endTime: time,
    } ) );
  };

  const checkIfHaveValue = ( ) => {
    if ( date?.startDate && date?.endDate ) {
      return true
    }
    return false;
  };

  return (
    <>
      <Box clone display="flex" width="100%">
        <Paper elevation={elevation} style={{ padding: '6px 15px', boxShadow: 'rgba(99, 99, 99, 0.3) 0px 2px 8px 0px'  }}>
          <InputBase
            onClick={handleOpen}
            fullWidth
            readOnly
            value={checkIfHaveValue() ? `${moment( date.startDate ).format( 'DD/MM/yy' )} - ${moment( date.endDate ).format( 'DD/MM/yy' )}`: ''}
            ref={anchorRef}
            placeholder={placeholder}
          />
          {
            checkIfHaveValue()
          && (
          // when have dateRange different from default value show button for clear it back to default value
          <IconButton disableRipple onClick={handleClear} size="small">
            <CloseIcon />
          </IconButton>
          )
          }
          <IconButton disableRipple onClick={handleOpen} size="small">
            <DateRangeIcon />
          </IconButton>
        </Paper>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        style={{ top: 10 }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper className={classes.root}>
          <Box overflow="hidden" marginBottom={2} position="relative">
            <DateRangePicker
              maxDate={maxDate}
              closeOnClickOutside={false}
              open
              initialDateRange={date}
              toggle={handleClose}
              onChange={handleChangeRange}
            />
            { canChangeHour && (
              <Grid
                container
                style={{
                  position: 'absolute', bottom: 0, zIndex: 1, marginBottom: -6,
                }}
              >
                <Grid item xs={12} container justify="space-around">
                  <Grid item xs={2}>
                    <InputBase
                      fullWidth
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={timeRange.startTime}
                      onChange={handleChangeStartTime}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputBase
                      fullWidth
                      type="time"
                      value={timeRange.endTime}
                      onChange={handleChangeEndTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) }
          
          </Box>
          <Divider className="date-bottom-divider" />
          <Grid container justify="space-between">
            <Grid item xs={12}>
              <Button onClick={handleClose} fullWidth style={{ borderRadius: 0 }} variant="contained" disableElevation>Close</Button>
            </Grid>
            {/* <Grid item xs={6}>
              <Button onClick={handleApply} fullWidth style={{ borderRadius: 0 }} color="primary" variant="contained" disableElevation>Apply</Button>
            </Grid> */}
          </Grid>
        </Paper>
      </Popover>
    </>
  );
};

export default DateTimeRangePicker;
