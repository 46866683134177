import { createSlice } from '@reduxjs/toolkit';

const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organization: {
      apiKey: '',
    },
  },
  reducers: {
    switchOrganizationSuccess: (state, action) => {
      state.organization = action.payload;
    },
  },
});

export const { switchOrganizationSuccess } = organizationSlice.actions;

export default organizationSlice.reducer;
