import { makeStyles } from "@material-ui/core";
import InvoiceFooter from "../invoice/footer";

const useStyles = makeStyles({
  container: {
    marginTop: "1em",
    pagebreakInside: "avoid",
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <InvoiceFooter />
  );
};

export default Footer;
