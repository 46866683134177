import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { roundDecimal, handleCalculateUnitPrice, handleCalculateAmount, getCurrency } from '../../../utility';
import useMeasure from '../../../../components/custom-hook/use-measure';
import { convertPrice } from '../../../utility';

const useStyles = makeStyles({
  table: {
    width: '100%',
    fontSize: 11,
    lineHeight: 1.3,
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        // border: '1px solid black',
        padding: '0px 0.5em',
      },
    },
  },
  textRight: {
    textAlign: 'right',
    fontWeight: 'bold',
    border: '1px solid black',
  },
  noBorder: {
    border: '1px solid white',
    borderRight: '1px solid black',
  },
  tableHead:{
    '& tr': {
      border: '1px solid black',
      padding: '0px',
      '& th': {
        border: '1px solid black',
        padding: '1em 0.5em',
      },
    },
  },
  tableBodyContentList: {
    '& tr': {
      borderWidth: '0px 1px 0px 1px',
      borderColor: 'black',
      borderStyle: 'solid',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 0px 1px',
      },
    },
  },
  tableBodyEmptySpace: {
    '& tr:first-child': {
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: '0px 1px 1px 1px',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
      },
    },
  },
});

const InvoiceListing = ({ invoice, exchangeRate, invoiceType }) => {
  const classes = useStyles();

  const { ref: tableBodyRef, size } = useMeasure(invoice.invoiceID);
  const minHeight = 170;

  const additionalHeight = minHeight - (size.height || 0);

  const renderInvoiceItems = amountTypes =>
    invoice.lineItems.map((item, key) => {
      return (
        <tr key={key}>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {key + 1}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.description}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.quantity}
          </td>
          {/* unit price field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.unitAmount}
          </td>
          {/* amount field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: handleCalculateAmount({
                amountTypes,
                invoiceType,
                lineAmount: item.lineAmount,
                taxAmount: item.taxAmount,
              }),
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
      );
    });

  return (
    <div style={{ padding: '1em 0', }}>
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th style={{ width: '48%' }}>
            បរិយាយ
            <br />
            Description
          </th>
          <th>
            បរិមាណ
            <br />
            Quantity
          </th>
          <th>
            តម្លៃឯកតា
            <br />
            Unit Price
          </th>
          {invoice.currencyCode === 'USD' ? (
            <th>
              ចំនួន (ដុល្លា)
              <br />
              Amount (USD)
            </th>
          ) : (
            <th>
              ចំនួន (រៀល)
              <br />
              Amount (KHR)
            </th>
          )}
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderInvoiceItems(invoice?.lineAmountTypes.toLowerCase())}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        <tr style={{height: additionalHeight > 0 ? additionalHeight : 0}}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {invoiceType === 'tax' && (
          <tr>
            <td
              colSpan='4'
              className={clsx([classes.textRight, classes.noBorder])}
              style={{ borderTop: '1px solid black' }}
            >
              សរុប/Sub Total
            </td>
            <td className={classes.textRight}>
              {convertPrice({
                price: roundDecimal({ value: invoice.subTotal}),
                currencyCode: invoice.currencyCode,
                convert: false
              })}
            </td>
          </tr>
        )}
        {invoiceType === 'tax' && (
          <tr>
            <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])}>
              អាករលើតម្លៃបន្ថែម/VAT (10%)
            </td>
            <td className={classes.textRight}>
              {convertPrice({
                price: roundDecimal({ value: invoice.totalTax}),
                currencyCode: invoice.currencyCode,
                convert: false
              })}
            </td>
          </tr>
        )}
        <tr>
          <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])} style={{ paddingTop: 5 }}>
            {invoiceType === 'tax' ? `សរុបរួម/Grand Total` : `សរុបរួមបញ្ចូលទាំងអាករ/Total Include (VAT 10%)`}
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: roundDecimal({ value: invoice.total}),
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
        <tr>
          <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])}>
            អត្រាប្តូរប្រាក់/Exchange Rate
          </td>
          <td className={classes.textRight}>{Number(exchangeRate)?.toLocaleString()}៛</td>
        </tr>
        <tr>
          <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])}>
            ចំនួន{`${invoice.currencyCode === 'USD' ? 'រៀល' : 'ដុល្លារ'}`} 
            /Amount ({`${getCurrency({currencyTypeOrCode: invoice.currencyCode, same: true, convert: true})}`})
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: roundDecimal({ value: invoice.total}),
              currencyCode: invoice.currencyCode,
              exchangeRate,
            })}
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default InvoiceListing;
