import React from 'react';
import {
  makeStyles,
  Dialog,
  DialogContent,
  Typography,
  Zoom,
  Fade,
  useMediaQuery,
  Divider,
  Button,
  DialogActions,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles( ( theme ) => ( {
  dialogContent : {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px 0`,
    width: '400px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  dialogContentPadding: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  dialogPaper: {
    borderRadius: 0,
  },
  stateTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  divider: {
    width: '10%',
    height: '3px',
    borderRadius: '100px',
    marginBottom: theme.spacing(1),
  },
  iconContainer: {
    display: 'contents'
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    borderRadius: 0,
    textTransform: 'none',
    "&:hover":{
      backgroundColor: theme.palette.error.dark,
    }
  },
  dialogActions: {
    marginBottom: theme.spacing(2),
    justifyContent: 'center'
  }
} ) );

// you can use usePopup() context instead of this component
const AlertPopup = ( {
  open = false, // when open=true it will automatically close base on 'timeout'
  success = true,
  message = '',
  onExiting = () => {},
  handleClose = () => {},
  timeout = 1000,
} ) => {
  const classes = useStyles();
  const matchesXs = useMediaQuery( theme => theme.breakpoints.only('xs') );

  const onEnter = () => {
    if ( success ) {
      setTimeout( () => {
        handleClose();
      }, timeout );
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      TransitionComponent={Fade}
      onEnter={onEnter}
      onExiting={onExiting}
      open={open}
      fullScreen={matchesXs}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent
        className={`${classes.dialogContent} ${success && classes.dialogContentPadding}`}
      >
        <Zoom in timeout={700}>
          {success ? (
            <CheckCircleOutlineIcon color="primary" style={{ fontSize: '70px' }} />
          ) : (
            <CancelOutlinedIcon color="error" style={{ fontSize: '70px' }} />
          )}
        </Zoom>
        <Typography className={classes.stateTitle} color={success ? "primary": "error"}>
          {success ? "Success!" : "Ooops!" }  
        </Typography>
        <Divider className={classes.divider}/>
        <Typography variant='caption' style={{ textAlign: 'center' }}>
          {message}
        </Typography>
      </DialogContent>
      { !success && (
        <DialogActions className={classes.dialogActions}>
          <Button
              variant='contained'
              disabled={!open} // prevent multiple click during fade out
              className={classes.closeButton}
              onClick={() => {
                handleClose();
              }}
          >
            Try Again
          </Button>
      </DialogActions>
      ) }
      
    </Dialog>
  );
};
export default AlertPopup;
