/**
 * Function for convert `name` to shorter version of itself. Ex: `Berry Brew` = `BB`
 * @param {string} name The value that need to be converted.
 * @returns {string}
 */

const generateLetterFromName = ( name ) => {
  // return immediately if name not string to avoid error
  if( typeof name !== "string" ) {
    return "";
  }

  // convert name to array base on " ". Ex: Name = "Jennifer Vaughan Lucas" = ["Jennifer", "Vaughan", "Lucas"]
  const arrayLetter = name.replace(
    /[`^~!@#$%^&*()\-_+={}[\]|/\\:;"'<>,.?]/g, '' // remove all special characters
    ).replace(/\s\s+/g, ' ') // replace multiple spaces with a single space
    .split(" ");

  return `${arrayLetter[0].charAt(0)}${arrayLetter.length > 1 ? arrayLetter[1].charAt(0) : ''}`
}

export default generateLetterFromName;