import React, { useEffect } from "react";
import {
  Grid,
  Button,
  makeStyles,
  Container,
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom';
import axios from "axios";
import { useForm } from 'react-hook-form';
import RHFTextInput from '../../../components/rhf-controlled-input/text-input';
import RHFSelectInput from '../../../components/rhf-controlled-input/select-input';
import RHFCheckboxInput from '../../../components/rhf-controlled-input/checkbox-input';
import { usePopup } from '../../../components/context/popup-context';

const useStyles = makeStyles(( theme ) => ({
    buttonContainer: {
      textAlign: "end",
    },
  }));

  const accountTypes = [
    {value: "BANK", label: "Bank", disable: true },
    {value: "CURRENT", label: "Current Asset" },
    {value: "CURRLIAB", label: "Current Liability" },
    {value: "DEPRECIATN", label: "Depreciation" },
    {value: "DIRECTCOSTS", label: "Direct Costs" },
    {value: "EQUITY", label: "Equity" },
    {value: "EXPENSE", label: "Expense" },
    {value: "FIXED", label: "Fixed Asset" },
    {value: "INVENTORY", label: "Inventory Asset" },
    {value: "LIABILITY", label: "Liability " },
    {value: "NONCURRENT", label: "Non-current Asset" },
    {value: "OTHERINCOME", label: "Other Income" },
    {value: "OVERHEADS", label: "Overhead" },
    {value: "PREPAYMENT", label: "Prepayment" },
    {value: "REVENUE", label: "Revenue" },
    {value: "SALES", label: "Sale " },
    // {value: "TERMLIAB", label: "TERMLIAB" },
    // {value: "PAYGLIABILITY", label: "PAYG Liability" },
    // {value: "SUPERANNUATIONEXPENSE", label: "Superannuation Expense" },
    // {value: "SUPERANNUATIONLIABILITY", label: "Superannuation Liability" },
    // {value: "WAGESEXPENSE", label: "Wages Expense" },
  ];
  const taxType = [
    {value: "INPUT", label:"Tax on Purchases"},
    {value: "NONE", label:"Tax Exempt"},
    {value: "OUTPUT", label:"Tax on Consulting"},
    {value: "GSTONIMPORTS", label:"Sales Tax on Imports"},
  ]

const CreateUpdateAccount = () => {
  const classes = useStyles();
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const history = useHistory();
  // if have contactID mean we need to update it
  const { id: accountID } = useParams();

  const {
    handleSubmit,
    control,
    reset,
  } = useForm({
    shouldFocusError: true,
    defaultValues: {
      type: '',
      code: '',
      name: '',
      taxType: '',
      description: '',
      addToWatchlist: false,
      showInExpenseClaims: false, 
      enablePaymentsToAccount: false,
    }
  });

  useEffect( ( ) => {
    const getData = async ( ) => {
      try {
        showSpinner();
        if( accountID ){
          const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER_URI}/accounts/${accountID}`,
            { withCredentials: true },
          );
          reset( data );
        }
      } catch ( err ) {
        showAlertPopup({
          message: err.response?.data?.message 
            ? err.response.data.message
              : "Sorry Something Went Wrong" ,
          success: false,
          onExiting: () => history.goBack(),
        });
      } finally {
        hideSpinner();
      }
    }
    getData();
  },[accountID] );

  const onSubmitAccount = async ( data ) => {
    try {
      reset();
      showSpinner();
      if (!accountID) {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URI}/accounts/create`,
          data,
          { withCredentials: true },
        );
      } else {
        await axios.put(
          `${process.env.REACT_APP_SERVER_URI}/accounts/update/${accountID}`,
          data,
          { withCredentials: true },
        );
      }
      reset();
      showAlertPopup({
        success: true,
        onExiting: () => history.push('/chart-of-accounts/all'),
      });
    } catch ( err ) {
      console.error( err.response, 'err' );
      showAlertPopup({
        message: err.response?.data?.message 
          ? err.response.data.message
            : `Fail To ${accountID ? 'Update' : 'Create'} Contact`,
        success: false
      });
    } finally {
      hideSpinner();
    }
  }

  return (
    <Container>
      <h1>{!accountID ? 'Create': 'Update'} Account</h1>
      <form onSubmit={handleSubmit(onSubmitAccount)}>
        <Grid container spacing={2}>
          <Grid
            container
            item 
            xs={12} 
            sm={6}
            style={{alignContent: 'flex-start'}}
            spacing={1}
          >
            <Grid container item xs={12}>
              <RHFSelectInput
                label="Account Type *"
                name="type"
                options={accountTypes}
                control={control}
                required
              />
            </Grid>
            <Grid container item xs={12}>
              <RHFTextInput 
                name="code"
                control={control}
                label="Code *"
                fullWidth
                required
              />
            </Grid>
            <Grid container item xs={12}>
              <RHFTextInput 
                name="name"
                control={control}
                label="Name *"
                fullWidth
                required
              />
            </Grid>
            <Grid container item xs={12}>
              <RHFTextInput 
                name="description"
                control={control}
                label="Description"
                fullWidth
              />
            </Grid>
            <Grid container item xs={12}>
              <RHFSelectInput
                label="Tax *"
                name="taxType"
                options={taxType}
                control={control}
                required
              />
            </Grid>
            <Grid container item xs={12}>
              <RHFCheckboxInput
                label="Show on Dashboard Watchlist"
                name="addToWatchlist"
                control={control}
              />
            </Grid>
            <Grid container item xs={12}>
              <RHFCheckboxInput
                label="Show in Expense Claims"
                name="showInExpenseClaims"
                control={control}
              />
            </Grid>
            <Grid container item xs={12}>
              <RHFCheckboxInput
                label="Enable payments to this account"
                name="enablePaymentsToAccount"
                control={control}
              />
            </Grid>
          </Grid>
        
        </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button type="submit" color="primary" variant="contained">
              {!accountID ? "Create": "Update" }
            </Button>
          </Grid>
      </form> 
    </Container>
  );
};

export default CreateUpdateAccount;
