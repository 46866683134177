import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  debounce,
  Chip,
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom'
// import { useSnackbar } from "notistack";
// import qs from 'qs';

import "react-datepicker/dist/react-datepicker.css";

import CustomTable, { paginationConfig } from '../../components/custom-table';
import { usePopup } from '../../components/context/popup-context';
// import { logout } from "../../redux-store/slices/user";
import {
  makeStyles,
  Container,
} from "@material-ui/core";
import useQuery from "../../components/custom-hook/use-query";
import axios from "axios";

const useStyles = makeStyles(( theme ) => ({
    container: {
      // maxHeight: 500,
    },
    button: {
      textTransform: 'capitalize',
      margin: `0px ${theme.spacing(1)}px`
    },
    actionButton:{
      color: theme.palette.error.main,
    },
    avatarStyle:{
      backgroundColor: theme.palette.primary.main,
      textTransform: 'uppercase',
      marginRight: theme.spacing(1),
    },
    codeFieldStyle: {
      // borderRadius: theme.spacing(1),
    }
  }));

const ProductsAndServices = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showPromptPopup, showAlertPopup, hideSpinner, showSpinner } = usePopup();
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const { data, error, query, loading } = useQuery(`${process.env.REACT_APP_SERVER_URI}/items`)
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });

  const schema = [
    {
      label: 'Code',
      field: 'code',
      tableCellProps:{ align:"left", width:"auto"  },
      render: (dataProps) => <Chip variant="outlined" color="primary" label={dataProps.code} className={classes.codeFieldStyle}/>
    },
    {
      label: 'Name',
      tableCellProps:{ align:"left", width:"auto"  },
      field: 'name',
    },
    {
      label: 'Cost Price',
      tableCellProps:{ align:"center", width:"10%"  },
      field: 'purchaseDetails.unitPrice'
    },
    {
      label: 'Sale Price',
      tableCellProps:{ align:"center", width:"10%"  },
      field: 'salesDetails.unitPrice'
    },
    {
      label: 'Quantity',
      tableCellProps:{ align:"center", width:"10%"  },
      field: 'quantityOnHand'
    },
  ];

  const handleFetchProductsServicesData = async ( searchProps, pageProps = pagination.page, perPageProps = pagination.rowsPerPage ) => {
    try{
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // when searchProps is 'null' or 'undefined' it won't be used.
        // so the searchProps stay the same. this effect happen when changing pagination
        ...( isNullish ? {} : { search: searchProps } ),
        page: pageProps,
        perPage: perPageProps,
      });
      setPagination( pre => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }) )
    } catch ( err ) {
      console.error( err );
    }
  }

  useEffect( ( ) => {
    handleFetchProductsServicesData();
  }, [organization, trigger]);
  
  // handle search
  const debouncedServiceResults = useMemo(() => {
    return debounce((text) => {
      handleFetchProductsServicesData( text, 0 );
    //   setSearch(text);
    }, 300);
  }, []);

  
  // handle sync contact data from xero with DB
  const handleSyncProductsServices = async () =>{
    try {
      showSpinner();
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/items/sync`, { withCredentials: true });
      handleFetchProductsServicesData();
    } catch ( err ) {
      console.error( err?.response )
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Contact`,
        success: false
      });
    } finally {
      hideSpinner();
    }
  }

  const handleCreateProductsServices = () => {

  }


  useEffect( ( ) => {
    if( data ){
      setPagination( pre => ({ ...pre,  count: data.totalDocs  }) );
    }
  }, [data] )

  const handleChangeProductsServicesPage = ( event, page ) => {
    handleFetchProductsServicesData( null, page )
  }

  const handleChangeProductsServicesRowsPerPage = ( event ) => {
    handleFetchProductsServicesData( null, 0, parseInt(event.target.value, 10) )
  }

  return (
    <Container>
      <h1>Products & Services</h1>
      <Grid container justify="space-between">
        <Grid container item  xs={12} sm={6}>
          <Grid item xs={12} >
            <TextField
              id="search"
              placeholder="Search..."
              variant="outlined"
              color="primary"
              size="small"
              fullWidth
              onChange={(event) => debouncedServiceResults(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} justify="flex-end">
          <Grid item xs="auto">
            {/* <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<RefreshIcon />}
              onClick={()=> { handleSyncProductsServices() }}
            >
              Sync
            </Button> */}
          </Grid>
          {/* <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => handleCreateProductsServices()}
            >
              New Item
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs}
        loading={loading}
        error={error}
        disableCheckBox
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeProductsServicesPage,
          onChangeRowsPerPage: handleChangeProductsServicesRowsPerPage,
        }}
      />
    </Container>
  );
};

export default ProductsAndServices;
