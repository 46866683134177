import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { roundDecimal, handleCalculateUnitPrice, handleCalculateAmount, convertPrice } from '../../../utility';
import useMeasure from '../../../../components/custom-hook/use-measure';

const useStyles = makeStyles({
  table: {
    margin: '1em 0',
    width: '100%',
    fontSize: 11,
    lineHeight: 1.3,
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        // border: '1px solid black',
        padding: '0px 0.5em',
      },
    },
  },
  textRight: {
    textAlign: 'right',
    fontWeight: 'bold',
    border: '1px solid black',
  },
  noBorder: {
    border: '1px solid white',
    borderRight: '1px solid black',
  },
  tableHead: {
    '& tr': {
      border: '1px solid black',
      padding: '0px',
      '& th': {
        border: '1px solid black',
        padding: '1em 0.5em',
      },
    },
  },
  tableBodyContentList: {
    '& tr': {
      borderWidth: '0px 1px 1px 1px',
      borderColor: 'black',
      borderStyle: 'solid',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 0px 1px',
      },
    },
  },
  tableBodyEmptySpace: {
    '& tr:first-child': {
      // borderColor: 'black',
      // borderStyle: 'solid',
      // borderWidth: '0px 1px 1px 1px',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
      },
    },
  },
});

const QuoteListing = ({ quote, exchangeRate, quoteType }) => {
  const classes = useStyles();

  const { ref: tableBodyRef, size } = useMeasure(quote.invoiceID);
  const minHeight = 170;

  const additionalHeight = minHeight - (size.height || 0);

  const renderInvoiceItems = amountTypes =>
    quote.lineItems.map((item, key) => {
      const unitPrice = roundDecimal({
        value: handleCalculateUnitPrice({
          amountTypes,
          invoiceType: quoteType,
          lineAmount: item.lineAmount,
          taxAmount: item.taxAmount,
          quantity: item.quantity,
        }),
      });
      const amount = roundDecimal({
        value: handleCalculateAmount({
          amountTypes,
          invoiceType: quoteType,
          lineAmount: item.lineAmount,
          taxAmount: item.taxAmount,
        }),
      })
      return (
        <tr key={key}>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {key + 1}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.description}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.quantity}
          </td>
          {/* unit price field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: unitPrice,
              currencyCode: quote.currencyCode,
              convert: false
            })}
          </td>
          {/* amount field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: amount,
              currencyCode: quote.currencyCode,
              convert: false
            })}
          </td>
        </tr>
      );
    });

  return (
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th style={{ width: '48%' }}>
            បរិយាយ
            <br />
            Description
          </th>
          <th>
            បរិមាណ
            <br />
            Quantity
          </th>
          <th>
            តម្លៃឯកតា
            <br />
            Unit Price
          </th>
          {quote.currencyCode === 'USD' ? (
            <th>
              ចំនួន (ដុល្លា)
              <br />
              Amount (USD)
            </th>
          ) : (
            <th>
              ចំនួន (រៀល)
              <br />
              Amount (KHR)
            </th>
          )}
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderInvoiceItems(quote?.lineAmountTypes)}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        {/* <tr style={{ height: additionalHeight > 0 ? additionalHeight : 0 }}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr> */}
        {quoteType === 'tax' && (
          <tr>
            <td
              colSpan='4'
              className={clsx([classes.textRight, classes.noBorder])}
              style={{ borderTop: '1px solid black' }}
            >
              សរុប/Sub Total
            </td>
            <td className={classes.textRight}>
              {convertPrice({
                price: roundDecimal({ value: quote.subTotal}),
                currencyCode: quote.currencyCode,
                convert: false
              })}
            </td>
          </tr>
        )}
        {quoteType === 'tax' && (
          <tr>
            <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])}>
              អាករលើតម្លៃបន្ថែម/VAT (10%)
            </td>
            <td className={classes.textRight}>
              {convertPrice({
                price: roundDecimal({ value: quote.totalTax}),
                currencyCode: quote.currencyCode,
                convert: false
              })}
            </td>
          </tr>
        )}
        <tr>
          <td 
            colSpan='4' 
            className={clsx([classes.textRight, classes.noBorder])} 
            style={{ paddingTop: 5, borderWidth: '0px 1px 0px 0px', borderColor: 'black', borderStyle: 'solid' }}
          >
            សរុបរួម/Grand Total 
            {/* {quoteType === 'tax' ? `សរុបរួម/Grand Total` : `សរុបរួមបញ្ចូលទាំងអាករ/Total Include (VAT 10%)`} */}
          </td>
          <td 
            className={classes.textRight}
            style={{borderWidth: '0px 1px 1px 0px', borderColor: 'black', borderStyle: 'solid' }}
          >
            {convertPrice({
              price: roundDecimal({ value: quote.total}),
              currencyCode: quote.currencyCode,
              convert: false
            })}
          </td>
        </tr>
        {/* <tr>
          <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])}>
            អត្រាប្តូរប្រាក់/Exchange Rate
          </td>
          <td className={classes.textRight}>{Number(exchangeRate)?.toLocaleString()}៛</td>
        </tr>
        <tr>
          <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])}>
            ចំនួនរៀល/Amount (KHR)
          </td>
          <td className={classes.textRight}>
            {quote.currencyCode === 'USD'
              ? convertKhmerRiel(quote.total)
              : roundDecimal({ value: quote.total })?.toLocaleString()}
            ៛
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default QuoteListing;
