import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import { RHFTextInput } from '../../components/rhf-controlled-input';
import { switchOrganizationSuccess } from '../../redux-store/slices/organization';
import GlobalSetting from './global-setting';

const ApiKeySetting = () => {
  const { control, handleSubmit, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { organization } = useSelector(state => state.organizationReducer);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const exchangeRate = JSON.parse(localStorage.getItem('exchangeRate'));
  const user = JSON.parse(localStorage.getItem('user')); 

  useEffect(() => {
    reset({ apiKey: organization.apiKey });
  }, [organization]);

  const onSubmit = async data => {
    const tenant = JSON.parse(localStorage.getItem('tenant'));

    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_SERVER_URI}/organizations/update/${tenant.tenantId}`,
        {
          apiKey: data.apiKey,
        },
        { withCredentials: true }
      );

      localStorage.setItem('apiKey', JSON.stringify(data.apiKey));
      dispatch(switchOrganizationSuccess({ apiKey: data.apiKey }));
      enqueueSnackbar('Update API Key successfully', { variant: 'success' });
    } catch (error) {
      console.error('error set api key :>> ', error);
      enqueueSnackbar(error?.response?.data?.message || 'Something went wrong', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {/* <h1>API Key Setting</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography gutterBottom>TADA API Key</Typography>
            <RHFTextInput control={control} name='apiKey' label='API Key' required />
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' type='submit' disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </form> */}
      { user?.canEditExchangeRate && (
        <>
          <h1>Month-end Exchange Rate</h1>
          <Grid container justify='space-evenly'>
            <Grid item xs={12}>
              <GlobalSetting exchangeRate={exchangeRate}/>
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </Grid>
        </>
      ) }
      
    </Container>
  );
};

export default ApiKeySetting;
