import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableContainer,
  TablePagination,
  makeStyles,
} from '@material-ui/core'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import PropTypes from "prop-types";

import CustomTableBody from './body';
import CustomTableHead from './head';
import SelectPopUp from '../select-popup';
import { usePopup } from "../context/popup-context";

const useStyles = makeStyles(( theme ) => ({
    paginationContainer: {
      justifyContent: 'flex-end'
    },
    table: {
      '& .MuiTableCell-sizeSmall' : {
        padding: `6px ${theme.spacing(2)}px`,
      }
    }
  }));

const paginationConfig = {
  rowsPerPageOptions: [10, 25, 50, 100],
}

// generate table header with nested children
const formatTableHeadSchema = ( schema, collection = [], collectionIndex = 0, colSpan = 1 ) => {
  if ( Array.isArray( schema ) ) {
    schema.forEach( ( item ) => {
      const { children, ...otherItemProps } = item;
        if ( Array.isArray(children) ) {
          colSpan = children.length;
          const { colSpan: colSpanReturnValue } =  formatTableHeadSchema( children, collection, collectionIndex + 1 );
          otherItemProps.colSpan = colSpan + colSpanReturnValue - 1;
          otherItemProps.haveChildren = true;
        }
        if ( Array.isArray(collection[collectionIndex]) ) {
          return collection[collectionIndex].push(otherItemProps);
        }
        collection[collectionIndex] = [otherItemProps]
    } )
  }
  return { collection, colSpan };
}

// generate table body to proper column
const formatTableBodySchema = ( schema, collection = [] ) => {
  if ( Array.isArray( schema ) ) {
    schema.forEach( ( item, index ) => {
      const { children, ...otherItemProps } = item;
      if ( Array.isArray(children) ) {
        return formatTableBodySchema( children, collection);
      }
      collection.push( otherItemProps );
    } )
  }
  return collection;
}

// what can improve this component:
// - `sort` with `CustomTableHead` doesn't work well yet 
/**
 * Custom Table
 * @param string tableSize `"small"` | `"medium"`, default `"small"`
 * @returns
 */

const CustomTable = ({
  schema,
  disableCheckBox = false,
  disablePagination = false,
  disableCheckBoxSelectPopup = false,
  disableHover = false,
  selectedIdentifier = '_id', // must be unique so it can be sort properly selectedIdentifier
  dataList,
  loading,
  handleDeleteMultiple = () => {},
  paginationProps = {},
  className = "",
  tableHeadClassName,
  tableSize = "small",
  getSelectedRow,
}) => {
  const classes = useStyles();
  const { showPromptPopup } = usePopup();
  const rowsPerPageOptions = paginationProps.rowsPerPageOptions;
  paginationConfig.rowsPerPageOptions = Array.isArray(rowsPerPageOptions) && rowsPerPageOptions.length > 0 
     ? rowsPerPageOptions : paginationConfig.rowsPerPageOptions;
  const [sort, setSort] = useState({});
  const [selected, setSelected] = useState([]);
  
  const handleSort = (property) => {
    const isSorting = property in sort;
    let order = 'asc';
    if (isSorting) {
      if (sort[property] === 'asc') {
        order = 'desc';
      } else {
        order = 'asc';
      }
    }
    setSort(() => ({ [property]: order }));
  };

  /** handle Selected action **/  
  const handleSelectAll = ( event ) => {
    if ( event.target.checked ) {
      const newSelected = Array.isArray( dataList ) ? dataList : [];
      setSelected( newSelected );
      return;
    }
    setSelected( [] );
  };

  const handleSelect = (e, data) => {
    const selectedIndex = selected
      .map((item) => item[selectedIdentifier])
      .indexOf(data[selectedIdentifier]);
    let newArray = [];
    if (selectedIndex === -1) {
      // if data don't have in selected array, add data to array
      newArray = newArray.concat(selected, data);
    } else if (selectedIndex === 0) {
      // if data have in first index of selected array,
      // remove data from the first index of selected array
      newArray = newArray.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
       // if data have in last index of selected array,
       // remove data from the last index of selected array
      newArray = newArray.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      // if data have in between first and last index of selected array,
      // remove data from that index of array
      newArray = newArray.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newArray);
  };

  const isSelected = (id) => selected.map((item) => item._id).indexOf(id) !== -1;

  // clear selected whenever dataList change
  useEffect( ( ) => {
    setSelected([]);
  }, [dataList] )

  useEffect( ( ) => {
    if( typeof getSelectedRow === 'function' ) {
      getSelectedRow(selected);
    }
  }, [selected] )

  return (
    <Grid container>
      <Grid item xs={12}>
      {/* style={{ maxHeight: 300 }} */}
        <TableContainer>
          {/* stickyHeader */}
          <Table size={tableSize} className={`${classes.table} ${className}`}>
            <CustomTableHead
              schema={formatTableHeadSchema(schema).collection}
              disableCheckBox={disableCheckBox}
              handleSort={handleSort}
              sort={sort}
              dataList={dataList}
              handleCheckboxClick={handleSelectAll}
              checkboxProps={{
                onClick: (event) => handleSelectAll( event ),
                indeterminate: selected.length > 0 && selected.length < dataList?.length,
                indeterminateIcon: <IndeterminateCheckBoxIcon color="primary" />,
                checked: selected.length === dataList?.length,
              }}
              tableHeadClassName={tableHeadClassName}
            /> 
            <CustomTableBody
              schema={formatTableBodySchema(schema)}
              disableCheckBox={disableCheckBox}
              handleSort={handleSort}
              sort={sort}
              dataList={dataList}
              loading={loading}
              handleCheckboxClick={handleSelect}
              handleShowCheck={(data) => isSelected(data._id)}
              disableHover={disableHover}
            />
          </Table>
        </TableContainer>
      </Grid>
      {!(disablePagination) && (
        <Grid item container className={classes.paginationContainer}>
          <TablePagination
            rowsPerPageOptions={paginationConfig.rowsPerPageOptions}
            component="div"
            {...paginationProps}
            onChangePage={(event, page) => {
              if ( typeof paginationProps.onChangePage === "function"  ){
                paginationProps.onChangePage(event, page)
              };
            }}
            onChangeRowsPerPage={(event) => {
              if ( typeof paginationProps.onChangeRowsPerPage === "function"  ){
                paginationProps.onChangeRowsPerPage(event)
              };
            }}
          />
        </Grid>
      )}
      { (!disableCheckBox && !disableCheckBoxSelectPopup) &&
        <SelectPopUp
          select={selected}
          handleDelete={()=> {
            showPromptPopup({
              handleConfirm: async () => {
                try {
                  await handleDeleteMultiple( selected );
                  // after delete reset select if nothing when wrong
                  // setSelected([]);
                } catch ( err ) {
                  console.error( err, 'err' )
                }
              }
            });
          }}
        />
      }
    </Grid>
  );
};

export default CustomTable;
export { paginationConfig };