import axios from 'axios';

const S3 = {
  getPrivateFileURL: key => {
    return axios.get(`aws-s3/private-file-url?key=${key}`);
  },
  getFileUploadURL: payload => {
    return axios.post('aws-s3/file-upload-url', payload);
  },
  uploadToS3: (urlToUpload, fileToUpload, fileType) => {
    return axios.put(urlToUpload, fileToUpload, {
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      withCredentials: false,
    });
  },
  removeFiles: keys => axios.post('aws-s3/remove-file', { Keys: keys }), // array of keys
};

export default S3;
