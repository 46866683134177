const taxExclusive = {
  commercial: ({ lineAmount, taxAmount,quantity }) => {
    return (lineAmount + taxAmount)/quantity;
  },
  tax: ({ lineAmount, quantity}) => {
    return lineAmount / quantity ;
  },
};

const taxInclusive = {
  commercial: ({ lineAmount, quantity ,}) => {
    return lineAmount / quantity;
  },
  tax: ({ lineAmount, taxAmount ,quantity}) => {
    return (lineAmount - taxAmount) / quantity;
  },
  quote: ({ lineAmount, taxAmount, quantity ,}) => {
    return (lineAmount + taxAmount) / quantity;
  },
};

const handleCalculateUnitPrice = ({ amountTypes, invoiceType, lineAmount, taxAmount, quantity }) => {
  if (amountTypes?.toLowerCase() === 'exclusive') {
    return taxExclusive[invoiceType === 'quote' ? 'commercial' : invoiceType]({ lineAmount, taxAmount, quantity });
  } else {
    return taxInclusive[invoiceType]({ lineAmount, taxAmount, quantity });
  }
};

export default handleCalculateUnitPrice;
