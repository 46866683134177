import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    paddingTop: "30px",
    // pagebreakInside: "avoid",
  },
});

const Footer = () => {
  const classes = useStyles();
  const { bank } = JSON.parse(localStorage.getItem("bankInfo"));
  const terms = JSON.parse(localStorage.getItem("terms"));
  return (
    <Grid container className={classes.container} style={{ lineHeight: 1.5 }}>
      <Grid item>
        ព័ត៌មានអំពីការទូទាត់ប្រាក់តាមធនាគារ/Bank Information for remittance of
        payment:
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs="auto">Bank Name:</Grid>
        <Grid item xs>
          <b>{bank?.name}</b>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs="auto">Swift Code:</Grid>
        <Grid item xs>
          <b>{bank?.swiftCode}</b>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs="auto">Bank Address:</Grid>
        <Grid item xs>
          <b>{bank?.address}</b>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs="auto">Account Name:</Grid>
        <Grid item xs>
          <b>{bank?.accountName}</b>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item >Account Number:</Grid>
        <Grid item>
          <b style={{ whiteSpace: "pre-line" }}>{bank?.accountNumber}</b>
        </Grid>
      </Grid>
      {terms &&
        <Grid container item spacing={1}>
          <Grid item>Term & Payment Advice:</Grid>
          <Grid item>
            <b style={{ whiteSpace: "pre-line" }}>{terms}</b>
          </Grid>
        </Grid>
      }
    </Grid>
  );
};

export default Footer;
