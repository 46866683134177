import React, { useCallback, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  Box,
  IconButton,
  Divider,
  makeStyles,
  Container,
  InputAdornment,
} from "@material-ui/core";
import AccountCircle from '@material-ui/icons/AccountCircle';
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useForm, useFieldArray, Controller, FormProvider } from "react-hook-form";
import ContainerWrapper from "../../../components/container-wrapper";
import RHFTextInput from "../../../components/rhf-controlled-input/text-input";
import RHFSelectInput from "../../../components/rhf-controlled-input/select-input";
import RHFDatePicker from "../../../components/rhf-controlled-input/date-picker";
import { usePopup } from "../../../components/context/popup-context";
import contactTypes from "../../../data/contact-types.json";
import ContactAutocomplete from "../../../components/rhf-controlled-input/contact-autocomplete";
import LineItems from "../../../components/lineItems";

const useStyles = makeStyles((theme) => ({
  container: {
    // maxHeight: 500,
  },
  button: {
    textTransform: "capitalize",
    margin: `0px ${theme.spacing(1)}px`,
  },
  actionButton: {
    color: theme.palette.error.main,
  },
  avatarStyle: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "uppercase",
    marginRight: theme.spacing(1),
  },
  subSection: {
    margin: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    marginBottom: 10,
  },
  sectionLabel: {
    marginTop: "1rem",
  },
  textWithUnderline: {
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "end",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

const defaultPhone = [
  {
    phoneType: "DEFAULT",
    label: "Phone",
  },
  // {
  //   phoneType: 'FAX',
  //   label: 'Fax',
  // },
  // {
  //   phoneType: 'MOBILE',
  //   label: 'Mobile',
  // },
  // {
  //   phoneType: 'DDI',
  //   label: 'Direct dial',
  // },
];

const defaultAddresses = [
  {
    addressType: "POBOX",
    label: "Address in English",
  },
  {
    addressType: "STREET",
    label: "Address in Khmer",
  },
];

const defaultLineAmountTypes = [
  {
    value: 'Exclusive',
    label: 'Tax Exclusive'
  },
  {
    value: 'Inclusive',
    label: 'Tax Inclusive'
  },
  {
    value: 'NoTax', 
    label: 'No Tax'
  },
];

const defaultValues = { 
  accountNumber: "",
  name: "",
  type: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  skypeUserName: "",
  website: "",
  companyNumber: "",
  taxNumber: "",
  contactPersons: [],
  isSupplier: false,
  isCustomer: false,
  phones: defaultPhone,
  addresses: defaultAddresses,
  date: new Date(),
  dueDate: new Date(),
  lineAmountTypes: 'Exclusive',
  lineItems: [{
    item: null,
    description: '',
    quantity: '',
    unitAmount: '',
    accountCode: '',
    account: null,
    taxType: '',
    tracking: '',
    lineAmount: '',
    tax: null,
  }]
};

const CreateUpdateInvoice = () => {
  const classes = useStyles();
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const history = useHistory();
  // if have contactID mean we need to update it
  const { id: invoiceID } = useParams();
  const methods = useForm(
    {
      shouldFocusError: true,
      // add defaultValues to prevent uncontrolled input warning
      defaultValues: defaultValues
    },
  );

  const { errors, handleSubmit, control, reset } = methods

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       showSpinner();
  //       if (contactID) {
  //         const { data } = await axios.get(
  //           `${process.env.REACT_APP_SERVER_URI}/contacts/${contactID}`,
  //           { withCredentials: true },
  //         );
  //         // combine addressLine1, addressLine2, addressLine3, addressLine4 to create addressLine by "\n"
  //         data.addresses = data.addresses.map((address) => {
  //           // all field that need to check if it have value
  //           const arrayaddressLine = [
  //             "addressLine1",
  //             "addressLine2",
  //             "addressLine3",
  //             "addressLine4",
  //           ];
  //           const existingAddressLine = [];
  //           arrayaddressLine.forEach((item) => {
  //             // if it have value store it in `existingAddressLine`
  //             if (address[item]) {
  //               existingAddressLine.push(address[item]);
  //             }
  //           });
  //           // combine all the value to create one string for display
  //           address.addressLine = existingAddressLine.join("\n") ?? "";
  //           return address;
  //         });
  //         data.type = data?.type?.number ?? "";
  //         data.phones = data.phones.filter(
  //           (item) => item.phoneType === "DEFAULT",
  //         );
  //         reset(data);
  //       }
  //     } catch (err) {
  //       showAlertPopup({
  //         message: err.response?.data?.message
  //           ? err.response.data.message
  //           : "Sorry Something Went Wrong",
  //         success: false,
  //         onExiting: () => history.goBack(),
  //       });
  //     } finally {
  //       hideSpinner();
  //     }
  //   };
  //   getData();
  // }, [contactID]);

  const {
    fields: contactPersonsFields,
    append: appendPerson,
    remove: removePerson,
  } = useFieldArray({
    control,
    name: "contactPersons",
  });
  const {
    fields: addressesFields,
    insert: insertAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: "addresses",
  });

  const { fields: phonesFields } = useFieldArray({
    control,
    name: "phones",
  });

  const onSubmit = async (data) => {
    try {
      const tempData = {...data} // avoid mutant object
      const formatData = {}
      formatData.contact = {contactID: tempData.contact.contactID}
      formatData.lineItems = tempData.lineItems.splice(0, tempData.lineItems.length - 1).map((lineItem, index) => {
        const formatItem = {
          description: lineItem.item.description,
          quantity: lineItem.quantity,
          unitAmount: lineItem.unitAmount,
          accountCode: lineItem.account.code,
        }

        if (lineItem.item) { 
          formatItem.itemCode = lineItem.item.code;
        }

        if (lineItem.account) { 
          formatItem.accountCode = lineItem.account.code;
        }

        if (lineItem.tax) { 
          formatItem.taxType = lineItem.tax.taxType;
        }

        return formatItem;
      })
      formatData.type = "ACCREC";
      formatData.date = tempData.date;
      formatData.dueDate = tempData.dueDate;
      formatData.reference = tempData.reference;
      formatData.status = "DRAFT"
    //   const { addresses } = data;
    //   // convert addressLine to addressLine1, addressLine2, addressLine3, addressLine4 by "\n"
    //   addresses.map((address) => {
    //     const arrayAddressLine = address.addressLine.split("\n");
    //     address.addressLine1 = arrayAddressLine[0] ?? "";
    //     address.addressLine2 = arrayAddressLine[1] ?? "";
    //     address.addressLine3 = arrayAddressLine[2] ?? "";
    //     address.addressLine4 =
    //       arrayAddressLine.slice(3, arrayAddressLine.length + 1).join("\n") ??
    //       "";
    //     return address;
    //   });
    //   const typeObject = contactTypes.filter(
    //     (contact) => contact.number === data.type,
    //   );
    //   data.type = typeObject[0] ?? null;
      showSpinner();

      if (!invoiceID) {
        // await axios.post(`${process.env.REACT_APP_SERVER_URI}/invoices`, formatData, {
        //   withCredentials: true,
        // });
      } else {
        await axios.put(
          `${process.env.REACT_APP_SERVER_URI}/invoices/${invoiceID}`,
          formatData,
          { withCredentials: true },
        );
      }
      reset();
      showAlertPopup({
        success: true,
        onExiting: () => history.push("/invoices"),
      });

    } catch (err) {
      console.error(err.response, "err");
      showAlertPopup({
        message: err.response?.data?.message
          ? err.response.data.message
          : `Fail To ${invoiceID ? "Update" : "Create"} Contact`,
        success: false,
      });
    } finally {
      hideSpinner();
    }
  };

  const handleAddAnotherPerson = (event) => {
    event.preventDefault();
    appendPerson({
      firstName: "",
      lastName: "",
      emailAddress: "",
      includeInEmails: false,
    });
  };

  return (
    <Container>
      <h1>{!invoiceID ? "Create" : "Update"} Invoice</h1>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerWrapper>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <h2>Invoice</h2>
                    <Divider />
                  </Grid>
                  <Grid container spacing={1} item xs={12}>
                    <Grid item xs={4}>
                      <ContactAutocomplete
                        name="contact"
                        rules={{ required: true }}
                        label="To *"
                        fullWidth
                        error={!!errors.contact}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextInput
                        name="reference"
                      //   rules={{ required: true }}
                        label="Reference"
                        fullWidth
                        error={!!errors.reference}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextInput
                        name="invoiceNumber"
                      //   rules={{ required: true }}
                        label="Invoice number"
                        fullWidth
                        error={!!errors.name}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFDatePicker
                        name="date"
                        rules={{ required: true }}
                        label="Issue date"
                        fullWidth
                        error={!!errors.name}
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}                  
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFDatePicker
                        name="dueDate"
                        rules={{ required: true }}
                        label="Due date"
                        fullWidth
                        error={!!errors.dueDate}
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}   
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFSelectInput
                        label="Amounts are *"
                        name="lineAmountTypes"
                        options={defaultLineAmountTypes}
                        required
                        labelIdentifier="label"
                        valueIdentifier="value"
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    {/* {fields.map((item, index) => {
                      return ();
                    })} */}
                    {/* ItemCode */}
                    {/* TaxAmount */}
                    <LineItems defaultValue={defaultValues.lineItems}/>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={7}></Grid>
                    <Grid container item xs={true}>
                      <Grid container item justify="space-between" xs={12}>
                        <Typography>Subtotal</Typography>
                        <Typography>000</Typography>
                      </Grid>
                      <Grid container item justify="space-between" xs={12}>
                        <Typography>Total Tax</Typography>
                        <Typography>000</Typography>
                      </Grid>
                      <Grid  item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid container item justify="space-between" xs={12}>
                        <Typography>Total</Typography>
                        <Typography>000</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ContainerWrapper>
            </Grid>
                      
            <Grid
              container
              item
              xs={12}
              sm={6}
              style={{ alignContent: "flex-start" }}
              spacing={1}
            >
            </Grid>
            <Grid item xs={12} style={{ textAlign: "end" }}>
              <Button type="submit" color="primary" variant="contained">
                {!invoiceID ? "Create" : "Update"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Container>
  );
};

export default CreateUpdateInvoice;
