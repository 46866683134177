import { Typography } from "@material-ui/core";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles({
  policy: {
    backgroundColor: "lightgray",
    border: "1px solid",
    padding: "10px",
    width: "100%",
  },
});

const Footer = () => {
  const classes = useStyles();
  const voucherValidation = JSON.parse(
    localStorage.getItem("voucherValidation"),
  );

  return (
    <Box className={classes.policy} mt={3}>
      <Typography style={{ textDecoration: "underline", fontWeight: "bold" }}>
        Company Policy and Guidelines:
      </Typography>
      1. Please put a tick "✔" in one of boxes in row "Received By". 
      <br />
      2. Quantity and Amount in Sale Invoice shall agree to Contract/Agreement and Official Receipts.
      <br />
      3. Amount in Official Receipt shall agree to amount received by Cash, Cheque or Bank transfers. <br />
      4. All supporting documents shall be approved by authorized person.
      <br />
      5. Sale Invoice shall be stamped "RECEIVED" after Payer made payments.
      <br />
      6. Payment Voucher (PV) shall be properly reviewed by{" "}
      <b>{voucherValidation.reviewedByPosition}</b> before posting into the
      system.
      <br />
      7. Receipt Voucher (RV) shall be stamped "POSTED" after reviewed and approved.
      <br />
    </Box>
  );
};

export default Footer;
