import { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import './App.css';
import Navbar from './components/navbar';
import ErrorContext from './components/error-context';

import Login from './routes/login';
import Dashboard from './routes/dashboard';
import LoginSuccess from './routes/login/success';
import dashboardRoutes from './routes/routes';

import { logoutSuccess } from './redux-store/slices/user';
import { PopupProvider } from './components/context/popup-context'

/**
 * ===== NOTE =====
 * initialize axios default config
 * when make any request using axios no need to pass process.env.REACT_APP_SERVER_URI
 * it already config as baseURL
 * you can config axios for other setting in /src/lib/fetcher.js
 */
import './lib/fetcher';

const App = () => {
  const { user } = useSelector(state => state.userReducer);
  const dispatch = useDispatch();
  const context = useContext(ErrorContext);
  const { modifyStatus, modifyErrMessage } = context;

  // use axios.interceptors to track down unauthenticated
  axios.interceptors.response.use(
    res => {
      modifyStatus(res?.status);
      return res;
    },
    err => {
      console.error('err in interceptors', err);
      // if error authenticate redirect to xero url for authenticate user again
      if (err?.response?.status === 401) {
        if ( err?.response?.data?.key === "generate-new-xero-url-for-authenticate-user" && err?.response?.data?.url ) {
          return window.location = err?.response?.data?.url;
        }
        dispatch(logoutSuccess({ isAuthenticated: false, isApproved: false }));
        localStorage.clear();
        return;
      }
      modifyStatus(err?.response?.status);
      modifyErrMessage(err?.response?.data?.message);
      return Promise.reject(err);
    }
  );

  return (
    <Router>
      {/* <AlertDialog /> */}
      <Switch>
        <PopupProvider>
          <Route exact={true} path='/login' component={Login} />
          <Route exact={true} path='/login-success' component={LoginSuccess} />
  
          {!user.isAuthenticated && !localStorage.getItem('tenant') ? (
            <Redirect to='/login' />
          ) : (
            <Navbar>
              <Route exact={true} path='/dashboard' component={Dashboard} />
              {dashboardRoutes.map((item, index) => (
                    <Route {...item} key={index} />
                  ))}
              {/* {user.isApproved ? (
                <>
                  {dashboardRoutes.map((item, index) => (
                    <Route {...item} key={index} />
                  ))}
                </>
              ) : (
                <Redirect to='/dashboard' />
              )} */}
            </Navbar>
          )}
        </PopupProvider>
      </Switch>
    </Router>
  );
};

export default App;
