import { forwardRef } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography as OriginalTypography,
  styled,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

import InvoiceListing from "./invoice-listing";
import SignatureSection from "./signature-section";
import TermsAndConditions from "./terms-and-conditions";
import { defaultStyles, khmerFontFamily , englishFontFamily } from "../config";

const Typography = styled(OriginalTypography)({
  fontSize: defaultStyles.fontSize,
  padding: "1px 0",
});

// max-height: 976px

const useStyles = makeStyles({
  pdfWrapper: {
    fontFamily: khmerFontFamily,
  },
  container: {
    margin: "0 auto",
    fontSize: defaultStyles.fontSize,
    position: "relative",
    fontFamily: khmerFontFamily,
    padding: 30,
    lineHeight: defaultStyles.lineHeight - 0.5,
  },
  header: {
    textAlign: "right",
    position: "relative",
    top: 0,
    width: "100%",
    // marginBottom: "300px",
  },
  logo: {
    width: 200,
    // position: "absolute",
    // left: 0,
  },
  body: {
    // marginTop: "200px",
  },
  pagebreak: {
    pageBreakBefore: "always",
  },
  headerWrpper: {
    width: "65%",
    display: "inline-block",
    fontFamily: englishFontFamily,
  },
  sectionBorder: {
    padding: "4px 2px",
    ...defaultStyles.border,
  },
  invoiceDetail: {
    padding: "2px 0",
  },
  khmerStyle: {
    fontFamily: khmerFontFamily,
  },
  englishStyle: {
    fontFamily: englishFontFamily,
  },
});

const KongchakPicturesInvoice = (
  {
    data,
    invoiceType,
    contentHeights,
    footerHeight,
    exchangeRate,
    signatureHeight,
    printScale = false,
    image = null,
  },
  ref,
) => {
  const classes = useStyles();
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const { company } = JSON.parse(localStorage.getItem("companyInfo"));
  const getInvoiceNo = (invoiceNo) => {
    const [, invoiceNumber] = invoiceNo.split("-");

    if (invoiceType === "commercial" && company?.commercialInvoicePrefix) {
      return `${company?.commercialInvoicePrefix}-${invoiceNumber}`;
    } else if (invoiceType === "tax" && company?.taxInvoicePrefix) {
      return `${company?.taxInvoicePrefix}-${invoiceNumber}`;
    }

    return invoiceNo;
  };
  let footerPadding = [],
    signaturePadding = [],
    allHeight,
    pageHeight = 976;
  contentHeights &&
    contentHeights.forEach((height, idx) => {
      allHeight = height + signatureHeight + footerHeight;
      if (allHeight > pageHeight) {
        footerPadding[idx] = signaturePadding[idx] = 0;
        const exceedPage = (allHeight =
          (height % pageHeight) + signatureHeight + footerHeight - pageHeight);
        if (exceedPage > 0) {
          if (exceedPage <= footerHeight) {
            footerPadding[idx] = footerHeight - exceedPage;
          } else {
            signaturePadding[idx] = footerHeight + signatureHeight - exceedPage;
          }
        }
      }
    });

  const invoiceDetailSection1Label = 4;
  const invoiceDetailSection1Value = 8;

  const invoiceDetailSection2Label = 7;
  const invoiceDetailSection2Value = 5;

  const invoiceDetailSectionLeft = 7;
  const invoiceDetailSectionRight = 5;

  return (
    <Box className={classes.pdfWrapper}>
      <div ref={ref} className={classes.testing}>
        {data.map((invoice, key) => {
          const streetaddress = invoice.contact?.addresses.filter(
            (address) => address.addressType === "STREET",
          )[0];
          const poboxaddress = invoice.contact?.addresses.filter(
            (address) => address.addressType === "POBOX",
          )[0];

          return (
            <div
              key={key}
              className={clsx([classes.container, classes.pagebreak])}
            >
              <div>
                <Box className={classes.header}>
                  <Grid container>
                    <Grid item xs="auto">
                      {image ? (
                        <img className={classes.logo} src={image} alt="" />
                      ) : (
                        tenant.logo.length > 0 && (
                          <img
                            className={classes.logo}
                            src={tenant.logo}
                            alt=""
                          />
                        )
                      )}
                    </Grid>
                    <Grid item xs={true} className={classes.headerWrpper}>
                      <Typography
                        style={{
                          fontWeight: "inherit",
                        }}
                        className={clsx([classes.khmerStyle])}
                      >
                        {company?.name}
                      </Typography>
                      <Typography
                        style={{
                          textTransform: "uppercase",
                        }}
                        className={clsx([classes.englishStyle])}
                      >
                        {tenant.tenantName}
                      </Typography>
                      <Typography className={clsx([classes.khmerStyle])}>{company?.address}</Typography>
                      <Typography className={clsx([classes.englishStyle])}>
                        {tenant.addresses[0]?.addressLine1}
                      </Typography>
                      <Typography className={clsx([classes.englishStyle])}>
                        <span className={clsx([classes.khmerStyle])}>លេខទូរសព្ទ</span>
                        /Telephone ៖ (
                        {tenant.phones[0]?.phoneAreaCode ||
                          tenant.phones[0]?.phoneCountryCode}
                        ){" "}
                        {tenant.phones[0]?.phoneNumber.charAt(0) === "0"
                          ? tenant.phones[0]?.phoneNumber.substring(1)
                          : tenant.phones[0]?.phoneNumber}
                      </Typography>
                      <Typography>
                        <span className={clsx([classes.khmerStyle])}>លេខ អតប</span>
                        /VAT TIN​​ ៖​ {invoice.taxId}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <Divider /> */}
                  <Box style={{ textAlign: "center", margin: "5px 0 10px 0" }}>
                    <Typography
                      style={{
                        fontSize: defaultStyles.fontSize + 5,
                        fontWeight: "bold",
                        fontFamily: "khmer-os-battambang",
                      }}
                      className={clsx([classes.khmerStyle])}
                    >
                      {invoiceType === "commercial"
                        ? "វិក្កយបត្រ"
                        : "វិក្កយបត្រអាករ"}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: defaultStyles.fontSize + 5,
                        fontWeight: "bolder",
                      }}
                      className={clsx([classes.englishStyle])}
                    >
                      {invoiceType === "commercial" ? "INVOICE" : "TAX INVOICE"}
                    </Typography>
                  </Box>
                </Box>
                <Grid container className={classes.sectionBorder}>
                  {/* customer info */}
                  <Grid
                    className={classes.body}
                    container
                    item
                    xs={invoiceDetailSectionLeft}
                    alignContent="flex-start"
                  >
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection1Label}
                        className={clsx([classes.khmerStyle, classes.invoiceDetail])}
                      >
                        ឈ្មោះក្រុមហ៊ុន ឬ អតិថិជន:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection1Value}
                        className={clsx([classes.khmerStyle, classes.invoiceDetail])}
                      >
                        {invoice.contact?.skypeUserName}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection1Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        Company Name/Customer:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection1Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {invoice.contact?.name}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection1Label}
                        className={clsx([classes.khmerStyle, classes.invoiceDetail])}
                      >
                        អាសយដ្ឋាន:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection1Value}
                        className={clsx([classes.khmerStyle, classes.invoiceDetail])}
                      >
                        {`${
                          streetaddress?.addressLine1
                            ? streetaddress.addressLine1
                            : ""
                        } ${
                          streetaddress?.addressLine2
                            ? streetaddress.addressLine2
                            : ""
                        } ${
                          streetaddress?.addressLine3
                            ? streetaddress.addressLine3
                            : ""
                        } ${
                          streetaddress?.addressLine4
                            ? streetaddress.addressLine4
                            : ""
                        }`}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection1Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        Address:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection1Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {`${
                          poboxaddress?.addressLine1
                            ? poboxaddress?.addressLine1
                            : ""
                        } ${
                          poboxaddress?.addressLine2
                            ? poboxaddress?.addressLine2
                            : ""
                        } ${
                          poboxaddress?.addressLine3
                            ? poboxaddress?.addressLine3
                            : ""
                        } ${
                          poboxaddress?.addressLine4
                            ? poboxaddress?.addressLine4
                            : ""
                        }`}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection1Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        <span className={clsx([classes.khmerStyle])}>លេខ អតប</span>
                         (VAT TIN​​):
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection1Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {invoice.contact?.taxNumber}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection1Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        <span className={clsx([classes.khmerStyle])}>ឈ្មោះគម្រោង</span>
                        /Project Name:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection1Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {invoice.reference}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection1Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        Attention to:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection1Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {Array.isArray(invoice?.contact?.contactPersons) &&
                          `${
                            invoice?.contact?.contactPersons[0]?.firstName || ""
                          } 
                            ${
                              invoice?.contact?.contactPersons[0]?.lastName ||
                              ""
                            }`}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Invoice Section */}
                  <Grid
                    container
                    item
                    xs={invoiceDetailSectionRight}
                    style={{ alignSelf: "self-start" }}
                    alignContent="flex-start"
                  >
                    <Grid container item xs={12} style={{ textAlign: "end" }}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        <span className={clsx([classes.khmerStyle])}>លេខវិក្កយបត្រ</span>
                        /Inv No:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection2Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {getInvoiceNo(invoice.invoiceNumber)}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ textAlign: "end" }}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        <span className={clsx([classes.khmerStyle])}>កាលបរិច្ឆេទ</span>
                        /Date Issued:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection2Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {moment(invoice.date).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ textAlign: "end" }}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        <span className={clsx([classes.khmerStyle])}>ថ្ងៃត្រូវសង</span>
                        /Due Date:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection2Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        {moment(invoice.dueDate).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid>
                    <Grid container item style={{ textAlign: "end" }}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        <span className={clsx([classes.khmerStyle])}>អត្រាប្តូរប្រាក់</span>
                        /Exchange Rate:
                      </Grid>
                      <Grid
                        item
                        xs={invoiceDetailSection2Value}
                        className={clsx([classes.englishStyle, classes.invoiceDetail])}
                      >
                        KHR{Number(exchangeRate)?.toLocaleString()}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <InvoiceListing
                  invoice={invoice}
                  exchangeRate={exchangeRate}
                  invoiceType={invoiceType}
                />
              </div>
              <div
                style={
                  {
                    // paddingTop: `${!printScale && footerHeight !== 0 && footerPadding[key]}px`
                  }
                }
              >
                <TermsAndConditions />
              </div>
              <div
                style={
                  {
                    // paddingTop: `${!printScale && signatureHeight !== 0 && signaturePadding[key]}px`
                  }
                }
              >
                <SignatureSection company={company} invoice={invoice} />
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default forwardRef(KongchakPicturesInvoice);
