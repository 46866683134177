import { makeStyles, Chip, IconButton, Button, Typography } from "@material-ui/core";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import { RHFTextInput } from "../rhf-controlled-input";
import ItemAutocomplete from "../rhf-controlled-input/item-autocomplete";
import AccountAutocomplete from "../rhf-controlled-input/account-autocomplete";
import TaxRateAutocomplete from "../rhf-controlled-input/tax-rate-autocomplete";
import roundDecimal from "../../routes/utility/roundDecimal ";

const useStyles = makeStyles((theme) => ({
  tableHeaderCell: {
    padding: theme.spacing(1),
  },
  tableBodyCell: {
    border: "1px solid black",
    padding: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
}));

const LineItems = ({ control, name = "lineItems", defaultValue }) => {
  const classes = useStyles();
  const {
    formState: { dirtyFields, touched },
    setValue,
    watch,
    register,
    getValues,
  } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name,
    },
  );

  const handleAppendOnLastIndex = (index) => {
    if (fields.length === index + 1) {
      append(defaultValue);
    }
  };

  const handleAfterChange = () => {
    console.info("last index ");
  };

  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead
        style={{
          border: "1px solid black",
          backgroundColor: "#0167AD",
          color: "white",
        }}
      >
        <tr>
          <th style={{ width: "20%" }} className={classes.tableHeaderCell}>
            Item
          </th>
          <th className={classes.tableHeaderCell}>Description</th>
          <th className={classes.tableHeaderCell}>Quantity</th>
          <th className={classes.tableHeaderCell}>Unit Price</th>
          <th style={{ width: "20%" }} className={classes.tableHeaderCell}>
            Account
          </th>
          <th style={{ width: "20%" }} className={classes.tableHeaderCell}>
            Tax rate
          </th>
          {/* <th className={classes.tableHeaderCell}>
              Region
            </th> */}
          <th className={classes.tableHeaderCell}>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => {
          const itemData = getValues(`lineItems[${index}].item`)
          const accountData = getValues(`lineItems[${index}].account`)
          const taxData = getValues(`lineItems[${index}].tax`)
          const quantityData = getValues(`lineItems[${index}].quantity`)
          const unitAmountData = getValues(`lineItems[${index}].unitAmount`)
          return (
            <tr key={field?.id}>
              <td className={classes.tableBodyCell}>
                { itemData?.code && <Chip variant="outlined" size='small' color="primary" label={itemData?.code}/>}
                <ItemAutocomplete
                  name={`lineItems[${index}].item`}
                  fullWidth
                  disableUnderline
                  // error={!!errors?.lineItems[index]?.item}
                  // append({
                  //   item: data,
                  //   description: data.description,
                  //   quantity: 1,
                  //   unitAmount: data?.salesDetails?.unitPrice,
                  //   accountCode: data.code,
                  //   taxType: 'taxType',
                  //   tracking: 'tracking',
                  //   lineAmount: Number(data?.salesDetails?.unitPrice) * 1
                  // })
                  margin="dense"
                  onAfterChange={(data) => {
                    // remove(index)
                    if (data) {
                      // setValue(`lineItems[${index}]`, {
                      //   // item: data,
                      //   description: data.description,
                      //   quantity: 1,
                      //   unitAmount: data?.salesDetails?.unitPrice,
                      //   // accountCode: data?.salesDetails?.accountCode,
                      //   taxType: data?.salesDetails?.taxType,
                      //   tracking: "tracking",
                      //   lineAmount: Number(data?.salesDetails?.unitPrice) * 1,
                      //   account: { name: 'test', code: '123' },
                      //   tax: data.salesDetails.tax,
                      // });
                      setValue(`lineItems[${index}].description`, data.description);
                      setValue(`lineItems[${index}].quantity`, 1);
                      setValue(`lineItems[${index}].unitAmount`, data?.salesDetails?.unitPrice);
                      setValue(`lineItems[${index}].account`, data?.salesDetails.account ?? null);
                      setValue(`lineItems[${index}].tax`, data?.salesDetails.tax ?? null);
                      setValue(`lineItems[${index}].lineAmount`, Number(data?.salesDetails?.unitPrice) * 1);
                      // register(`lineItems[${index}].accountCode`, {
                      //   setValueAs: (value) => data?.salesDetails?.accountCode,
                      // });
                      // register(`lineItems[${index}].taxType`, {
                      //   setValueAs: (value) => data?.salesDetails?.taxType,
                      // });
                     
                    }
                    handleAppendOnLastIndex(index);
                    // insert(index, )
                  }}
                  defaultValue={field.item}
                />
              </td>
              <td className={classes.tableBodyCell}>
                <RHFTextInput
                  name={`lineItems[${index}].description`}
                  fullWidth
                  disableUnderline
                  // error={!!errors?.lineItems[index]?.description}
                  margin="dense"
                  defaultValue={field.description}
                  multiline
                  onAfterChange={() => handleAppendOnLastIndex(index)}
                />
              </td>
              <td className={classes.tableBodyCell}>
                <RHFTextInput
                  name={`lineItems[${index}].quantity`}
                  fullWidth
                  disableUnderline
                  // error={!!errors?.lineItems[index]?.description}
                  margin="dense"
                  type="number"
                  defaultValue={field.quantity}
                  onAfterChange={(event) => {
                    setValue(`lineItems[${index}].lineAmount`, roundDecimal({value: Number(event?.target?.value) * Number(unitAmountData)})) ;
                    handleAppendOnLastIndex(index)
                  }}
                />
              </td>
              <td className={classes.tableBodyCell}>
                <RHFTextInput
                  name={`lineItems[${index}].unitAmount`}
                  fullWidth
                  disableUnderline
                  // error={!!errors?.lineItems[index]?.description}
                  maxrgin="dense"
                  defaultValue={field.unitAmount}
                  type="number"
                  onAfterChange={(event) => {
                    setValue(`lineItems[${index}].lineAmount`, roundDecimal({value: Number(event?.target?.value) * Number(quantityData)}));
                    handleAppendOnLastIndex(index)
                  }}
                />
              </td>
              <td className={classes.tableBodyCell}>
                {/* <RHFTextInput
                    name={`lineItems[${index}].accountCode`}
                    fullWidth
                    disableUnderline
                    // error={!!errors?.lineItems[index]?.description}
                    margin="dense"
                    defaultValue={field.accountCode} 
                  /> */}
                  {field?.account?.code && (
                     <Typography variant='body2' style={{ fontWeight: "bold"}} color="primary">
                      {field?.account?.code}
                      <span style={{ fontWeight: "normal", color: 'black'}}> - </span>
                     </Typography>
                  ) }
                 
                <AccountAutocomplete
                  name={`lineItems[${index}].account`}
                  fullWidth
                  disableUnderline
                  // error={!!errors?.lineItems[index]?.account}
                  margin="dense"
                  // onChange={( data ) => {
                  // }}
                  defaultValue={field.account}
                  onAfterChange={() => handleAppendOnLastIndex(index)}
                />
              </td>
              <td className={classes.tableBodyCell}>
                {/* <RHFTextInput
                    name={`lineItems[${index}].taxType`}
                    fullWidth
                    disableUnderline
                    // error={!!errors?.lineItems[index]?.description}
                    margin="dense"
                    defaultValue={field.taxType} 
                  /> */}
                  {taxData?.displayTaxRate?.toString() && (
                    <Typography
                    variant="body2"
                    style={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    ({taxData?.displayTaxRate}%)
                  </Typography>
                  )}
                <TaxRateAutocomplete
                  name={`lineItems[${index}].tax`}
                  fullWidth
                  disableUnderline
                  // error={!!errors?.lineItems[index]?.tax}
                  margin="dense"
                  // onChange={( data ) => {
                  // }}
                  defaultValue={field.tax}
                  onAfterChange={() => handleAppendOnLastIndex(index)}
                />
              </td>
              {/* <td className={classes.tableBodyCell}>
                  <RHFTextInput
                    name={`lineItems[${index}].tracking`}
                    fullWidth
                    disableUnderline
                    // error={!!errors?.lineItems[index]?.description}
                    margin="dense"
                    defaultValue={field.tracking} 
                  />
                </td> */}
              <td className={classes.tableBodyCell}>
                <RHFTextInput
                  name={`lineItems[${index}].lineAmount`}
                  fullWidth
                  disableUnderline
                  // error={!!errors?.lineItems[index]?.description}
                  margin="dense"
                  defaultValue={field.lineAmount}
                  disabled
                />
              </td>
              <td className={classes.tableBodyCell}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={classes.deleteButton}
                  onClick={() => handleRemove(index)}
                  disabled={fields.length === index + 1}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LineItems;
