// will be use as default value for filter between date
export default function getDefaultFilterBetweenDate ( ) {
  const tempDate = new Date();
  const y = tempDate.getFullYear();
  const m = tempDate.getMonth();
  // get the start of the first day of the month 
  const firstDay = new Date(y, m - 1, 1, 0, 0, 0, 0);
  // get the end of the last day of the month
  const lastDay = new Date(y, m, 0, 23, 59, 59);
 
  return ({startDate: firstDay, endDate: lastDay})
}
