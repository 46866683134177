import { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
// import qs from 'qs';

import "react-datepicker/dist/react-datepicker.css";

// import CreateDeliveryButton from "./components/create-delivery-button/index";
import CreateDeliveryModal from "./components/create-delivery-modal";

import {
  Grid,
  makeStyles,
  Container,
  Switch,
  Input,
  Typography,
  TextField,
  debounce,
  Link as MUILink,
  // Button,
} from "@material-ui/core";
import axios from "axios";
// import RefreshIcon from '@material-ui/icons/Refresh';
import { usePopup } from "../../components/context/popup-context";
import CustomTable, { paginationConfig } from "../../components/custom-table";
import DateTimeRangePicker from "../../components/date-time-range-picker";
import useQuery from "../../components/custom-hook/use-query";
import settingApi from "../../api/setting";
// import CreateDeliveryButton from "./components/create-delivery-button";
import PrintButton from "../../components/print-button";
import {
  getDeliveryNoteTemplate,
  getInvoiceTemplate,
  getInvoicePLTTemplate,
  getReceiptVoucherTemplate,
  getUnregisterInvoiceTemplate
} from "./getTemplates";

const useStyles = makeStyles((theme) => ({
  container: {},
  datePicker: {
    border: "none",
    borderBottom: "1px solid grey",
    padding: "0.42em",
    fontSize: 16,
    zIndex: 100000000000000,
    width: "100%",
  },
  dataPickerWrapper: {
    width: "100%",
  },
  dataPickerPopper: {
    zIndex: 9,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  scaleSwitch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  switchLabel: {
    lineHeight: "100%",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
  },
  loadingContainer: {
    borderBottom: "none",
  },
  printButtonContainer: {
    padding: 2,
  },
  dateFilter: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  printButtonSection: {
    marginTop: theme.spacing(1) / 2, // theme.spacing(1) = 8px
  },
}));

const Invoice = () => {
  const classes = useStyles();
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [allTax, setAllTax] = useState([])
  // const [selectedAll, setSelectedAll] = useState(false);
  const [printScale, setPrintScale] = useState(false);
  // const exchangeRate = JSON.parse(localStorage.getItem("exchangeRate"));
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const logo = localStorage.getItem("logo") ? localStorage.getItem("logo") : tenant?.logo;
  const [exchangeRate, setExchangeRate] = useState(
    Number(JSON.parse(localStorage.getItem("exchangeRateInvoicesBills"))) ||
      4000,
  );
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const textFieldRef = useRef(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [payment, setPayment] = useState([]);
  const [createDelivery, setCreateDelivery] = useState({ open: false });
  const invoiceName = JSON.parse(localStorage.getItem("invoiceName") || '""'); // prevent error when don't have "invoiceName" in local storage
  const {
    data,
    error,
    query,
    loading: fetchLoading,
  } = useQuery(`${process.env.REACT_APP_SERVER_URI}/invoices?type=ACCREC`);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });
  const [date, setDate] = useState();
  const shouldDisablePrintButton =
    selectedInvoices.length <= 0 ||
    exchangeRate === null ||
    exchangeRate === "";

  const handleOpenCreateDelivery = () => {
    setCreateDelivery((prev) => ({ ...prev, open: true }));
  };

  const handleCloseCreateDelivery = () => {
    setCreateDelivery((prev) => ({ ...prev, open: false }));
  };

  const schema = [
    {
      label: "Invoice No.",
      field: "invoiceNumber",
    },
    {
      label: "Invoice Date",
      field: "date",
      render: (invoice) => new Date(invoice.date).toLocaleDateString("en-GB"),
    },
    {
      label: "Contact Name",
      field: "contact.name",
      render: (invoice) => (
        <MUILink
          component={Link}
          to={`/contacts/create/${invoice?.contact?._id}`}
          replace
        >
          {invoice?.contact?.name}
        </MUILink>
      ),
    },
    {
      label: "Sub Totals",
      tableCellProps: { align: "center" },
      field: "subTotal",
      render: (invoice) => invoice.subTotal.toLocaleString(),
    },
    {
      label: "Tax Total",
      tableCellProps: { align: "center" },
      field: "totalTax",
      render: (invoice) => invoice.totalTax.toLocaleString(),
    },
    {
      label: "Total",
      tableCellProps: { align: "center" },
      field: "total",
      render: (invoice) => invoice.total.toLocaleString(),
    },
    {
      label: "Status",
      tableCellProps: { align: "center" },
      field: "status",
      render: (invoice) => (
        <Typography variant="body2" style={{ textTransform: "capitalize" }}>
          {invoice.status === "AUTHORISED"
            ? "awaiting payment"
            : invoice?.status?.toLowerCase()}
        </Typography>
      ),
    },
  ];

  const getPayment = async (invoices) => {
    // get all paymentID
    const paymentIdArray = [];
    invoices.forEach((item) => {
      if (Array.isArray(item?.payments)) {
        if (
          item?.payments.length > 0 &&
          item.payments[item?.payments.length - 1].paymentID
        )
          paymentIdArray.push(
            item.payments[item?.payments.length - 1].paymentID,
          );
      }
    });
    //get payment
    const paymentResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_URI}/invoices/get-payment`,
      {
        withCredentials: true,
        params: {
          id: paymentIdArray,
        },
        paramsSerializer: (params) => {
          let queryString = "";
          params.id.forEach((item) => {
            queryString += `id=${item}&`;
          });

          return queryString;
        },
      },
    );

    setPayment(paymentResponse.data.allPayment);
  }

  const handleBatchInvoiceContent = async (printType = '') => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/invoices/get-selected-invoices`,
        {
          withCredentials: true,
          params: {
            invoiceIds: selectedInvoices.map((inv) => inv.invoiceID),
          },
          paramsSerializer: (params) => {
            let queryString = "";
            params.invoiceIds.forEach((item) => {
              queryString += `invoiceIds=${item}&`;
            });
            return queryString;
          },
        },
      );

      printType === 'voucher' && getPayment(res.data.invoices);

      const formatAllTax = allTax.reduce(
        (accumulator, currentValue) => ({...accumulator, [currentValue.taxType]: currentValue}),
        {}
        );

      const invoicesWithTax = res.data.invoices.map((item) => {
        item.lineItems = item.lineItems.map((lineItem) => ({...lineItem, tax: formatAllTax[lineItem.taxType]}));
        return item;
      });

      setCompanyLogo(logo || null);
      setInvoiceData(invoicesWithTax);
    } catch (err) {
      console.error("Failed to fetch the selected invoices", err);
      showAlertPopup({
        message:
          err?.response?.data?.message ||
          `Failed to fetch the selected invoices`,
        success: false,
      });
      throw err; // cancel print event
    }
  };

  const getAllTax = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URI}/taxRates?noPagination`)
      setAllTax(data.docs)
    } catch (err) {
      console.error(err)
    }
  }

  const handleFetchInvoiceData = async ({
    searchProps = null,
    pageProps = pagination.page,
    perPageProps = pagination.rowsPerPage,
    startDateProps = date?.startDate,
    endDateProps = date?.endDate,
  } = {}) => {
    try {
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // preserve searchProps when changing pagination
        ...(isNullish ? {} : { search: searchProps }),
        page: pageProps,
        perPage: perPageProps,
        startDate: startDateProps?.getTime(),
        endDate: endDateProps?.getTime(),
      });
      setPagination((pre) => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  //handle when Exchange Rate Value change
  const handleExchangeChange = (values) => {
    setExchangeRate(values.value);
    localStorage.setItem(
      "exchangeRateInvoicesBills",
      JSON.stringify(values.value),
    );
  };

  const handlePrintScaleSwitch = (e) => {
    setPrintScale(e.target.checked);
  };

  const handleRefreshInvoice = async () => {
    try {
      showSpinner();
      await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/invoices/sync?type=ACCREC`,
        {
          withCredentials: true,
        },
      );
      handleFetchInvoiceData({ searchProps: null, pageProps: 0 });
    } catch (err) {
      console.error(err?.response);
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Invoice`,
        success: false,
      });
    } finally {
      hideSpinner();
    }
  };

  const handleChangeInvoicePage = (event, page) => {
    handleFetchInvoiceData({ searchProps: null, pageProps: page });
  };

  const handleChangeInvoiceRowsPerPage = (event) => {
    handleFetchInvoiceData({
      searchProps: null,
      pageProps: 0,
      perPageProps: parseInt(event.target.value, 10),
    });
  };
  useEffect(() => {
    handleFetchInvoiceData();
  }, [organization, date, trigger]);

// get tax rate 
  useEffect(() => {
    getAllTax()
    return setAllTax([])
  }, [organization, trigger]);
  
  useEffect(() => {
    if (data) {
      setPagination((pre) => ({ ...pre, count: data.totalDocs }));
    }
  }, [data]);

  // handle search
  const debouncedInvoiceResults = useMemo(() => {
    return debounce((text) => {
      handleFetchInvoiceData({ searchProps: text, pageProps: 0 });
    }, 500);
  }, []);

  return (
    <Container>
      <h1>INVOICES</h1>
      <Grid container spacing={1}>
        <Grid container item spacing={0} direction="column" sm={12} md={6}>
          <Grid item>
            <Typography>Exchange Rate</Typography>
            <NumberFormat
              customInput={Input}
              thousandSeparator={true}
              suffix={" ៛"}
              placeholder="Exchange Rate (៛)"
              style={{ width: "100%" }}
              value={exchangeRate}
              onValueChange={(values) => {
                handleExchangeChange(values);
              }}
              error={
                selectedInvoices.length > 0 &&
                (exchangeRate === null || exchangeRate === "")
              }
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Search :</Typography>
            {/* <Typography>Filter By Invoice No. and Contact Name:</Typography> */}
            <TextField
              inputRef={textFieldRef}
              onChange={(event) => debouncedInvoiceResults(event.target.value)}
              fullWidth
              placeholder="Filter by No. and Contact"
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.dateFilter}>
            <DateTimeRangePicker date={date} elevation={2} setDate={setDate} />
          </Grid>
          <Grid container item spacing={1} justify="flex-end">
            {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: 'capitalize', }}
                startIcon={<RefreshIcon />}
                onClick={handleRefreshInvoice}
              >
                Sync
              </Button>
            </Grid> */}
            {/* <Grid item>
              <CreateDeliveryButton
                onClick={handleOpenCreateDelivery}
                disabled={selectedInvoices.length <= 0 || organization.apiKey === ''}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.printButtonSection}>
        
        {/** Commercial Invoice Button**/}
        <PrintButton
          buttonLabel="Commercial Invoice"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={() =>
            getInvoiceTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: invoiceData,
              printScale: printScale,
              exchangeRate: exchangeRate,
              invoiceType: "commercial",
              name: invoiceName,
              image: companyLogo,
            })
          }
        />

      {/** Tax Invoice Button**/}
        <PrintButton
          buttonLabel="Tax Invoice"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={() =>
            getInvoiceTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: invoiceData,
              printScale: printScale,
              exchangeRate: exchangeRate,
              invoiceType: "tax",
              name: invoiceName,
              image: companyLogo,
            })
          }
        />

        {/** Unregister Invoice Button**/}
        <PrintButton
          buttonLabel="Unregister Invoice"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={() =>
            getUnregisterInvoiceTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: invoiceData,
              printScale: printScale,
              exchangeRate: exchangeRate,
              invoiceType: "commercial",
              name: invoiceName,
              image: companyLogo,
            })
          }
        />

        {/** TAX INVOICE (PLT) Button**/}
        <PrintButton
          buttonLabel="TAX INVOICE (PLT)"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={() =>
            getInvoicePLTTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: invoiceData,
              printScale: printScale,
              exchangeRate: exchangeRate,
              invoiceType: "tax",
              name: invoiceName,
              image: companyLogo,
            })
          }
        />

        {/** Delivery Note Button**/}
        <PrintButton
          buttonLabel="Delivery Note"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={() =>
            getDeliveryNoteTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: invoiceData,
              name: invoiceName,
              image: companyLogo,
            })
          }
        />

        {/** Receipt Voucher Button**/}
        <PrintButton
          buttonLabel="Receipt Voucher"
          onBeforeGetContent={() => handleBatchInvoiceContent('voucher')}
          disabled={shouldDisablePrintButton}
          printComponent={() =>
            getReceiptVoucherTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: invoiceData,
              name: invoiceName,
              image: companyLogo,
              exchangeRate: exchangeRate,
              payment: payment,
            })
          }
        />
      </Grid>
      <Grid container>
        <Grid container item xs="auto">
          <Typography className={classes.switchLabel}>
            Print With Scale :
          </Typography>
          <Switch
            className={classes.switch}
            color="primary"
            onChange={handlePrintScaleSwitch}
            checked={printScale}
          />
        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs}
        loading={fetchLoading}
        error={error}
        tableSize="medium"
        disableCheckBoxSelectPopup
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeInvoicePage,
          onChangeRowsPerPage: handleChangeInvoiceRowsPerPage,
        }}
        getSelectedRow={(arrayValue) => setSelectedInvoices(arrayValue)}
      />
      <CreateDeliveryModal
        open={createDelivery.open}
        handleClose={handleCloseCreateDelivery}
        selectedInvoices={selectedInvoices}
      />
    </Container>
  );
};

export default Invoice;
