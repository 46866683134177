import { Paper, Box } from '@material-ui/core';

/**
 * Container component wrapper content to distinguish section
 */
const ContainerWrapper = ({ children, boxProps = { p: 2 }, paperProps }) => {
	return (
		<Box {...boxProps} component={Paper} {...paperProps}>
			{children}
		</Box>
	);
};

export default ContainerWrapper;
