import React, { useRef, useState, useMemo, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Input, makeStyles, Switch, Button, TextField, Link as MUILink,debounce } from '@material-ui/core';
import NumberFormat from 'react-number-format';

// Icons
import RefreshIcon from '@material-ui/icons/Refresh';

import useQuery from '../../components/custom-hook/use-query';
import CustomTable, { paginationConfig } from '../../components/custom-table';
import DateTimeRangePicker from '../../components/date-time-range-picker';
import { usePopup } from '../../components/context/popup-context';
// import CreateDeliveryButton from '../invoice/components/create-delivery-button';
import settingApi from '../../api/setting';
import PrintButton from '../../components/print-button';
import { getManualJournalTemplate } from './getTemplates';
import { roundDecimal } from '../utility';

const useStyles = makeStyles( theme => ({
  container: {},
  datePicker: {
    border: "none",
    borderBottom: "1px solid grey",
    padding: "0.42em",
    fontSize: 16,
    zIndex: 100000000000000,
    width: "100%",
  },
  dataPickerWrapper: {
    width: "100%",
  },
  dataPickerPopper: {
    zIndex: 9,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  scaleSwitch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  switchLabel: {
    lineHeight: "100%",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
  },
  loadingContainer: {
    borderBottom: 'none'
  },
  printButtonContainer: {
    padding: 2
  },
  dateFilter: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  printButtonSection: {
    marginTop: theme.spacing(1) / 2, // theme.spacing(1) = 8px
  },
}));

const ManualJournal = () => {
  const classes = useStyles();
  const { data, error, query, loading: fetchLoading, } = useQuery(`${process.env.REACT_APP_SERVER_URI}/manual-journal`)
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const [createDelivery, setCreateDelivery] = useState({ open: false });
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const [companyLogo, setCompanyLogo] = useState(null);
  const tenant = JSON.parse(localStorage.getItem('tenant'));
  const invoiceName = JSON.parse(localStorage.getItem("invoiceName") || '""');  // prevent error when don't have "invoiceName" in local storage
  const [selectedManualJournal, setSelectedManualJournal] = useState([]);
  const [allTax, setAllTax] = useState([])
  const [manualJournalData, setManualJournalData] = useState([])
  const [exchangeRate, setExchangeRate] = useState(Number(JSON.parse(localStorage.getItem("exchangeRateInvoicesBills"))) || 4000);
  const [printScale, setPrintScale] = useState(false);
  const [date, setDate] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });
  const textFieldRef = useRef(null)  
  
  const shouldDisablePrintButton = selectedManualJournal.length <= 0 || exchangeRate === null || exchangeRate === "";

  const handlePrintScaleSwitch = (e) => {
    setPrintScale(e.target.checked);
  }

  const handleExchangeRateChange = (values) => {
    setExchangeRate(values.value);
    localStorage.setItem("exchangeRateInvoicesBills", JSON.stringify(values.value));
  };

  const handleFetchManualJournalData = async ({
    searchProps = null,
    pageProps = pagination.page,
    perPageProps = pagination.rowsPerPage,
    startDateProps = date?.startDate,
    endDateProps = date?.endDate,
  } = {}) => {
    try {
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // preserve searchProps when changing pagination 
        ...(isNullish ? {} : { search: searchProps }),
        page: pageProps,
        perPage: perPageProps,
        startDate: startDateProps?.getTime(),
        endDate: endDateProps?.getTime(),
      });
      setPagination(pre => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }))
    } catch (err) {
      console.error(err);
    }
  }

  const debouncedManualJournalResults = useMemo(() => {
    return debounce((text) => {
      handleFetchManualJournalData({ searchProps: text, pageProps: 0 });
    }, 500);
  }, []);

  const handleRefreshManualJournal = async () => {
    try {
      showSpinner();
      await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/manual-journal/sync?deepSync`,
        {
          withCredentials: true,
        }
      );
      handleFetchManualJournalData({ searchProps: null, pageProps: 0 })
    } catch (err) {
      console.error(err?.response);
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Invoice`,
        success: false
      });
    } finally {
      hideSpinner();
    }
  }
  const handleChangeManualJournalPage = (event, page) => {
    handleFetchManualJournalData({ searchProps: null, pageProps: page })
  }
  const handleChangeManualJournalRowsPerPage = (event) => {
    handleFetchManualJournalData({ searchProps: null, pageProps: 0, perPageProps: parseInt(event.target.value, 10) })
  }

  const handleBatchInvoiceContent = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/manual-journal/get-selected-manual-journals`,
        {
          withCredentials: true,
          params: {
            manualJournalID: selectedManualJournal.map((manualJournal) => manualJournal.manualJournalID),
          },
          paramsSerializer: (params) => {
            let queryString = "";
            params.manualJournalID.forEach((item) => {
              queryString += `manualJournalID=${item}&`;
            });
            return queryString;
          },
        }
      );

      const formatAllTax = allTax.reduce(
        (accumulator, currentValue) => ({...accumulator, [currentValue.taxType]: currentValue}),
        {}
      );

      const manualJournalsWithTax = res.data.manualJournals.map((item) => {
        item.journalLines = item.journalLines.map((journalItem) => ({...journalItem, tax: formatAllTax[journalItem.taxType]}));
        return item;
      });

      const { data: response } = await settingApi.getSingleTenant(tenant.tenantId);

      setCompanyLogo(response?.logo?.tempUrl || null);
      setManualJournalData(res.data.manualJournals);
    } catch (err) {
      console.error("Failed to fetch the selected Manual Journal", err);
      throw err; // cancel print event
    }
  };

  const getAllTax = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URI}/taxRates?noPagination`)
      setAllTax(data.docs)
    } catch (err) {
      console.error(err)
    }
  }

  const schema = [
    {
      label: "Narration",
      field: 'narration',
    },
    {
      label: "Date",
      field: 'date',
      render: (manualJournal) => new Date(manualJournal.date).toLocaleDateString("en-GB"),
    },
    {
      label: "Debit USD	",
      field: 'date',
      render: (manualJournal) => {
        if (Array.isArray(manualJournal.journalLines)) {
          return roundDecimal({ value: manualJournal.journalLines.reduce((accumulator, currentValue) => {
            const tempLineAmount = currentValue?.lineAmount ?? 0
            const tempTaxAmount = currentValue?.taxAmount ?? 0
            if (tempLineAmount < 0) {
              return accumulator
            }

            if (manualJournal?.lineAmountTypes?.toLowerCase() === "exclusive") {
              return accumulator + (tempLineAmount + tempTaxAmount)
            }
            return accumulator + tempLineAmount
          }, 0) })
        }
        return ""
      },
    },
    {
      label: "Credit USD	",
      field: 'date',
      render: (manualJournal) => {
        if (Array.isArray(manualJournal.journalLines)) {
          return roundDecimal({ value: manualJournal.journalLines.reduce((accumulator, currentValue) => {
            const tempLineAmount = currentValue?.lineAmount ?? 0
            const tempTaxAmount = currentValue?.taxAmount ?? 0
            if (tempLineAmount > 0) {
              return accumulator
            }

            if (manualJournal?.lineAmountTypes?.toLowerCase() === "exclusive") {
              return accumulator - (tempLineAmount + tempTaxAmount)
            }
            return (accumulator - tempLineAmount)
          }, 0) })
        }
        return ""
      },
    },
    {
      label: 'Status',
      tableCellProps: { align: "center" },
      field: 'status',
      render: (manualJournal) => manualJournal.status.toLocaleString()
    },
  ];

  useEffect(() => {
    handleFetchManualJournalData();
  }, [organization, date, trigger]);

  // get tax rate 
  useEffect(() => {
    getAllTax()
    return setAllTax([])
  }, [organization, trigger]);

  useEffect(() => {
    if (data) {
      setPagination(pre => ({ ...pre, count: data.totalDocs }));
    }
  }, [data])

  return (
    <Container>
      <h1>Manual Journals</h1>
      <Grid container spacing={1}>
        <Grid container item spacing={0} direction="column" sm={12} md={6}>
          <Grid item>
            <Typography>Exchange Rate</Typography>
            <NumberFormat
              customInput={Input}
              thousandSeparator={true}
              suffix={" ៛"}
              placeholder="Exchange Rate (៛)"
              style={{ width: "100%" }}
              value={exchangeRate}
              onValueChange={(values) => {
                handleExchangeRateChange(values);
              }}
              error={
                selectedManualJournal.length > 0 &&
                (exchangeRate === null || exchangeRate === "")
              }
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Search :</Typography>
            <TextField
              inputRef={textFieldRef}
              onChange={(event) => debouncedManualJournalResults(event.target.value)}
              fullWidth
              placeholder="Filter Narration"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.dateFilter}>
            <DateTimeRangePicker
              date={date}
              elevation={2}
              setDate={setDate}
              onAfterChange={(props) => {
                return handleFetchManualJournalData({
                  pageProps: 0,
                  startDateProps: props?.startDate,
                  endDateProps: props?.endDate,
                })
              }}
            />
          </Grid>
        
          {/* <Grid container item spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: 'capitalize', }}
                startIcon={<RefreshIcon />}
                onClick={handleRefreshManualJournal}
              >
                Sync
              </Button>
            </Grid>
          </Grid> */}

        </Grid>
      </Grid>
      <Grid item container className={classes.printButtonSection}>
        <PrintButton
          buttonLabel="Manual Journal"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={ () =>
            getManualJournalTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: manualJournalData,
              printScale: printScale,
              exchangeRate: exchangeRate,
              manualJournalType: 'commercial',
              name: invoiceName,
              image: companyLogo,
            })
          }
        />  
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs}
        loading={fetchLoading}
        error={error}
        tableSize="medium"
        disableCheckBoxSelectPopup
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeManualJournalPage,
          onChangeRowsPerPage: handleChangeManualJournalRowsPerPage,
        }}
        getSelectedRow={(arrayValue) => setSelectedManualJournal(arrayValue)}
      />
    </Container>
  )
}

export default ManualJournal