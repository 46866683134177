import { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import settingApi from '../../api/setting';
// import { logout } from "../../redux-store/slices/user";

import {
  Grid,
  makeStyles,
  Button,
  Container,
  Input,
  Switch,
  Typography,
  TextField,
  debounce,
  Link as MUILink,
} from "@material-ui/core";
import axios from "axios";
import SendIcon from "@material-ui/icons/Send";
import { usePopup } from "../../components/context/popup-context";
import CustomTable, { paginationConfig } from "../../components/custom-table";
import useQuery from "../../components/custom-hook/use-query";
import DateTimeRangePicker from "../../components/date-time-range-picker";
import PrintButton from "../../components/print-button";
import { getPaymentVoucherTemplate, getGoodsReceivedNoteTemplate } from './getTemplates'

const useStyles = makeStyles((theme) => ({
  container: {
    // maxHeight: 500,
  },
  datePicker: {
    border: "none",
    borderBottom: "1px solid grey",
    padding: "0.42em",
    fontSize: 16,
    zIndex: 100000000000000,
    width: "100%",
  },
  dataPickerWrapper: {
    width: "100%",
  },
  dataPickerPopper: {
    zIndex: 9,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  scaleSwitch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  switchLabel: {
    lineHeight: "100%",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
  },
  loadingContainer: {
    borderBottom: "none",
  },
  printButtonContainer: {
    margin: 2,
  },
  dateFilter: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  printButtonSection: {
    marginTop: theme.spacing(1) / 2,
  },
}));

const Bills = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const { allTaxRate: listOfTaxes, allAccounts: listOfAccounts } = JSON.parse(
    localStorage.getItem("tenant"),
  );
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [printScale, setPrintScale] = useState(false);
  // const exchangeRate =  JSON.parse(localStorage.getItem("exchangeRate"));
  const tenant = JSON.parse(localStorage.getItem('tenant'));
  const [exchangeRate, setExchangeRate] = useState(
    localStorage.getItem("exchangeRateInvoicesBills") || 4000,
  );
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const textFieldRef = useRef(null);
  const [accountName, setAccountName] = useState([]);
  const [allTax, setAllTax] = useState([]);
  const [payment, setPayment] = useState([]);
  const [companyLogo, setCompanyLogo] = useState(null);
  const {
    data,
    error,
    query,
    loading: fetchLoading,
  } = useQuery(`${process.env.REACT_APP_SERVER_URI}/invoices?type=ACCPAY`);
  const [billPagination, setBillPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });
  const [date, setDate] = useState();
  const shouldDisablePrintButton = selectedInvoices.length <= 0 || exchangeRate === null || exchangeRate === "";

  const handleBatchInvoiceContent = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/invoices/get-selected-invoices`,
        {
          withCredentials: true,
          params: {
            invoiceIds: selectedInvoices.map((inv) => inv.invoiceID),
          },
          paramsSerializer: (params) => {
            let queryString = "";
            params.invoiceIds.forEach((item) => {
              queryString += `invoiceIds=${item}&`;
            });
            return queryString;
          },
        },
      );
      const paymentIdArray = [];
      res.data.invoices.forEach((item) => {
        if (Array.isArray(item?.payments)) {
          if (
            item?.payments.length > 0 &&
            item.payments[item?.payments.length - 1].paymentID
          )
            paymentIdArray.push(
              item.payments[item?.payments.length - 1].paymentID,
            );
        }
      });
      //get payment
      const paymentResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/invoices/get-payment`,
        {
          withCredentials: true,
          params: {
            id: paymentIdArray,
          },
          paramsSerializer: (params) => {
            let queryString = "";
            params.id.forEach((item) => {
              queryString += `id=${item}&`;
            });

            return queryString;
          },
        },
      );

      const { data: response } = await settingApi.getSingleTenant(tenant.tenantId);
      setCompanyLogo(response?.logo?.tempUrl || null);

      const taxCodeArray = res.data.invoices.map((item) =>
        item.lineItems.map((lineItem) => listOfTaxes[lineItem.taxType]),
      );
      const accountArrayName = res.data.invoices.map((item) =>
        item.lineItems.map((lineItem) => listOfAccounts[lineItem.accountCode]),
      );
      // get tracking
      setPayment(paymentResponse.data.allPayment);
      setAllTax(taxCodeArray);
      setAccountName(accountArrayName);
      setInvoiceData(res.data.invoices);
    } catch (err) {
      console.error("Failed to fetch the selected voucher", err.response);
      showAlertPopup({
        message: err?.response?.data?.message || `Failed to fetch the selected bill`,
        success: false,
      });
      throw err; // cancel print event
    }
  };

  //handle when Exchange Rate Value change
  const hanleExchangeChange = (values) => {
    setExchangeRate(values.value);
    localStorage.setItem(
      "exchangeRateInvoicesBills",
      JSON.stringify(values.value),
    );
  };

  const handlePrintScaleSwitch = (e) => {
    setPrintScale(e.target.checked);
  };

  const handleRefreshBill = async () => {
    try {
      showSpinner();
      await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/invoices/sync?type=ACCPAY`,
        {
          withCredentials: true,
        },
      );
      handleFetchBillData({ searchProps: null, pageProps: 0 });
    } catch (err) {
      console.error(err?.response);
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Bill`,
        success: false,
      });
    } finally {
      hideSpinner();
    }
  };

  const schema = [
    {
      label: "Invoice No.",
      field: "invoiceNumber",
    },
    {
      label: "Invoice Date",
      field: "date",
      render: (invoice) => new Date(invoice.date).toLocaleDateString("en-GB"),
    },
    {
      label: "Contact Name",
      field: "contact.name",
      render: (invoice) => (
        <MUILink
          component={Link}
          to={`/contacts/create/${invoice?.contact?._id}`}
          replace
        >
          {invoice?.contact?.name}
        </MUILink>
      ),
    },
    {
      label: "Sub Totals",
      tableCellProps: { align: "center" },
      field: "subTotal",
      render: (invoice) => invoice.subTotal.toLocaleString(),
    },
    {
      label: "Tax Total",
      tableCellProps: { align: "center" },
      field: "totalTax",
      render: (invoice) => invoice.totalTax.toLocaleString(),
    },
    {
      label: "Total",
      tableCellProps: { align: "center" },
      field: "total",
      render: (invoice) => invoice.total.toLocaleString(),
    },
    {
      label: "Status",
      tableCellProps: { align: "center" },
      field: "status",
      render: (invoice) => (
        <Typography variant="body2" style={{ textTransform: "capitalize" }}>
          {invoice.status === "AUTHORISED"
            ? "awaiting payment"
            : invoice?.status?.toLowerCase()}
        </Typography>
      ),
    },
  ];

  const handleFetchBillData = async ({
    searchProps = null,
    pageProps = billPagination.page,
    perPageProps = billPagination.rowsPerPage,
    startDateProps = date?.startDate,
    endDateProps = date?.endDate,
  } = {}) => {
    try {
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // preserve searchProps when changing pagination
        ...(isNullish ? {} : { search: searchProps }),
        page: pageProps,
        perPage: perPageProps,
        startDate: startDateProps?.getTime(),
        endDate: endDateProps?.getTime(),
      });
      setBillPagination((pre) => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleFetchBillData();
  }, [organization, date, trigger]);

  const handleChangeBillPage = (event, page) => {
    handleFetchBillData({ searchProps: null, pageProps: page });
  };

  const handleChangeBillRowsPerPage = (event) => {
    handleFetchBillData({
      searchProps: null,
      pageProps: 0,
      perPageProps: parseInt(event.target.value, 10),
    });
  };

  useEffect(() => {
    if (data) {
      setBillPagination((pre) => ({ ...pre, count: data.totalDocs }));
    }
  }, [data]);

  // handle search
  const debouncedBillResults = useMemo(() => {
    return debounce((text) => {
      handleFetchBillData({ searchProps: text, pageProps: 0 });
    }, 500);
  }, []);

  return (
    <Container>
      <h1>BILLS</h1>
      <Grid container spacing={1}>
        <Grid container item spacing={1} direction="column" sm={12} md={6}>
          <Grid item>
            <Typography>Exchange Rate</Typography>
            <NumberFormat
              customInput={Input}
              thousandSeparator={true}
              suffix={" ៛"}
              placeholder="Exchange Rate (៛)"
              style={{ width: "100%" }}
              value={exchangeRate}
              onValueChange={(values) => {
                hanleExchangeChange(values);
              }}
              error={
                selectedInvoices.length > 0 &&
                (exchangeRate === null || exchangeRate === "")
              }
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Search :</Typography>
            <TextField
              inputRef={textFieldRef}
              onChange={(event) => debouncedBillResults(event.target.value)}
              fullWidth
              placeholder="Filte by No. and Contact"
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.dateFilter}>
            <DateTimeRangePicker date={date} elevation={2} setDate={setDate} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.printButtonSection}>
        <PrintButton
          buttonLabel="Payment Voucher"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={ () =>
            getPaymentVoucherTemplate({
              data: invoiceData,
              footHeights: [0,0,0],
              contentHeights: [],
              exchangeRate: exchangeRate,
              accountName: accountName,
              allTax: allTax,
              payment: payment,
              image: companyLogo,
            })
          }
        />
        <PrintButton
          buttonLabel="Goods Received Note"
          onBeforeGetContent={() => handleBatchInvoiceContent()}
          disabled={shouldDisablePrintButton}
          printComponent={ () =>
            getGoodsReceivedNoteTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: invoiceData,
              image: companyLogo,
            })
          }
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          style={{ position: "relative" }}
          className={classes.printButtonContainer}
          onClick={() => history.push("/bills/bills-to-xero")}
        >
          Upload Bills to Xero
        </Button>
      </Grid>
      <Grid container>
        <Grid container item xs="auto">
          <Typography className={classes.switchLabel}>
            Print With Scale :
          </Typography>
          <Switch
            className={classes.switch}
            color="primary"
            onChange={handlePrintScaleSwitch}
            checked={printScale}
          />
        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs}
        loading={fetchLoading}
        error={error}
        tableSize="medium"
        disableCheckBoxSelectPopup
        paginationProps={{
          count: billPagination.count,
          rowsPerPage: billPagination.rowsPerPage,
          page: billPagination.page,
          onChangePage: handleChangeBillPage,
          onChangeRowsPerPage: handleChangeBillRowsPerPage,
        }}
        getSelectedRow={(arrayValue) => setSelectedInvoices(arrayValue)}
      />
    </Container>
  );
};

export default Bills;
