import moment from "moment";

export const salesTaxTableSchema = [
  {
    label: 'No. *	',
    field: 'contactStatus',
    tableCellProps:{ align:"center", width: '5%' },
    render: ( { date }, index ) => {
      return index + 1;
    },
  },
  {
    label: 'Date *',
    tableCellProps:{ align:"center", width: '5%' },
    field: 'date',
    render: ( { date }, index ) => {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  {
    label: 'Invoice No. *',
    tableCellProps:{ align:"center", width: '5%' },
    field: 'invoiceNumber'
  },
  {
    label: 'Contact',
    tableCellProps:{ align:"center", width: '5%', },
    field: 'contactStatus',
    children : [
      {
        label: 'Type *',
        tableCellProps:{ align:"center", width: '5%', style: { top: 25 }, },
        field: 'contact.type.number',
      },
      {
        label: 'VAT Number *',
        tableCellProps:{ align:"center", width: '5%', style: { top: 25 }, },
        field: 'contactTaxNumber'
      },
      {
        label: 'Name(KH)',
        tableCellProps:{ align:"center", width: '5%', style: { top: 25 }, },
        field: 'contact.skypeUserName'
      },
      {
        label: 'Name(EN)',
        tableCellProps:{ align:"center", width: '5%', style: { top: 25 }, },
        field: 'contact.name',
      },
    ]
  },
  {
    label: 'Type of Operation *',
    tableCellProps:{ align:"center", width: '5%' },
    field: 'itemTaxType'
  },
  {
    label: 'Original Total Amount',
    tableCellProps:{ align:"center", width: '5%' },
    field: 'lineAmount',
    render: ( data ) => data?.total ? data.total.toLocaleString() : ''
  },
  {
    label: 'Total Amount Included VAT*',
    tableCellProps:{ align:"center", width: '5%' },
    field: 'lineAmount',
    render: ( data ) => data?.itemTotalAmount ? `${data.itemTotalAmount.toLocaleString()} ៛` : ''
  },
  // {
  //   label: 'Description *',
  //   tableCellProps:{ align:"center", width: '20%' },
  //   field: 'description',
  // },
  // {
  //   label: 'Chart Of Accounts',
  //   tableCellProps:{ align:"center", width: '10%' },
  //   field: 'allAccountDescription',
  // },
];

export const purchasesTaxTableSchema = [
    {
      label: 'No. *	',
      field: 'contactStatus',
      tableCellProps:{ align:"center", width: '5%' },
      render: ( { date }, index ) => {
        return index + 1;
      },
    },
    {
      label: 'Date *',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'date',
      render: ( { date }, index ) => {
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      label: 'Invoice No. *',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'invoiceNumber'
    },
    {
      label: 'Contact',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'contactStatus',
      children : [
        {
          label: 'Type *',
          tableCellProps:{ align:"center",width: '5%' },
          field: 'contact.type.number'
        },
        {
          label: 'VAT Number *',
          tableCellProps:{ align:"center", width: '5%' },
          field: 'contactTaxNumber'
        },
        {
          label: 'Name(KH)',
          tableCellProps:{ align:"center", width: '5%' },
          field: 'contact.skypeUserName'
        },
        {
          label: 'Name(EN)',
          tableCellProps:{ align:"center", width: '5%' },
          field: 'contact.name',
        },
      ]
    },
    {
      label: 'Type of Operation',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'itemTaxType'
    },
    {
      label: 'Original Total Amount',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'lineAmount',
      render: ( data ) => data?.total ? data.total.toLocaleString() : ''
    },
    {
      label: 'Total Amount Included VAT*',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'lineAmount',
      render: ( data ) => data?.itemTotalAmount ? `${data.itemTotalAmount.toLocaleString()} ៛` : ''
    },
    // {
    //   label: 'Description *',
    //   tableCellProps:{ align:"center", width: '20%' },
    //   field: 'description',
    // },
    // {
    //   label: 'Chart Of Accounts',
    //   tableCellProps:{ align:"center", width: '10%' },
    //   field: 'allAccountDescription',
    // },
  ];


  export const withholdingTaxTableSchema = [
    {
      label: 'No. *	',
      field: 'contactStatus',
      tableCellProps:{ align:"center", width: '5%' },
      render: ( { date }, index ) => {
        return index + 1;
      },
    },
    {
      label: 'Date *',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'date',
      render: ( { date }, index ) => {
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      label: 'Invoice No. *',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'invoiceNumber'
    },
    {
        label: 'Type of Withholding*',
        tableCellProps:{ align:"center", width: '5%' },
        field: 'withholdingType'
      },
    {
      label: 'Contact',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'contactStatus',
      children : [
        {
          label: 'Type *',
          tableCellProps:{ align:"center", width: '5%' },
          field: 'contact.type.number'
        },
        {
          label: 'VAT Number *',
          tableCellProps:{ align:"center", width: '5%' },
          field: 'contactTaxNumber'
        },
        {
          label: 'Name(EN)',
          tableCellProps:{ align:"center", width: '5%' },
          field: 'contact.name',
        },
      ]
    },
    {
      label: 'Type of Operation *',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'itemTaxType'
    },
    {
      label: 'Original Total Amount',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'subTotal',
      render: ( data ) => data?.subTotal ? data.subTotal.toLocaleString() : ''
    },
    {
      label: 'Total Amount *',
      tableCellProps:{ align:"center", width: '5%' },
      field: 'itemSubTotal',
      render: ( data ) => data?.itemSubTotal ? `${data.itemSubTotal.toLocaleString()} ៛` : ''
    },
    // {
    //   label: 'Chart Of Accounts',
    //   tableCellProps:{ align:"center", width: '10%' },
    //   field: 'allAccountDescription',
    // },
  ];