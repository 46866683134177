import { Typography } from "@material-ui/core";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles({
  policy: {
    backgroundColor: "lightgray",
    border: "1px solid",
    padding: "10px",
    width: "100%",
  },
});

const Footer = () => {
  const classes = useStyles();
  const voucherValidation = JSON.parse(
    localStorage.getItem("voucherValidation"),
  );

  return (
    <Box className={classes.policy} mt={3}>
      <Typography style={{ textDecoration: "underline", fontWeight: "bold" }}>
        Company Policy and Guidelines:
      </Typography>
      1. Please put a tick "✔" in one of boxes in row "Paid By". <br />
      2. Quantity and Amount in Invoice shall agree to Good Received Note (GRN)
      or Delivery Note and Purchase Order.
      <br />
      3. Amount in Official Receipt brought by Payee shall agree to amount paid
      by Cash, Cheque or Bank transfers. <br />
      4. All supporting documents shall be approved by authorised person.
      <br />
      5. Invoice shall be stamped "PAID" after Payee collected payments.
      <br />
      6. Payment Voucher (PV) shall be properly reviewed by{" "}
      <b>{voucherValidation.reviewedByPosition}</b> before posting into the
      system.
      <br />
      7. Payment Voucher (PV) shall be stamped "POSTED" after reviewed and
      approved.
      <br />
      8. Payee's Staff ID and Personal ID card shall be copied and attached with
      other supporting documents as an evidence.
      <br />
    </Box>
  );
};

export default Footer;
