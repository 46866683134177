import { useEffect, useState, useMemo } from "react";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  Typography,
  Box,
  IconButton,
  debounce,
  makeStyles,
  Container,
} from '@material-ui/core'
import { useSelector } from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom'
import axios from "axios";
import CustomTable, { paginationConfig } from '../../components/custom-table';
import { usePopup } from '../../components/context/popup-context';
import useQuery from "../../components/custom-hook/use-query";

const useStyles = makeStyles(( theme ) => ({
    container: {
      // maxHeight: 500,
    },
    button: {
      textTransform: 'capitalize',
      margin: `0px ${theme.spacing(1)}px`
    },
    actionButton:{
      color: theme.palette.error.main,
    },
    avatarStyle:{
      backgroundColor: theme.palette.primary.main,
      textTransform: 'uppercase',
      marginRight: theme.spacing(1),
    }
  }));

const Accounts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showPromptPopup, showAlertPopup, hideSpinner, showSpinner } = usePopup();
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const { data, error, query, loading } = useQuery(`${process.env.REACT_APP_SERVER_URI}/accounts`)
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });

  const schema = [
    {
      label: 'Code',
      field: 'code' 
    },
    {
      label: 'Name',
      // sortable: true,
      // field: 'name',
      tableCellProps:{ width:"40%" },
      render: ( data ) => {
        return(
          <Box alignItems="center">
            <Typography>{data.name}</Typography>
            <Typography variant="body2" color="textSecondary">{data.description}</Typography>
          </Box>
        );
      }
    },
    {
      label: 'Type',
      field: 'type',
    },
    {
      label: 'Status',
      field: 'status'
    },
    {
      tableCellProps:{ width:"auto" },
      label: 'Tax Rate',
      field: 'taxType'
    },
    {
      label: 'Action',
      tableCellProps:{ align:"right" },
      render: ( data ) => {
        return(
          <Box display="flex" justifyContent="end">
            <IconButton 
              aria-label="edit" 
              color="primary"
              onClick={()=> { 
                handleUpdateAccount( data._id );
              }}
            >
              <EditIcon />
            </IconButton>
            {/* <IconButton
              aria-label="delete"
              className={classes.actionButton}
              onClick={()=> { 
                showPromptPopup({
                  handleConfirm: async () => {
                    try {
                      await handleDeleteAccount( data.accountID );
                    } catch (err) {
                      console.error( err, 'err' )
                    }
                  }
                });
              }}
            >
              <DeleteIcon />
            </IconButton> */}
          </Box>
        );
      }
    },
  ]

  const handleFetchAccountData = async ( searchProps, pageProps = pagination.page, perPageProps = pagination.rowsPerPage ) => {
    try{
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // when searchProps is 'null' or 'undefined' it won't be used.
        // so the searchProps stay the same. this effect happen when changing pagination
        ...( isNullish ? {} : { search: searchProps } ),
        page: pageProps,
        perPage: perPageProps,
      });
      setPagination( pre => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }) )
    } catch ( err ) {
      console.error( err );
    }
  }

  useEffect( ( ) => {
    handleFetchAccountData();
  }, [organization, trigger]);
  
  // handle search
  const debouncedAccountResults = useMemo(() => {
    return debounce((text) => {
      handleFetchAccountData( text, 0 );
    //   setSearch(text);
    }, 300);
  }, []);

  // handle sync account data from xero with DB
  const handleRefreshAccount = async () =>{
    try {
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/accounts/sync`, { withCredentials: true });
      handleFetchAccountData();
    } catch ( err ) {
      console.error( err?.response )
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Account`,
        success: false
      });
    }
  }

  const handleUpdateAccount = ( id ) => {
    history.push(`/chart-of-accounts/create/${id}`);
  }

  const handleCreateAccount = ( ) => {
    history.push('/chart-of-accounts/create')
  }

  const handleDeleteAccount = async ( arrayId ) => {
    try {
      showSpinner();
      const tempArrayId = Array.isArray( arrayId ) ? arrayId : [arrayId]
      const querystring = `${(tempArrayId.map( ( id ) => `accountID=${id}` )).join("&")}`
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URI}/accounts/remove?${querystring}`,
        { withCredentials: true },
      );
      showAlertPopup({
        success: true,
      });
      handleFetchAccountData();
    } catch ( err ) {
      showAlertPopup({
        message: err.response?.data?.message 
          ? err.response.data.message
            : `Fail To Delete Accounts`,
        success: false
      });
      console.error( err?.response ?? err );
      throw err;
    } finally {
      hideSpinner();
    }
  }

  const handleDeleteMultipleAccount = ( selectedData ) => {
    const arrayId = selectedData.map( ( item ) => item.accountID );
    return handleDeleteAccount( arrayId );
  }

  useEffect( ( ) => {
    if( data ){
      setPagination( pre => ({ ...pre,  count: data.totalDocs  }) );
    }
  }, [data] )

  const handleChangeAccountPage = ( event, page ) => {
    handleFetchAccountData( null, page )
  }

  const handleChangeAccountRowsPerPage = ( event ) => {
    handleFetchAccountData( null, 0, parseInt(event.target.value, 10) )
  }

  return (
    <Container>
      <h1>Accounts</h1>
      <Grid container justify="space-between">
        <Grid container item  xs={12} sm={6}>
          <Grid item xs={12} >
            <TextField
              id="search"
              placeholder="Search..."
              variant="outlined"
              color="primary"
              size="small"
              fullWidth
              onChange={(event) => debouncedAccountResults(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} justify="flex-end">
          {/* <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<RefreshIcon />}
              onClick={()=> { handleRefreshAccount() }}
            >
              Sync
            </Button>
          </Grid> */}
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => handleCreateAccount()}
            >
              New Account
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs ?? []}
        loading={loading}
        error={error}
        handleDeleteMultiple={handleDeleteMultipleAccount}
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeAccountPage,
          onChangeRowsPerPage: handleChangeAccountRowsPerPage,
        }}
      />
    </Container>
  );
};

export default Accounts;
