import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    marginTop: "1em",
    pagebreakInside: "avoid",
  },
});

const Footer = () => {
  const classes = useStyles();
  const deliveryTerms = JSON.parse(localStorage.getItem("deliveryTerms"));
  return (
    <Grid container className={classes.container} style={{ lineHeight: 1.5 }}>
      {deliveryTerms &&
        <Grid container item spacing={1}>
          <Grid item> 
              <Typography variant="h3" style={{ fontWeight: 'bold'  }}>
                Delivery Terms & Condition:
              </Typography>
            </Grid>
          <Grid item>
            <Typography variant="h3" style={{ whiteSpace: "pre-line" }}>{deliveryTerms}</Typography>
          </Grid>
        </Grid>
      }
    </Grid>
  );
};

export default Footer;
