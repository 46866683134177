import { makeStyles } from "@material-ui/core";
import InvoiceSignatureSection from "../invoice/signature-section";

const useStyles = makeStyles({
  
});

const SignatureSection = ({ company }) => {
  const classes = useStyles();

  return (
    <InvoiceSignatureSection />
  );
};

export default SignatureSection;
