import { useForm } from 'react-hook-form';
import { makeStyles, Container, Divider, Grid, TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  root: {
    margin: '1em 0',
  },
});

const DeliveryTerms = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const deliveryTerms = JSON.parse(localStorage.getItem('deliveryTerms'));
  const tenant = JSON.parse(localStorage.getItem('tenant'));

  const [value, setValue] = useState(deliveryTerms);
  const [isLoading, setIsLoading] = useState(false);

  // change term value whenever Organzation change
  useEffect(() => {
    setValue(JSON.parse(localStorage.getItem('deliveryTerms')));
  }, [deliveryTerms]);

  //handle post data to sever when click button 'save'
  const handleSave = async data => {
    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_SERVER_URI}/organizations/update/${tenant.tenantId}`,
        {
          deliveryTerms: data.deliveryTerms,
        },
        { withCredentials: true }
      );
      localStorage.setItem('deliveryTerms', JSON.stringify(data.deliveryTerms));
      enqueueSnackbar('Save Completed', { variant: 'success' });
    } catch (err) {
      console.error('Failed to update DeliveryTerms', err);
      enqueueSnackbar('Failed to update DeliveryTerms', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className={classes.root}>
      <h2>Delivery Terms & Condition</h2>
      <Divider />
      <br />
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin='normal'
              fullWidth
              multiline
              value={value}
              onChange={e => setValue(e.target.value)}
              minRows={5}
              maxRows={20}
              size='small'
              placeholder='Terms & Payment Advice'
              name='deliveryTerms'
              className={classes.textBox}
              inputRef={register({
                required: false,
              })}
            />
          </Grid>
          <Grid item xs={12} container justifyContent='flex-end'>
            <Button variant='contained' color='primary' type='submit' disabled={isLoading}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default DeliveryTerms;
