import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import useMeasure from '../../../../components/custom-hook/use-measure';
import { roundDecimal, handleCalculateUnitPrice, handleCalculateAmount } from '../../../utility';
import { convertPrice } from '../../../utility';

const useStyles = makeStyles({
  table: {
    margin: '1em 0',
    width: '100%',
    fontSize: 11,
    lineHeight: 1.3,
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        padding: '0px 0.5em',
      },
    },
  },
  textRight: {
    textAlign: 'right',
    fontWeight: 'bold',
    border: '1px solid black',
  },
  noBorder: {
    border: '1px solid white',
    borderRight: '1px solid black',
    borderTop: '1px solid black',
  },
  tableHead:{
    '& tr': {
      border: '1px solid black',
      padding: '0px',
      '& th': {
        border: '1px solid black',
        padding: '1em 0.5em',
      },
    },
  },
  tableBodyContentList: {
    '& tr': {
      borderWidth: '0px 1px 0px 1px',
      borderColor: 'black',
      borderStyle: 'solid',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 0px 1px',
      },
    },
  },
  tableBodyEmptySpace: {
    '& tr:first-child': {
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: '0px 1px 1px 1px',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
      },
    },
  },
});

const DepositSlipListing = ({ depositSlip }) => {
  const classes = useStyles();
  const { ref: tableBodyRef, size } = useMeasure(depositSlip.prepaymentID);
  const minHeight = 250;
  const additionalHeight = minHeight - (size.height || 0);
  
  const renderDepositSlipItems = (amountTypes) =>
    depositSlip.lineItems.map((item, key) => {
      return (
        <tr key={key}>
          <td
            style={{
            }}
          >
            {key + 1}
          </td>
          <td
            style={{
            }}
          >
            {item.description}
          </td>
          <td
            style={{
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.quantity}
          </td>
          <td
            style={{
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: handleCalculateUnitPrice({
                amountTypes,
                invoiceType: "commercial",
                lineAmount: item.lineAmount,
                taxAmount: item.taxAmount,
                quantity: item.quantity,
              }),
              currencyCode: depositSlip.currencyCode,
              convert: false
            })}
          </td>
          {/* amount field */}
          <td
            style={{
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: handleCalculateAmount({
                amountTypes,
                invoiceType: "commercial",
                lineAmount: item.lineAmount,
                taxAmount: item.taxAmount,
              }),
              currencyCode: depositSlip.currencyCode,
              convert: false
            })}
          </td>
        </tr>
      );
    });

  return (
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th style={{ width: '60%' }}>
            បរិយាយ
            <br />
            Description
          </th>
          <th>
            បរិមាណ
            <br />
            Quantity
          </th>
          <th>
            តម្លៃឯកតា
            <br />
            Unit Price
          </th>
          {depositSlip.currencyCode === "USD" ? (
            <th>
              ចំនួន (ដុល្លា)
              <br />
              Amount (USD)
            </th>
          ) : (
            <th>
              ចំនួន (រៀល)
              <br />
              Amount (KHR)
            </th>
          )}  
        </tr>
        
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderDepositSlipItems(depositSlip?.lineAmountTypes)}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        <tr style={{height: additionalHeight > 0 ? additionalHeight : 0}}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])} style={{ paddingTop: 5 }}>
            សរុបរួម/Grand Total
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: depositSlip.total,
              currencyCode: depositSlip.currencyCode,
              convert: false
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default DepositSlipListing;
