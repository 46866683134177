import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

const FileContainer = ( {
    children,
    handleDelete = ( ) => {},
    containerStyle = {},
    innerContainerStyle = {},
} ) => (
  <div 
    style={{
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid lightgray',
      marginBottom: 8,
      marginRight: 8,
      padding: 4,
      boxSizing: 'border-box',
      position: 'relative',
      ...containerStyle
    }}
  >
    <div 
      style={{
        display: 'inline-flex',
        minWidth: 0,
        overflow: 'hidden',
        flexDirection: 'column',
        ...innerContainerStyle,
      }}
    >
    {children}
    <IconButton
      aria-label="remove-image" 
      size="small"
      style={{
        position: 'absolute',
        top: '-10px',
        right: '-10px',
      }}
      onClick={handleDelete}
    >
        <CancelIcon fontSize="inherit" color='error'/>
    </IconButton>
    </div>
  </div>
);

export default FileContainer;
