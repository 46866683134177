import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { FormControl, FormHelperText, makeStyles, MenuItem, Select } from '@material-ui/core';

import { switchOrganizationSuccess } from '../../redux-store/slices/organization';
import { defaultExchangeRate } from '../../data/default-value';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
    color: 'white',
  },
  selectEmpty: {
    height: '40px',
    paddingBottom: '17px',
    marginTop: theme.spacing(2),
  },
}));

const OrganizationSelect = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrganization, setOrganization] = useState('');
  const [organizationList, setOrganizationList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URI}/organizations`, {
        withCredentials: true,
      })
      .then(res => {
        // if tenantIndex doesn't exist in localStorage
        if (!localStorage.getItem('tenantIndex')) {
          localStorage.setItem('tenant', JSON.stringify(res.data.tenants[0]));
          localStorage.setItem('logo', res.data.logo?.tempUrl || '');
          localStorage.setItem('signature', res.data.signatureImage?.tempUrl || '');
          localStorage.setItem('tenantIndex', JSON.stringify(res.data.tenants[0]));
          localStorage.setItem('companyInfo', JSON.stringify({ company: res.data.company }));
          localStorage.setItem('invoiceName', JSON.stringify( res.data.invoiceName || '' ));
          localStorage.setItem('bankInfo', JSON.stringify({ company: res.data.bank }));
          localStorage.setItem('terms', JSON.stringify(res.data.terms ? res.data.terms : ''));
          localStorage.setItem('quotesTerms', JSON.stringify(res.data.quotesTerms ? res.data.quotesTerms : ''));
          localStorage.setItem('deliveryTerms', JSON.stringify(res.data.deliveryTerms ? res.data.deliveryTerms : ''));
          localStorage.setItem(
            'voucherValidation',
            JSON.stringify(res.data.voucherValidation ? res.data.voucherValidation : {})
          );
          localStorage.setItem('apiKey', JSON.stringify(res.data.apiKey ? res.data.apiKey : ''));
          localStorage.setItem('exchangeRate', JSON.stringify(Number(res.data?.exchangeRate) || defaultExchangeRate));
          dispatch(switchOrganizationSuccess({ apiKey: res.data.apiKey || '' }));
        } else {
          // if tenantIndex exist in localStorage
          const apiKeyFromLocalStorage = JSON.parse(localStorage.getItem('apiKey'));
          dispatch(switchOrganizationSuccess({ apiKey: apiKeyFromLocalStorage }));
        }
        setOrganization(localStorage.getItem('tenantIndex') || 0);
        setOrganizationList(res.data.tenants);
      })
      .catch(err => {
        console.error('failed to fetch organization', err.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleChange = event => {
    const tenantIndex = event.target.value;
    setIsLoading(true);
    /// axios post to server to change the active tenant
    axios
      .get(`${process.env.REACT_APP_SERVER_URI}/organizations/switch-organization/${tenantIndex}`, {
        withCredentials: true,
      })
      .then(res => {
        let tmpTenants = organizationList; //reassign the organizationList then update later
        tmpTenants[tenantIndex].allAccounts = res.data.allAccounts;
        tmpTenants[tenantIndex].allTaxRate = res.data.allTaxRate;
        localStorage.setItem('tenant', JSON.stringify(tmpTenants[tenantIndex]));
        localStorage.setItem('logo', res.data.logo?.tempUrl || '');
        localStorage.setItem('signature', res.data.signatureImage?.tempUrl || '');
        localStorage.setItem('tenantIndex', tenantIndex);
        localStorage.setItem('companyInfo', JSON.stringify({ company: res.data.company }));
        localStorage.setItem('invoiceName', JSON.stringify(res.data.invoiceName || ''));
        localStorage.setItem('bankInfo', JSON.stringify({ bank: res.data.bank }));
        localStorage.setItem('terms', JSON.stringify(res.data.terms ? res.data.terms : ''));
        localStorage.setItem('quotesTerms', JSON.stringify(res.data.quotesTerms ? res.data.quotesTerms : ''));
        localStorage.setItem('deliveryTerms', JSON.stringify(res.data.deliveryTerms ? res.data.deliveryTerms : ''));
        localStorage.setItem(
          'voucherValidation',
          JSON.stringify(res.data.voucherValidation ? res.data.voucherValidation : {})
        );
        localStorage.setItem('apiKey', JSON.stringify(res.data.apiKey ? res.data.apiKey : ''));
        localStorage.setItem('exchangeRate', JSON.stringify(Number(res.data?.exchangeRate) || defaultExchangeRate));
        setOrganizationList(tmpTenants);
        setOrganization(tenantIndex);
        dispatch(switchOrganizationSuccess({ apiKey: res.data.apiKey || '' }));
      })
      .catch(err => {
        console.error('error swtich organization', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FormControl variant='filled' className={classes.formControl}>
      <Select
        disabled={isLoading}
        value={selectedOrganization}
        onChange={handleChange}
        name='organizationSelect'
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'organizationSelect' }}
        style={{ backgroundColor: 'white' }}
      >
        {organizationList.map((item, key) => (
          <MenuItem key={key} value={key}>
            {item.tenantName}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText style={{ color: 'white' }}>Select Your Organization</FormHelperText>
    </FormControl>
  );
};

export default OrganizationSelect;
