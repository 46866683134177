import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { roundDecimal } from '../../utility';

import FilesViewer from './files-viewer';
import axios from 'axios';

const usestyles = makeStyles( ( theme ) => ({
    dropZoneContainer: {
      height: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'whitesmoke',
      cursor: 'pointer',
      border: 'dashed darkgray',
    },
    dialogContentText: {
      fontSize: '1.3rem'
    }
}) )

export default function BillToXero() {
    const classes = usestyles();
    const [files, setFiles] = useState([]);
    const defaultStatus = { type: 'success', message: '', renderMessage: false }; //there are 3 type: success, uploading, error
    const [status, setStatus] = useState( defaultStatus )
    const [totalSize, setTotalSize] = useState( 0 );
    const uploadLimitSize = 10000000; // measure​ in Byte, 10MB

    const convertToMB = ( sizeInByte = 0 ) => {
      if ( typeof sizeInByte === 'number' ) {
        return roundDecimal({ value: sizeInByte/1000000 });
      }
      return sizeInByte;
    };
  

    const onDrop = async (acceptedFiles) => {
      setStatus( defaultStatus );
        if (acceptedFiles.length > 0) {
            // get file that haven't been added before
            let newFile = acceptedFiles.filter(file => {
            if (files.length > 0) {
              return !files.find(image =>{
                // remove element that we add to file in 'fileWithContent' so we can compare file properly
                const { content, displaySize, ...imageObj } = image.file; 
                return(JSON.stringify(imageObj) === JSON.stringify(file))});
            }
            return true;
          });

          newFile.forEach(file => {
            const reader = new FileReader();
            reader.onload = (event) => {
                // get content as base64 
                const fileWithContent = Object.assign(file, {
                  // if you add more element here don't forget to remove it in the code above when compare file  
                  content: reader.result,
                  displaySize: `${convertToMB( file.size )} MB`
                });

                // add content to files array
                const updatedFiles = [ ...files,  {
                  file: fileWithContent,
                  preview: URL.createObjectURL(file),
                } ];

                //  calculate total files size than assign it to useState
                setTotalSize( updatedFiles.reduce( ( pre, current ) => {
                  if ( typeof pre === 'object' ) {
                    return pre?.file?.size + current?.file?.size
                  }
                  return pre + current?.file?.size
                }, 0 ) )

                // update files array
                setFiles( updatedFiles );
             };
            reader.readAsDataURL(file);
          });
        }};

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop,
      accept: "image/*, application/pdf", // accept all image and pdf file
    });

  // clear files
  const reset = ( ) => {
    // Revoke all preview
    files.forEach( item => URL.revokeObjectURL(item.preview) )
    setFiles([]);
    setTotalSize( 0 );
  }

  const handleSubmit = async ( ) => {
    try {
        setStatus( { type: 'uploading', message: 'Uploading...', renderMessage: true } )
        const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

        // check to see if have billEmail
        if( !(companyInfo
            && companyInfo?.company
            && companyInfo?.company?.billEmail 
            && companyInfo?.company?.billEmail.length > 0)
        ){
            // if true show error
            setStatus( pre => ({
              ...pre,
              type: 'error',
              message: 'Don\'t have bill email. Please update in setting at Company Information',
            }) )
            return;
        }

         // check to see if have more than 5 files
        if ( files.length > 5 ) { 
           // if true show error
           setStatus( pre => ({
             ...pre,
             type: 'error',
             message: 'Can\'t Upload more than 5 files',
          }) )
          return;
        }

        // check to see if exceed limited size
        if ( totalSize > uploadLimitSize ) { 
          // if true show error
          setStatus( pre => ({
            ...pre,
            type: 'error',
            message: `(${convertToMB( totalSize )} MB) Exceed limited (${convertToMB( uploadLimitSize )} MB) size can't Upload`,
          }) )
          return;
        }
        
        const submitData = {
            toEmail: companyInfo.company.billEmail,
            files: files.map( item => ({
              size: item.file.size,
              type: item.file.type,
              ...item.file,
            })),
        }
       await axios.patch( `${process.env.REACT_APP_SERVER_URI}/invoices/send-email-to-bills-email-from-xero`, submitData, { withCredentials: true } );
       reset()
       setStatus( pre => ({ ...pre, type: 'success', message: 'Upload successfully' }) )
    } catch ( err ) {
      console.error( err );
      if ( err?.response?.statusText === "Payload Too Large" ) {
        setStatus( pre => ({
          ...pre,
          type: 'error',
          message: 'Files is too large to upload',
        }) )
        return;
      }
      setStatus( pre => ({
        ...pre,
        type: 'error',
        message: 'Something when wrong',
      }) )
    }
  }

  const handleClose = () => {
    reset()
    setStatus( defaultStatus )
  };

  const handleDeleteFile= index => {
    const filter = [...files]; // copy files array
    const deletedItem = filter.splice(index, 1);
    setTotalSize( totalSize - deletedItem[0]?.file?.size )
    setFiles( filter );
    setStatus( defaultStatus );
  };

  return (
    <Container>
        <DialogTitle id="form-dialog-title">Upload Bills To Xero</DialogTitle>
        <DialogContent>
            <div {...getRootProps()} className={classes.dropZoneContainer}>
               <input {...getInputProps()} />
               <Box textAlign="center">
                {
                  isDragActive ?
                    <DialogContentText className={classes.dialogContentText}>Drop the files here ...</DialogContentText> :
                    <DialogContentText className={classes.dialogContentText}>Drag and drop image or PDF files here, or click to select files</DialogContentText>
                }
                    <CloudUploadIcon fontSize="large" style={{color: 'grey', fontSize: '3rem'}}/>
               </Box>
             </div>
        {files.length > 0 && <DialogContentText style={{margin: "16px 0 0 0"}}>Perview :</DialogContentText>}
        <FilesViewer
          files={files}
          handleDelete={( props, index ) => handleDeleteFile( index )}
        />
        
        </DialogContent>
        <DialogActions>
            {status.renderMessage &&
              <Typography color={ status.type === 'error' ? 'error': 'primary' }>
                { status.message }
              </Typography>
            }
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={files.length === 0} onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
    </Container>
  );
}