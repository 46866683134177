import { useForm, Controller } from 'react-hook-form';
import { Grid, Button, Input } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { usePopup } from '../../components/context/popup-context';
import { defaultExchangeRate } from '../../data/default-value';

// const useStyles = makeStyles({
//   root: {
//   },
// });

const GlobalSetting = ( {exchangeRate} ) => {
  // const classes = useStyles();
  const { control, handleSubmit, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { showSpinner, hideSpinner } = usePopup();

  // reset value back in feild
  useEffect(() => {
     reset({ exchangeRate: Number(exchangeRate) });
   }, [exchangeRate]);


  // handle post exchangeRate to sever when click button 'save'
  const handleSaveExchangeRate = async ( data ) => {
    try {
      showSpinner();
      const exchangeRate = Number(data.exchangeRate) || defaultExchangeRate;
      await axios.put( 
        `${process.env.REACT_APP_SERVER_URI}/users/exchange-rate`, { exchangeRate }, { withCredentials: true }
      )
      localStorage.setItem('exchangeRate', JSON.stringify(exchangeRate));
      enqueueSnackbar('Save Completed', { variant: 'success' });
    } catch (err) {
      console.error('Failed to update Exchange Rate', err);
      enqueueSnackbar('Failed to update Exchange Rate', {
        variant: 'error',
      });
    } finally {
      hideSpinner();
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSaveExchangeRate)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
        <Controller
          control={control}
          name="exchangeRate"
          render={({ onChange, name, value }) => (
            <NumberFormat
              customInput={Input}
              name={name}
              value={value}
              onValueChange={ ({ value }) => onChange( value ) }
              thousandSeparator={true}
              suffix={" ៛"}
              placeholder="Exchange Rate (៛)"
              style={{ width: "100%" }}
            />
          )}
        />
        </Grid>
        <Grid item xs={12} container justify='flex-end'>
          <Button variant='contained' color='primary' type='submit'>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GlobalSetting;
