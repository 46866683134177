import imageCompression from 'browser-image-compression';

const defaultImageOption = {
	maxSizeMB: 1,
	maxWidthOrHeight: 1200,
	useWebWorker: true,
};

const defaultThumbnailOption = {
	maxSizeMB: 0.2,
	maxWidthOrHeight: 500,
	useWebWorker: true,
};

/**
 * document link: https://www.npmjs.com/package/browser-image-compression
 */

/**
 * compress image to reduce file size
 */
const imageCompressor = async ({ file, thumbnail = false, imageOption = {}, thumbnailOption = {} }) => {
	const compressedImage = await imageCompression(file, { ...defaultImageOption, ...imageOption });
	if (!thumbnail) {
		return compressedImage;
	} else {
		// compress the same image that we pass and return an addition compressed thumbnail image beside the normal compressed image
		const compressedThumbnail = await imageCompression(file, {
			...defaultThumbnailOption,
			...thumbnailOption,
		});
		return { compressedImage, compressedThumbnail };
	}
};

export { imageCompressor };
