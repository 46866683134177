import { forwardRef } from "react";
import { makeStyles, Box, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

import ManualJournalListing from "./manual-journal-listing";
import SignatureSection from "./signature-section";
import Footer from "./footer";
import commaNumber from "comma-number";

const useStyles = makeStyles({
  pdfWrapper: {

  },
  container: {
    margin: "0 auto",
    fontSize: 11,
    position: "relative",
  },
  header: {
    textAlign: "center",
    // position: "fixed",
    top: 0,
    width: "100%",
    // marginBottom: "300px",
  },
  logo: {
    width: 110,
    position: "absolute",
    left: 0,
  },
  body: {
    // marginTop: "200px",
  },
  pagebreak: {
    pageBreakBefore: "always",
  },
  headerWrpper: {
    width: "65%",
    display: "inline-block",
  },
});

// max-height: 976px

const ManualJournalTemplate = ({ data, manualJournalType, contentHeights, footerHeight, exchangeRate, signatureHeight, printScale = false, image=null }, ref) => {
  const classes = useStyles();
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const { company } = JSON.parse(localStorage.getItem("companyInfo"));

  // const getQuoteNo = (quoteNo) => {
  //   const [, invoiceNumber] = quoteNo.split("-");

  //   if (manualJournalType === "commercial" && company?.commercialInvoicePrefix) {
  //     return `${company?.commercialInvoicePrefix}-${invoiceNumber}`;
  //   } else if (manualJournalType === "tax" && company?.taxInvoicePrefix) {
  //     return `${company?.taxInvoicePrefix}-${invoiceNumber}`;
  //   }

  //   return quoteNo;
  // };

  let footerPadding = [], signaturePadding = [], allHeight, pageHeight = 976;
  contentHeights && contentHeights.forEach((height, idx) => {
    allHeight = height + signatureHeight + footerHeight;
    if (allHeight > pageHeight) {
      footerPadding[idx] = signaturePadding[idx] = 0;
      const exceedPage = allHeight = height % pageHeight + signatureHeight + footerHeight - pageHeight;
      if (exceedPage > 0) {
        if (exceedPage <= footerHeight) {
          footerPadding[idx] = footerHeight - exceedPage;
        }
        else signaturePadding[idx] = footerHeight + signatureHeight - exceedPage;
      }
    }
  });

  return (
    <Box className={classes.pdfWrapper}>
      <div ref={ref} className={classes.testing}>
        {data.map((manualJournal, key) => {
          return (
            <div
              key={key}
              className={clsx([classes.container, classes.pagebreak])}
            >
              <div>
                <Box className={classes.header}>
                  {image ? (
                    <img className={classes.logo}
                      src={image}
                      alt=""
                    />
                  ) : (
                    tenant.logo.length > 0 && (
                      <img className={classes.logo}
                        src={tenant.logo}
                        alt=""
                      />
                    )
                  )}
                  <Box className={classes.headerWrpper}>
                    <Typography variant="h1">{company?.name}</Typography>
                    <Typography style={{ textTransform: "uppercase" }}>
                      {tenant.tenantName}
                    </Typography>
                    <Typography variant="h3">
                      លេខអត្តសញ្ញាណកម្មសារពើពន្ធ {manualJournal.taxId}
                    </Typography>
                    <Typography variant="h5">{company?.address}</Typography>
                    <Typography variant="h5">
                      {tenant.addresses[0]?.addressLine1}
                    </Typography>
                    <Typography variant="h5">
                      លេខទូរសព្ទ / Telephone No: (
                      {tenant.phones[0]?.phoneAreaCode ||
                        tenant.phones[0]?.phoneCountryCode}
                      ){" "}
                      {tenant.phones[0]?.phoneNumber.charAt(0) === "0"
                        ? tenant.phones[0]?.phoneNumber.substring(1)
                        : tenant.phones[0]?.phoneNumber}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box style={{ textAlign: "center", margin: 15 }}>
                    <Typography variant="h1">
                      ទិនានុប្បវត្តិ
                    </Typography>
                    <Typography variant="h1">
                      MANUAL JOURNAL
                    </Typography>
                  </Box>
                </Box>
                <Grid container>
                  {/* customer info */}
                  <Grid className={classes.body} container item xs={12} alignContent="flex-start" style={{ lineHeight: 1.5 }}>
                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        និទានកថា/Narration៖
                      </Grid>
                      <Grid item xs={8}>
                        {manualJournal.narration}
                      </Grid>
                    </Grid>
                    {/* <Grid container item xs={12}>
                      <Grid item xs={4}>
                        Company/Customer៖
                      </Grid>
                      <Grid item xs={8}>
                      </Grid>
                    </Grid> */}

                    <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={4}>
                        កាលបរិច្ឆេទ/Date៖
                      </Grid>
                      <Grid item xs={8}>
                        {moment(manualJournal.data).isValid() && moment(manualJournal.data).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid>
                    {/* <Grid item container xs={12} justify='flex-end'>
                      <Grid item xs={4}>
                        Date៖
                      </Grid>
                      <Grid item xs={8} />
                    </Grid> */}
                    <Grid item container xs={12} justify='flex-end'>
                      <Grid item xs={4}>
                        អត្រាប្តូរប្រាក់/Exchange Rate៖
                      </Grid>
                      <Grid item xs={8}>
                        {commaNumber(exchangeRate)} ៛
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Invoice Section */}
                  {/* <Grid container item xs={4} justify="flex-end" alignContent="flex-start" style={{ lineHeight: 1.5 }}>
               
                  </Grid> */}
                </Grid>
                <ManualJournalListing
                  manualJournal={manualJournal}
                  exchangeRate={exchangeRate}
                  manualJournalType={manualJournalType}
                />
              </div>
              <div
                style={{
                  paddingTop: `${!printScale && signatureHeight !== 0 && signaturePadding[key]}px`
                }}
              >
                <SignatureSection company={company} />
              </div>
              {/* <div
                style={{
                  paddingTop: `${!printScale && footerHeight !== 0 && footerPadding[key]}px`
                }}
              >
                <Footer />
              </div> */}
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default forwardRef(ManualJournalTemplate);
