import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';

import { imageCompressor } from '../../lib/image-compressor';

/**
 * ===== USAGE =====
 * we need state to hold the image
 * => const [state, setState] = useState({ file: null, remove: null })
 * <SingleImageInput
 *  onChange={setState}
 *  defaultPreview={imageURL} // display image when first render
 *  compressImageOption={{ }}
 * />
 * for compressImageOption props check in imageCompressor function (path:/src/lib/image-compressor.js)
 */
const placeholderImage = '/no-image.png';

const SingleImageInput = ({
	onChange, // handle set file state (react useState)
	defaultPreview = null,
	compressImageOption = {},
	disabled = false,
}) => {
	const { enqueueSnackbar } = useSnackbar();

	const [preview, setPreview] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const onDrop = useCallback(
		async acceptedFiles => {
			try {
				setIsLoading(true);
				const file = acceptedFiles[0];

				const compressedFile = await imageCompressor({ file, imageOption: { ...compressImageOption } });

				onChange(prev => {
					let fileToRemove = prev.remove;
					if (prev.file?.key) {
						// if the existing file is from backend pass to fileToRemove to remove when input new image
						fileToRemove = prev.file;
					}
					return { file: compressedFile, remove: fileToRemove };
				});
				const previewURL = URL.createObjectURL(compressedFile);
				setPreview(previewURL);
			} catch (error) {
				console.error('error drp image :>> ', error);
			} finally {
				setIsLoading(false);
			}
		},
		[onChange]
	);

	const handleRemoveImage = () => {
		setPreview(null);
		onChange(prev => {
			let fileToRemove = prev.remove;
			if (!prev.remove) {
				if (prev.file.hasOwnProperty('key')) {
					fileToRemove = prev.file;
				}
			}

			return { file: null, remove: fileToRemove };
		});
	};

	const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
		onDrop,
		accept: ['.jpeg', '.png', '.jpg'],
		multiple: false,
		noClick: true, // disable onClick on drop zone
		disabled,
	});

	useEffect(() => {
		setPreview(defaultPreview);
	}, [defaultPreview]);

	useEffect(() => {
		fileRejections.length && enqueueSnackbar('Invalid file', { variant: 'error' });
	}, [fileRejections]);

	return (
		<>
			<Box
				{...getRootProps()}
				border={fileRejections.length ? '2px dashed #ff1c1c' : '2px dashed #b8b8b8'}
				p={2}
				display='flex'
				justifyContent='center'
				flexDirection='column'
				alignItems='center'
			>
				<input {...getInputProps()} />

				{/* image preview */}
				<Box mb={1} bgcolor='grey'>
					<Box margin='auto' position='relative' width='300px'>
						{preview && (
							<IconButton
								size='small'
								onClick={handleRemoveImage}
								style={{ position: 'absolute', right: 0, color: 'red' }}
							>
								<CancelIcon />
							</IconButton>
						)}
						<img
							src={preview || placeholderImage}
							alt='preview-image'
							style={{ objectFit: 'contain', width: '300px', height: '200px' }}
						/>
					</Box>
				</Box>

				<Typography variant='body2' align='center' paragraph>
					Drag 'n' drop some files here, or click button
				</Typography>
				<Button
					onClick={open}
					variant='contained'
					size='small'
					color='primary'
					disabled={isLoading || disabled}
				>
					Brows file
				</Button>
			</Box>
		</>
	);
};

export default SingleImageInput;
