import { Grid, makeStyles } from "@material-ui/core";
import { defaultStyles, englishFontFamily } from '../config';

const useStyles = makeStyles((theme)=>({
  container: {
    lineHeight: defaultStyles.lineHeight,
    pagebreakInside: "avoid",
    fontFamily: englishFontFamily
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const terms = JSON.parse(localStorage.getItem("terms"));
  return (
    <Grid container className={classes.container}>
      {terms &&
        <Grid container item>
          <Grid item xs={12} style={{ fontSize: defaultStyles.fontSize + 1, marginBottom: 3 }}>
            <b>TERMS & CONDITIONS:</b>
          </Grid>
          <Grid item style={{ whiteSpace: "pre-line" }}>
            {terms}
          </Grid>
        </Grid>
      }
    </Grid>
  );
};

export default TermsAndConditions;
