import setOrganization from './setOrganzationData.js';
import roundDecimal from './roundDecimal ';
import handleCalculateUnitPrice from './calculate-unit-price.js';
import handleCalculateAmount from './calculate-amount.js';
import getDefaultFilterBetweenDate from './default-filter-between-date.js';
import generateLetterFromName from './generate-letter-from-name.js';
import convertPrice, { getCurrency } from './convertPrice.js';
import isNumeric from './isNumeric.js';
import formatDate from './formatDate.js';

export {
  setOrganization,
  roundDecimal,
  handleCalculateUnitPrice,
  handleCalculateAmount,
  getDefaultFilterBetweenDate,
  generateLetterFromName,
  convertPrice,
  getCurrency,
  isNumeric,
  formatDate,
};

export const removeAllSpaceFromString = ( value = '' ) => {
  if ( typeof value === 'string' ) {
    return value.replace(/\s\s+/g, '');
  }
  return '';
}
