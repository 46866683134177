import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { roundDecimal, handleCalculateUnitPrice, handleCalculateAmount } from '../../../utility';
import useMeasure from '../../../../components/custom-hook/use-measure';

const useStyles = makeStyles({
  table: {
    margin: '1em 0',
    width: '100%',
    fontSize: 11,
    lineHeight: 1.3,
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        // border: '1px solid black',
        padding: '0px 0.5em',
      },
    },
  },
  textRight: {
    textAlign: 'right',
    fontWeight: 'bold',
    border: '1px solid black',
  },
  noBorder: {
    border: '1px solid white',
    borderRight: '1px solid black',
  },
  tableHead: {
    '& tr': {
      border: '1px solid black',
      padding: '0px',
      '& th': {
        border: '1px solid black',
        padding: '1em 0.5em',
      },
    },
  },
  tableBodyContentList: {
    '& tr': {
      borderWidth: '0px 1px 1px 1px',
      borderColor: 'black',
      borderStyle: 'solid',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 0px 1px',
      },
    },
  },
  tableBodyEmptySpace: {
    '& tr:first-child': {
      // borderColor: 'black',
      // borderStyle: 'solid',
      // borderWidth: '0px 1px 1px 1px',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
      },
    },
  },
});

const QuoteListing = ({ quote, exchangeRate, quoteType, currencyType }) => {
  const classes = useStyles();

  const { ref: tableBodyRef, size } = useMeasure(quote.invoiceID);
  const minHeight = 170;
  let totalQuantity = 0;

  const additionalHeight = minHeight - (size.height || 0);

  const convertKhmerRiel = amount => roundDecimal({ value: (amount * exchangeRate) })?.toLocaleString();

  const renderInvoiceItems = amountTypes =>
    quote.lineItems.map((item, key) => {
      // const unitPrice = roundDecimal({
      //   value: handleCalculateUnitPrice({
      //     amountTypes,
      //     invoiceType: quoteType,
      //     lineAmount: item.lineAmount,
      //     taxAmount: item.taxAmount,
      //     quantity: item.quantity,
      //   }),
      // });
      // const amount = roundDecimal({
      //   value: handleCalculateAmount({
      //     amountTypes,
      //     invoiceType: quoteType,
      //     lineAmount: item.lineAmount,
      //     taxAmount: item.taxAmount,
      //   }),
      // })
      totalQuantity += Number(item?.quantity || 0)
      return (
        <tr key={key}>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {key + 1}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.itemCode}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.description}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
           {/* {quote.contact?.name ?? ''} */}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.quantity}
          </td>
        </tr>
      );
    });

  return (
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th>
            លេខកូដ
            <br />
            Item Code.
          </th>
          <th>
            បរិយាយ
            <br />
            Description
          </th>
          <th style={{ width: '20%' }}>
            អ្នកទទួល
            <br />
            Receiver
          </th>
          <th>
            បរិមាណ
            <br />
            Quantity
          </th>
          {/* <th>
            តម្លៃឯកតា
            <br />
            Unit Price
          </th> */}
          {/* {quote.currencyCode === 'USD' ? (
            <th>
              ចំនួន (ដុល្លា)
              <br />
              Amount (USD)
            </th>
          ) : (
            <th>
              ចំនួន (រៀល)
              <br />
              Amount (KHR)
            </th>
          )} */}
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderInvoiceItems(quote?.lineAmountTypes)}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        {/* <tr style={{ height: additionalHeight > 0 ? additionalHeight : 0 }}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr> */}
        {quoteType === 'tax' && (
          <tr>
            <td
              colSpan='4'
              className={clsx([classes.textRight, classes.noBorder])}
              style={{ borderTop: '1px solid black' }}
            >
              សរុប/Sub Total
            </td>
            <td className={classes.textRight}>
              {quote.currencyCode === 'USD'
                ? quote.subTotal?.toLocaleString()
                : roundDecimal({ value: quote.subTotal })?.toLocaleString()}
              {currencyType}
            </td>
          </tr>
        )}
        {quoteType === 'tax' && (
          <tr>
            <td colSpan='4' className={clsx([classes.textRight, classes.noBorder])}>
              អាករលើតម្លៃបន្ថែម/VAT (10%)
            </td>
            <td className={classes.textRight}>
              {quote.currencyCode === 'USD'
                ? quote.totalTax?.toLocaleString()
                : roundDecimal({ value: quote.totalTax })?.toLocaleString()}
              {currencyType}
            </td>
          </tr>
        )}
        <tr>
          <td 
            colSpan='4' 
            className={clsx([classes.textRight, classes.noBorder])} 
            style={{ paddingTop: 5, borderWidth: '0px 1px 0px 0px', borderColor: 'black', borderStyle: 'solid' }}
          >   
            បរិមាណសរុបរួម/Total Quantity 
          </td>
          <td 
            className={classes.textRight}
            style={{borderWidth: '0px 1px 1px 0px', borderColor: 'black', borderStyle: 'solid' }}
          >
            {Number((totalQuantity).toFixed(10))}
            
          </td>
        </tr>
        {/* <tr>
          <td colSpan='3' className={clsx([classes.textRight, classes.noBorder])}>
            អត្រាប្តូរប្រាក់/Exchange Rate
          </td>
          <td className={classes.textRight}>{Number(exchangeRate)?.toLocaleString()}៛</td>
        </tr>
        <tr>
          <td colSpan='3' className={clsx([classes.textRight, classes.noBorder])}>
            ចំនួនរៀល/Amount (KHR)
          </td>
          <td className={classes.textRight}>
            {quote.currencyCode === 'USD'
              ? convertKhmerRiel(quote.total)
              : roundDecimal({ value: quote.total })?.toLocaleString()}
            ៛
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default QuoteListing;
