import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { convertPrice } from "../../../utility";

const useStyles = makeStyles({
  table: {
    margin: "1em 0",
    width: "100%",
    fontSize: 11,
    lineHeight: 1.3,
    pageBreakInside: "auto",
    borderCollapse: "collapse",
    "& tr": {
      border: "1px solid grey",
      padding: "0px",
      "& th": {
        border: "1px solid grey",
        padding: "0px 0.5em",
      },
      "& td": {
        border: "1px solid grey",
        padding: "0px 0.5em",
        "&$textRight": {
          textAlign: "right",
          fontWeight: "bold",
        },
        "&$noBorder": {
          border: "1px solid white",
          borderRight: "1px solid grey",
        },
      },
    },
  },
  textRight: {},
  noBorder: {},
  trStyle: {
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
  },
});

const VoucherListing = ({ invoice, accountName, tax, tracking }) => {
  const classes = useStyles();

  const renderInvoiceItems = (type) => {
    return invoice.lineItems.map((item, key) => (
      <tr key={key} className={classes.trStyle}>
        {/* No */}
        <td
          style={{
            borderBottom: `1px solid ${
              key === invoice.lineItems.length - 1 ? "black" : "white"
            }`,
            textAlign: "center",
            verticalAlign: "top",
          }}
        >
          {key + 1}
        </td>
        {/* Description */}
        <td
          style={{
            borderBottom: `1px solid ${
              key === invoice.lineItems.length - 1 ? "black" : "white"
            }`,
            // textAlign: "center",
            verticalAlign: "top",
          }}
        >
          {item.itemCode
            ? `${item.itemCode}: ${item.description}`
            : item.description}
        </td>
        {/* Account */}
        <td
          style={{
            borderBottom: `1px solid ${
              key === invoice.lineItems.length - 1 ? "black" : "white"
            }`,
            textAlign: "center",
            verticalAlign: "top",
          }}
        >
          {`${item.accountCode ? item.accountCode : ""}-${
            accountName[key] ? accountName[key] : ""
          }`}
        </td>
        {/* Quantity */}
        <td
          style={{
            borderBottom: `1px solid ${
              key === invoice.lineItems.length - 1 ? "black" : "white"
            }`,
            textAlign: "center",
            verticalAlign: "top",
          }}
        >
          {item.quantity}
        </td>
        {/* Unit Price */}
        <td
          style={{
            borderBottom: `1px solid ${
              key === invoice.lineItems.length - 1 ? "black" : "white"
            }`,
            textAlign: "right",
            verticalAlign: "top",
          }}
        >
          {convertPrice({
            price: item.unitAmount,
            currencyCode: invoice.currencyCode,
            convert: false,
          })}
        </td>
        {/* Tax */}
        <td
          style={{
            borderBottom: `1px solid ${
              key === invoice.lineItems.length - 1 ? "black" : "white"
            }`,
            textAlign: "center",
            verticalAlign: "top",
            // whiteSpace: "nowrap"
          }}
        >
          {`${tax[key]?.name ? tax[key]?.name : ""} ${
            !isNaN(tax[key]?.displayTaxRate)
              ? `(${tax[key]?.displayTaxRate}%)`
              : ""
          }`}
        </td>
        {/* Tracking */}
        {tracking.map((value, index) => (
          <td
            key={index}
            style={{
              borderBottom: `1px solid ${
                key === invoice.lineItems.length - 1 ? "black" : "white"
              }`,
              textAlign: "center",
              verticalAlign: "top",
            }}
          >
            {Array.isArray(item.tracking)
              ? item.tracking.map((data) => {
                  if (data.name === value) return data.option;
                  return "";
                })
              : ""}
          </td>
        ))}
        {/* Total */}
        <td
          style={{
            borderBottom: `1px solid ${
              key === invoice.lineItems.length - 1 ? "black" : "white"
            }`,
            textAlign: "right",
            verticalAlign: "top",
          }}
        >
          {convertPrice({
            price:
              type === "Inclusive"
                ? item.lineAmount
                : item.lineAmount + item.taxAmount,
            currencyCode: invoice.currencyCode,
            convert: false,
          })}
        </td>
      </tr>
    ));
  };

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th style={{ width: 20 }}>No.</th>
          <th style={{ width: "50%" }}>Description </th>
          <th style={{ width: "20%" }}>Account</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          <th style={{ width: "20%" }}>Tax</th>
          {tracking.map((item, key) => (
            <th key={key} style={{ width: "20%" }}>
              {item}
            </th>
          ))}

          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {/* {test()} */}
        {renderInvoiceItems(invoice.lineAmountTypes)}

        <tr style={{ border: "none" }} className={classes.trStyle}>
          <td
            colSpan={tracking.length > 0 ? 5 + tracking.length : "5"}
            className={clsx([classes.textRight])}
            style={{ border: "none" }}
          ></td>
          <td
            className={clsx([classes.textRight])}
            style={{ borderRight: "none", textAlign: "center" }}
          >
            Total Payment:
          </td>
          <td style={{ borderLeft: "none", textAlign: "center" }}>
            {convertPrice({
              price: invoice.total,
              currencyCode: invoice.currencyCode,
              convert: false,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default VoucherListing;
