import React from 'react';
import {
 makeStyles, CircularProgress, Backdrop,
} from '@material-ui/core';

const useStyles = makeStyles( ( theme ) => ( {
    backdrop: {
      zIndex: theme.zIndex.drawer + 9999,
      color: '#fff',
    },
} ) );

const BackDropSpinner = ( {
 open = false,
} ) => {
    const classes = useStyles( );
    return (
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
};

export default BackDropSpinner;
