import {
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
} from '@material-ui/core'
import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(( theme ) => ({
  checkBoxStyle: {

  }   
}));


const CustomTableHead = ({
  disableCheckBox,
  schema,
  handleSort,
  sort,
  checkboxProps = {},
  tableHeadClassName = "",
}) => {
  const classes = useStyles();
  return (
    <TableHead className={tableHeadClassName}>
      { schema.map( ( schemaItem, schemaIndex ) => {
        const rowSpan = schema.length - schemaIndex;
        return (
          <TableRow key={schemaIndex}>
            { schemaIndex === 0 && !(disableCheckBox) && (
              <TableCell padding="checkbox" rowSpan={rowSpan} colSpan={1}>
                <Checkbox
                  color="primary"
                  className={classes.checkBoxStyle}
                  {...checkboxProps}
                />
              </TableCell>
            ) }
            { schemaItem.map(( item, index ) => (
              <TableCell key={index} {...item.tableCellProps} rowSpan={item.haveChildren ? 1 : rowSpan} colSpan={item.colSpan || 1}>
                {
                  !(item.sortable) ? (
                    item.label
                  ) : (
                    <TableSortLabel
                      // IconComponent={ExpandMoreIcon}
                      active={item.field in sort}
                      direction={item.field in sort ? sort[item.field] : 'asc'}
                      onClick={(event) => handleSort(item.field)}
                    >
                      {item.label}
                    </TableSortLabel>
                  )
                }
              </TableCell>
            ))}
          </TableRow>
        );
      } ) }
    </TableHead>
  );
};

export default CustomTableHead;
