import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Box,
  Zoom,
  Fade,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={{ exit: 400 }}/>;
});


const useStyles = makeStyles( ( theme ) => ( {
  dialogTitle: {
    // padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0px`,
    padding: 0,
  },
  dialogContent: {

  },
  dialogPaper: {
    borderRadius: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  button: {
    borderRadius: 0,
    textTransform: 'none',
    padding: 5,
  },
  logout: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: 0,
  },
  paperStyle: ( paperStyle ) => ( {
    maxWidth: paperStyle?.maxWidth || 400,
  } ),
} ) );

const defaultPromptPopupProps = {
  open: false,
  handleClose: () => {}, 
  handleConfirm: () => {}, 
  updateMode: false, 
  Icon: DeleteIcon, 
  title: 'Are you sure ?',
  message: 'This action can\'t not be undone.', 
}

// you can use usePopup() context instead of this component
const PromptPopup = ( {
 open = defaultPromptPopupProps.open,
 handleClose= defaultPromptPopupProps.handleClose,
 handleConfirm= defaultPromptPopupProps.handleConfirm,
 updateMode= defaultPromptPopupProps.updateMode, // will change color to 'primary' if true
 Icon= defaultPromptPopupProps.Icon,
 title= defaultPromptPopupProps.title,
 message= defaultPromptPopupProps.message,
 paperStyle,
} ) => {
  // for handle show transition
  const [transition, setTransition] = useState(true);

  useEffect(()=> {
    // close transition base on open
    setTransition(open)
  },[open])

  const classes = useStyles( paperStyle );
 return (
  <>
     <Dialog
       open={open}
       onClose={handleClose}
       TransitionComponent={Transition}
       classes={{ paper: `${classes.dialogPaper} ${classes.paperStyle}` }}
       fullWidth
     >
       <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
       <DialogContent style={{ textAlign: 'center' }}>
         <Box>
            <Zoom in={transition} timeout={500}>
              <Icon color={updateMode ? "primary" : "error"} style={{ fontSize: '55px' }} />
            </Zoom>
              <Typography>{message}</Typography>
         </Box>
       </DialogContent>
       <DialogActions className={classes.dialogActions}>
         <Button
            variant='contained'
            disabled={!open} // prevent multiple click during fade out
            className={`${classes.logout} ${classes.button}`}
            onClick={() => {
              handleClose();
              setTransition(false);
            }}
         >
            No
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={() => {
              handleConfirm();
              setTransition(false);
            }}
            disabled={!open} // prevent multiple click during fade out
          >
            Yes
          </Button>
       </DialogActions>
     </Dialog>
   </>
);
};

export default PromptPopup;
export { defaultPromptPopupProps };
