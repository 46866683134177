import { Box, Grid, makeStyles } from "@material-ui/core";
import { defaultStyles, englishFontFamily } from '../config';

const useStyles = makeStyles((theme)=>({
  container:{
    lineHeight: defaultStyles.lineHeight,
    fontFamily: englishFontFamily,
  },
  signatureContainer: {
    textAlign: "center",
    justifyContent: "right"
  },
  approvalContainer: {
    textAlign: "right",
    height: 70,
    fontWeight: 'bold'
  },
  clientContainer:{
    textAlign: "right",
  },
  providerContainer:{
    textAlign: "left",
  }
}));

const SignatureSection = ({ company, invoice }) => {
  const classes = useStyles();
  return (
    <Grid container justify="space-evenly" spacing={2} className={classes.container}>
      <Grid xs={1} item></Grid>
      <Grid xs={10} item container>
        <Grid item xs={12} className={classes.approvalContainer}>
          <Box>APPROVAL</Box>
        </Grid>
        <Grid item xs={6} className={classes.providerContainer}>
          <Box>
            <Box>Ms. Rom Linna</Box>
            <Box>Chief Executive Officer</Box>
          </Box>
        </Grid>
        <Grid item xs={6} container className={classes.signatureContainer}>
          <Grid xs="auto" item>
            SIGNATURE
            <br/>
            Company Stamp
          </Grid>
        </Grid>
        <Grid xs={10} item className={classes.clientContainer}>
          <Box>CLIENT FULL NAME: </Box>
          <Box>POSITION:</Box>
          <Box>DATE: </Box>
        </Grid>
        <Grid xs={2} item style={{ textAlign: "left", paddingLeft: 5}}>
          {/* <Box>{invoice?.contact?.name}</Box> */}
        </Grid>
      </Grid>
      <Grid xs={1} item></Grid>
    </Grid>
  );
};

export default SignatureSection;
