import Invoice from './invoice';
import CreateUpdateInvocie from './invoice/create-update-invocie';
import Bills from './bills';
import TenantInfo from './tenant-info';
import TaxReport from './tax-report';
import PurchaseReport from './tax-report/purchase';
import SalaryReport from './tax-report/salary';
import WithholdingReport from './tax-report/withholding';
import ApiKeySetting from './api-key-setting';
import BillToXero from './bills/bill-to-xero';
import Contacts from './contacts';
import CreateUpdateContact from './contacts/create-update-contact';
import Accounts from './accounts';
import CreateUpdateAccount from './accounts/create-update-account';
import VatReports from './reports/vat-reports'
import QuotePage from './quote';
import DepositSlip from './deposit-slip';
import CreditNote from './credit-note';
import ProductsAndServices from './products-and-services'
import ManualJournal from './manual-journal';
import PurchaseOrders from './purchase-orders';
// import Test from './test';

const dashboardRoutes = [
  {
    exact: true,
    path: '/invoices',
    component: Invoice,
  },
  {
    exact: true,
    path: '/invoices/create',
    component: CreateUpdateInvocie,
  },
  {
    exact: true,
    path: '/quotes',
    component: QuotePage,
  },
  {
    exact: true,
    path: '/deposit-slip',
    component: DepositSlip,
  },
  {
    exact: true,
    path: '/credit-note',
    component: CreditNote,
  },
  {
    exact: true,
    path: '/bills/generate-bills',
    component: Bills,
  },
  {
    exact: true,
    path: '/manual-journal',
    component: ManualJournal,
  },
  {
    exact: true,
    path: '/purchase-orders',
    component: PurchaseOrders,
  },
  {
    exact: true,
    path: '/products-and-services',
    component: ProductsAndServices,
  },
  {
    exact: true,
    path: '/bills/bills-to-xero',
    component: BillToXero,
  },
  {
    exact: true,
    path: '/tax-report',
    component: TaxReport,
  },
  {
    exact: true,
    path: '/tax-report/purchase',
    component: PurchaseReport,
  },
  {
    exact: true,
    path: '/tax-report/sale',
    component: SalaryReport,
  },
  // {
  //   exact: true,
  //   path: '/tax-report/withholding',
  //   component: WithholdingReport,
  // },
  {
    exact: true,
    path: '/tax-report/salary',
    component: SalaryReport,
  },
  {
    exact: true,
    path: '/setting/tenant-info',
    component: TenantInfo,
  },
  {
    exact: true,
    path: '/contacts/all',
    component: Contacts,
  },
  {
    exact: true,
    path: '/contacts/create',
    component: CreateUpdateContact,
  },
  {
    exact: true,
    path: '/contacts/create/:id',
    component: CreateUpdateContact,
  },
  {
    exact: true,
    path: '/chart-of-accounts/all',
    component: Accounts,
  },
  {
    exact: true,
    path: '/chart-of-accounts/create',
    component: CreateUpdateAccount,
  },
  {
    exact: true,
    path: '/chart-of-accounts/create/:id',
    component: CreateUpdateAccount,
  },
  {
    exact: true,
    path: '/reports/vat-reports',
    component: VatReports,
  },
  {
    exact: true,
    path: '/setting/api-key',
    component: ApiKeySetting,
  },
  // {
  //   exact: true,
  //   path: '/test',
  //   component: Test,
  // },
];

export default dashboardRoutes;
