import React from 'react';
import {
  Snackbar, Typography, Button, Paper, makeStyles, IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles( ( theme ) => ( {
  paper: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    width: '250px',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: `0 0 0 ${theme.spacing(2)}px`,
    borderRadius: 0,
  },
} ) );

const SelectPopUp = ( {
  handleDelete,
  select,
  buttonText,
  buttonProps,
} ) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={select.length > 0}
      style={{ marginBottom: '20px' }}
    >
      <Paper
        elevation={2}
        className={classes.paper}
      >
        <Typography>
          {`${select.length} selected`}
        </Typography>
        { buttonText ? (
          <Button
            onClick={handleDelete}
            disableElevation
            color="secondary"
            variant="contained"
            style={{ padding: '10px 20px' }}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        ): (
          <IconButton
            aria-label="delete-multiple" 
            onClick={handleDelete}
            {...buttonProps}
          >
            <DeleteIcon color='error'/>
          </IconButton>
        ) }
      </Paper>
    </Snackbar>
  );
};

export default SelectPopUp;
