import {
  Box,
  Button,
  makeStyles,
  TextField,
  Paper,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  logo: {
    marginBottom: 20,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  containter: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "none",
    "-webkit-tap-highlight-color": "transparent",
    zIndex: "1201",
    display: "flex",
    overflow: "hidden",
  },
  textBox: {
    marginBottom: 8,
    marginTop: 8,
    backgroundColor: "white",
  },
  linkStyle: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.logo}>
          <img src="/logo.png" alt="logo" width="350" />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            href={`${process.env.REACT_APP_SERVER_URI}/users/login`}
          >
            <img
              src="/xero_logo.svg"
              width="40px"
              style={{ paddingRight: "10px" }}
              alt="xero_logo"
            />
            Login with Xero
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Login;
