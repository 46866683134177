const defaultStyles = {
  fontSize: 10, // make sure `fontSize` is number otherwise it will error
  lineHeight: 2, // make sure `lineHeight` is number otherwise it will error
  border: {
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1, // make sure `borderWidth` is number otherwise it will error
  },
}

const invoiceListingMinHeight = 150

const khmerFontFamily = "khmer-os-battambang";

const englishFontFamily = "century gothic";

export { defaultStyles, invoiceListingMinHeight, khmerFontFamily, englishFontFamily }
  