import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  convertPrice,
  getCurrency,
  isNumeric,
} from "../../../utility";
import useMeasure from "../../../../components/custom-hook/use-measure";

const useStyles = makeStyles({
  table: {
    margin: "1em 0",
    width: "100%",
    fontSize: 11,
    lineHeight: 1.3,
    borderCollapse: "collapse",
    "& tr": {
      "& td": {
        // border: '1px solid black',
        padding: "0px 0.5em",
      },
    },
  },
  textRight: {
    textAlign: "right",
    fontWeight: "bold",
    border: "1px solid black",
  },
  textCenter: {
    textAlign: "center",
    fontWeight: "bold",
    border: "1px solid black",
  },
  noBorder: {
    border: "1px solid white",
    borderRight: "1px solid black",
  },
  tableHead: {
    "& tr": {
      border: "1px solid black",
      padding: "0px",
      "& th": {
        border: "1px solid black",
        padding: "1em 0.5em",
      },
    },
  },
  tableBodyContentList: {
    "& tr": {
      borderWidth: "0px 1px 0px 1px",
      borderColor: "black",
      borderStyle: "solid",
      "& td": {
        borderColor: "black",
        borderStyle: "solid",
        borderWidth: "0px 1px 0px 1px",
      },
    },
  },
  tableBodyEmptySpace: {
    "& tr:first-child": {
      borderColor: "black",
      borderStyle: "solid",
      borderWidth: "0px 1px 1px 1px",
      "& td": {
        borderColor: "black",
        borderStyle: "solid",
        borderWidth: "0px 1px 1px 1px",
      },
    },
  },
});

const ManualJournalListing = ({
  manualJournal,
  exchangeRate,
}) => {
  const classes = useStyles();

  const { ref: tableBodyRef, size } = useMeasure(manualJournal.manualJournalID);
  const minHeight = 170;

  const additionalHeight = minHeight - (size.height || 0);

  let debitSubTotal = 0;
  let creditSubTotal = 0; // credit is negative number
  let debitTotalTaxAmount = 0;
  let creditTotalTaxAmount = 0;

  const currencyCode = "USD"; // Manual Journal only have USD currencyCode for now
  const manualJournalType = manualJournal.lineAmountTypes ?? '';
  const isNoTax = manualJournalType.toLowerCase() === "notax";

  const colSpanTotalSection = isNoTax ? 3 : 4;

  const handleCalculateLineAmount = ({ taxAmountProps, lineAmountProps }) => {
    if (manualJournalType?.toLowerCase() === "inclusive") {
      return lineAmountProps - taxAmountProps;
    } else {
      return lineAmountProps;
    }
  };

  const renderInvoiceItems = (amountTypes) =>
    manualJournal.journalLines.map((item, key) => {
      let debitAmount = 0;
      let creditAmount = 0;
      const amount = handleCalculateLineAmount({
        lineAmountProps: item.lineAmount,
        taxAmountProps: item.taxAmount,
      });

      if (amount > 0) {
        debitAmount = convertPrice({
          price: amount,
          currencyCode: currencyCode,
          convert: false,
        });
      } else if (amount < 0) {
        creditAmount = convertPrice({
          price: -amount,
          currencyCode: currencyCode,
          convert: false,
        });
      }

      if (isNumeric(item?.taxAmount)) {
        if (item?.taxAmount > 0) {
          debitTotalTaxAmount += item.taxAmount;
        } else {
          creditTotalTaxAmount += item.taxAmount;
        }
      }

      if(isNumeric(item?.lineAmount)) {
        if (item?.lineAmount > 0) {
          debitSubTotal += amount;
        } else {
          creditSubTotal += amount;
        }
      }

      return (
        <tr key={key}>
          <td
            style={
              {
                // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              }
            }
          >
            {key + 1}
          </td>
          {/* Description */}
          <td
            style={
              {
                // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              }
            }
          >
            {item.description}
          </td>
          {/* Account */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: "left",
              verticalAlign: "top",
            }}
          >
            {`${item.accountCode ? `${item.accountCode}-` : ""}${
              item?.account?.name ? item?.account?.name : ""
            }`}
          </td>
          {/* Tax */}
          { !isNoTax && (
            <td
              style={{
                // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
                textAlign: "right",
                verticalAlign: "top",
              }}
            >
              {`${item?.tax?.name ? item?.tax?.name : ""} ${
                !isNaN(item?.tax?.displayTaxRate)
                ? `(${item?.tax?.displayTaxRate}%)`
                : ""
              }`}
          </td>
          )}
          
          {/* amount field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: "right",
              verticalAlign: "top",
            }}
          >
            {debitAmount}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: "right",
              verticalAlign: "top",
            }}
          >
            {creditAmount}
          </td>
        </tr>
      );
    });

  return (
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th style={{ width: "30%" }}>
            បរិយាយ
            <br />
            Description
          </th>
          <th>
            គណនី
            <br />
            Account
          </th>
          { !isNoTax && (
            <th>
              ពន្ធ
              <br />
              Tax
            </th>
          ) }
          {currencyCode === "USD" ? (
            <th>
              ឥណពន្ធ (ដុល្លា)
              <br />
              Debit (USD)
            </th>
          ) : (
            <th>
              ឥណពន្ធ (រៀល)
              <br />
              Debit (KHR)
            </th>
          )}
          {currencyCode === "USD" ? (
            <th>
              ឥណទាន (ដុល្លា)
              <br />
              Credit (USD)
            </th>
          ) : (
            <th>
              ឥណទាន (រៀល)
              <br />
              Credit (KHR)
            </th>
          )}
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderInvoiceItems(manualJournal?.lineAmountTypes)}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        <tr style={{ height: additionalHeight > 0 ? additionalHeight : 0 }}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          { !isNoTax && <td></td> }
        </tr>
        { !isNoTax && (
          <>
           <tr>
             <td
               colSpan={colSpanTotalSection}
               className={clsx([classes.textRight, classes.noBorder])}
               style={{ borderTop: "1px solid black" }}
             >
               សរុប/Sub Total
             </td>
             <td className={classes.textRight}>
               {convertPrice({
                 price: debitSubTotal,
                 currencyCode: currencyCode,
                 convert: false,
               })}
             </td>
             <td className={classes.textRight}>
               {convertPrice({
                 price: -creditSubTotal,
                 currencyCode: currencyCode,
                 convert: false,
               })}
             </td>
            </tr>
            <tr>
              <td
                colSpan={colSpanTotalSection}
                className={clsx([classes.textRight, classes.noBorder])}
              >
                អាករលើតម្លៃបន្ថែម/VAT
              </td>
              <td className={classes.textRight}>
                {convertPrice({
                  price: debitTotalTaxAmount,
                  currencyCode: currencyCode,
                  convert: false,
                })}
              </td>
              <td className={classes.textRight}>
                {convertPrice({
                  price: -creditTotalTaxAmount,
                  currencyCode: currencyCode,
                  convert: false,
                })}
              </td>
            </tr>
          </>
        ) }
        <tr>
          <td
            colSpan={colSpanTotalSection}
            className={clsx([classes.textRight, classes.noBorder])}
            style={{ paddingTop: 5 }}
          >
            សរុបរួម/Grand Total
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: debitSubTotal + debitTotalTaxAmount,
              currencyCode: currencyCode,
              convert: false,
            })}
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: -(creditSubTotal + creditTotalTaxAmount),
              currencyCode: currencyCode,
              convert: false,
            })}
          </td>
        </tr>
        {/* <tr>
          <td
            colSpan={colSpanTotalSection}
            className={clsx([classes.textRight, classes.noBorder])}
          >
            អត្រាប្តូរប្រាក់/Exchange Rate
          </td>
          <td colSpan={2} className={classes.textCenter}>
            {Number(exchangeRate)?.toLocaleString()}៛
          </td>
        </tr> */}
        <tr>
          <td
            colSpan={colSpanTotalSection}
            className={clsx([classes.textRight, classes.noBorder])}
          >
            ចំនួន{`${currencyCode === "USD" ? "រៀល" : "ដុល្លារ"}`}
            /Amount (
            {`${getCurrency({
              currencyTypeOrCode: currencyCode,
              same: true,
              convert: true,
            })}`}
            )
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: debitSubTotal + debitTotalTaxAmount,
              currencyCode: currencyCode,
              exchangeRate,
            })}
          </td>
          <td className={classes.textRight}>
            {convertPrice({
              price: -(creditSubTotal + creditTotalTaxAmount),
              currencyCode: currencyCode,
              exchangeRate,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ManualJournalListing;
