import { makeStyles, TextField, debounce, Box, Typography, Paper, AppBar, Toolbar, ListItem, ListItemText  } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import { Controller } from 'react-hook-form';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useQuery from '../custom-hook/use-query';

const useStyles = makeStyles((theme) => ({
  root: {
    '& textarea': {
      paddingTop: '5px',
    },
  },
  avatarInputIcon: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    width: 24,
    height: 24,
    fontSize: '0.7rem'
  },
  avatarOptionIcon: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    width: 32,
    height: 32,
    fontSize: '0.9rem'
  },
  loading: {
    top: "calc(50% - 14px)",
    right: 0,
    position: "absolute",
  },
  listOption: {
    display: "flex",
    alignItems: "center",
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    minHeight: 50
    // padding: 
  },
  toolbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
    paddingLeft: 0
  },
  paperPopup: {
    paddingBottom: 50.
  },
  itemLoading: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  shrink: {
    transform: "translate(32px, 24px) scale(1)",
    transformOrigin: 'top left',
  },

}));

const AccountAutocomplete = ({
  name, // require
  control, // require, Optional when using FormProvider
  required,
  error: formError = false,
  placeholder = "",
  defaultValue = null,
  createNewButton = false, // not working yet so keep it as false
  disableUnderline = false,
  onAfterChange,
  limit = 100, // limit the item show in list. Note: if we render too many item at the same time it will lag
//   variant = 'standard',
//   size = 'medium',
  label,
//   fullWidth = true,
//   className = '',
  rules,
//   onChange,
//   helperText= null,
//   ...textFieldProps
}) => {
  const { watch } = useFormContext()
  const classes = useStyles();
  const { data, query, loading } = useQuery(`${process.env.REACT_APP_SERVER_URI}/accounts`);
  const [canCreateNew, setCanCreateNew] = useState(false);

  const handleFetchAccountData = async ( searchProps ) => {
    try{
      await query({
        search: searchProps,
        perPage: limit
      });
    } catch ( err ) {
      console.error( err );
    }
  }

  // fetch data once on render
  useEffect( ( ) => {
    handleFetchAccountData();
  }, []);

  // render custom item in list show we can display what we want
  const handleRenderOption = ( option, state ) => {
    return (
        <Box className={classes.listOption}>
            {/* <Avatar className={classes.avatarOptionIcon}>{generateLetterFromName(option.name)}</Avatar> */}
            <Box px={1}>
              <Typography variant='body2' style={{ fontWeight: "bold"}} color="primary">
                {option.code}<span style={{ fontWeight: "normal", color: 'black'}}> - {option.name}</span>
              </Typography>
            </Box>
        </Box>
    );
  }

  const handleCreate = () => {
    console.info('create');
  }

  // render custom paper component so we can display when it is loading and other stuff
  const renderPaperComponent = ( props, test ) => {
    const isCreatable =  !loading && canCreateNew && createNewButton;
    return(
      <>
        <Paper className={isCreatable ? classes.paperPopup : undefined}>
          { loading ? <div className="MuiAutocomplete-loading">Loading…</div> : props.children }
        </Paper> 
        { isCreatable && (
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <ListItem button dense onMouseDown={handleCreate}>
                <ListItemIcon>
                  <AddIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Create as new Account" />
              </ListItem>
            </Toolbar>
          </AppBar>
        ) }
      </>
    );
  }
  
  return (
    <Controller 
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={(props, { invalid }) => {
        return (
          <Autocomplete
            {...props}
            id="account-autocomplete"
            options={data?.docs ?? []}
            getOptionLabel={(option) => option.name}
            groupBy={(option) => option.type}
            renderOption={handleRenderOption}
            getOptionSelected={(option, value) => {
              return option.name === value.name
            }}
            onChange={(_, data) => {
              props.onChange(data)
              if(typeof onAfterChange === 'function') {
                onAfterChange(data)
              }
            }}
            onInputChange={debounce((event, newInputValue, reason ) => {
              const arrName = data?.docs.map( ( value ) => value?.name.toLowerCase() ); 

              // check to see if the create new button should be show or not
              if ( newInputValue !== '' && !arrName.includes( newInputValue.toLowerCase() ) ) {
                  setCanCreateNew(true)
              } else {
                  setCanCreateNew(false)
              }
            
              // when selected a value we don't need to query it
              if (newInputValue !== "" && reason === "reset") {
                return;
              }
              
              // Fetch new data on every input meaning that it filter from backend
              handleFetchAccountData(newInputValue);
            }, 500)}
            PaperComponent={renderPaperComponent}
            renderInput={(params) => {
              return (
                <TextField 
                  {...params}
                  label={label}
                  error={invalid}
                  margin="dense" 
                  placeholder={placeholder}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        loading ? <Box className="MuiAutocomplete-endAdornment"><CircularProgress color="primary" size={20}/></Box> : params.InputProps.endAdornment
                    ),
                    disableUnderline: disableUnderline,
                  }}
                />
              )
            }}
           />
        )
      }}
    />
  );
};

export default AccountAutocomplete;
