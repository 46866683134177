import { Divider, Grid, Box } from '@material-ui/core';

import { RHFTextInput } from '../../components/rhf-controlled-input';

const BankInfo = () => {
	return (
		<Box mt={1}>
			<h2>Bank Information</h2>
			<Divider />
			<br />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<RHFTextInput name='bank.name' label='Bank Name' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='bank.swiftCode' label='Swift Code' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='bank.address' label='Bank Address' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput name='bank.accountName' label='Account Name' />
				</Grid>
				<Grid item xs={12}>
					<RHFTextInput
					  name='bank.accountNumber'
					  label='Account Number' 
					  multiline
						maxRows={5}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default BankInfo;
