import { Typography } from "@material-ui/core";
import { formatDate } from "../utility";

const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem("user") || '""'); // prevent error when don't have "invoiceName" in local storage
  let expirationDate = '' 
  if( user?.expirationDate ) {
    const formatDateString = formatDate({ date: user?.expirationDate, format: 'DD/MMMM/YYYY'})
    if(formatDateString) {
      expirationDate = ` You account is expiring on ${formatDateString}`
    }
  }
  return (
    <Typography component="div" variant="h6" style={{ fontWeight: "normal" }}>
      You are successfully login. {expirationDate}
    </Typography>
  );
};

export default Dashboard;
