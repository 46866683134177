import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, makeStyles } from '@material-ui/core';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { NavLink, useHistory } from 'react-router-dom';

import drawerMenu from './menu';

const useStyles = makeStyles(theme => ({
  selected: {
    background: `${theme.palette.grey['300']}!important`,
  },
  subSelected: {
    background: `${theme.palette.grey['100']}!important`,
  },
}));

const DrawerMenu = () => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    //  find active menu
    for (const [index, item] of drawerMenu.entries()) {
      // in case the menu has no submenu
      if (!item.submenu) {
        if (item.path.includes(history.location.pathname)) {
          setSelectedIndex(index + 1);
          break;
        }
      } else if (item.submenu.length > 0) {
        for (const [subIndex, submenu] of item.submenu.entries()) {
          if (
            submenu.path === history.location.pathname ||
            // match base pathname
            history.location.pathname.includes(`/${submenu.path.split('/').slice(0, 2).join('/')}`)
          ) {
            setSelectedIndex(index + 1 + (subIndex + 1) / 100);
            break;
          }
        }
      }
    }
  }, []);

  return (
    <List>
      {drawerMenu.map((item, index) => (
        <React.Fragment key={index}>
          {!item.submenu || item.submenu.length > 0 ? (
            <ListItem
              key={index}
              disableRipple
              button
              component={NavLink}
              to={!item.submenu ? item.path : item.submenu[0].path}
              onClick={() => (!item.submenu ? setSelectedIndex(index + 1) : setSelectedIndex(index + 1.01))}
              selected={
                !item.submenu
                  ? selectedIndex === index + 1
                  : selectedIndex >= index + 1 && selectedIndex < index + 2
              }
              classes={{ selected: classes.selected }}
            >
              <ListItemIcon style={{ minWidth: 35 }}>{item.Icon && React.createElement(item.Icon)}</ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
              {item.submenu && <IconExpandMore />}
            </ListItem>
          ) : null}
          {item.submenu ? (
            <Collapse in={selectedIndex >= index + 1 && selectedIndex < index + 2}>
              <Divider />
              {item.submenu.map((submenu, subIndex) => (
                <ListItem
                  key={subIndex}
                  button
                  disableRipple
                  component={NavLink}
                  to={submenu.path}
                  onClick={() => {
                    setSelectedIndex(index + 1 + (subIndex + 1) / 100);
                  }}
                  selected={selectedIndex === index + 1 + (subIndex + 1) / 100}
                  classes={{ selected: classes.subSelected }}
                >
                  <ListItemIcon style={{ minWidth: 35 }}></ListItemIcon>
                  <ListItemText>{submenu.name}</ListItemText>
                </ListItem>
              ))}
            </Collapse>
          ) : null}
        </React.Fragment>
      ))}
    </List>
  );
};

export default DrawerMenu;
