import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import useMeasure from '../../../../components/custom-hook/use-measure';

const useStyles = makeStyles({
  table: {
    margin: '1em 0',
    width: '100%',
    fontSize: 11,
    lineHeight: 1.3,
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        padding: '0px 0.5em',
      },
    },
  },
  textRight: {
    textAlign: 'right',
    fontWeight: 'bold',
    border: '1px solid black',
  },
  noBorder: {
    border: '1px solid white',
    borderRight: '1px solid black',
  },
  tableHead:{
    '& tr': {
      border: '1px solid black',
      padding: '0px',
      '& th': {
        border: '1px solid black',
        padding: '1em 0.5em',
      },
    },
  },
  tableBodyContentList: {
    '& tr': {
      borderWidth: '0px 1px 0px 1px',
      borderColor: 'black',
      borderStyle: 'solid',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 0px 1px',
      },
    },
  },
  tableBodyEmptySpace: {
    '& tr:first-child': {
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: '0px 1px 1px 1px',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
      },
    },
  },
});

const DeliveryNoteListing = ({ invoice }) => {
  const classes = useStyles();
  let totalQuantity = 0;
  const { ref: tableBodyRef, size } = useMeasure(invoice.invoiceID);
  const minHeight = 250;
  const additionalHeight = minHeight - (size.height || 0);

  const renderDeliveryNoteItems = () =>
    invoice.lineItems.map((item, key) => {
      totalQuantity += item.quantity;
      return (
        <tr key={key}>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {key + 1}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.description}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.quantity}
          </td>
        </tr>
      );
    });

  return (
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th style={{ width: '80%' }}>
            បរិយាយ
            <br />
            Description
          </th>
          <th>
            បរិមាណ
            <br />
            Quantity
          </th>
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderDeliveryNoteItems(invoice?.lineAmountTypes)}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        <tr style={{height: additionalHeight > 0 ? additionalHeight : 0}}>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colSpan='2' className={clsx([classes.textRight, classes.noBorder])} style={{ paddingTop: 5 }}>
            សរុបរួម/Grand Total
          </td>
          <td className={classes.textRight}>
            {totalQuantity}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default DeliveryNoteListing;
