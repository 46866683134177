import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { useSnackbar } from "notistack";
// import qs from 'qs';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch } from "react-redux";
import { logout } from "../../redux-store/slices/user";

import {
  makeStyles,
  Container,
  Grid,
  Button,
} from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles({
  container: {
    // maxHeight: 500,
  },
  
});

const TaxReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = [
    {
      label: "Purchase",
      url: "/tax-report/purchase",
    },
    {
      label: "Sale",
      url: "/tax-report/sale",
    },
    {
      label: "Withholding",
      url: "/tax-report/withholding",
    },
    {
      label: "Salary",
      url: "/tax-report/salary",
    },
  ];

  return (
    <Container>
        <h1>TAX REPORT</h1>
        <Grid container justify="space-evenly">
            {data.map((item, idx) => {
              return (
                <Grid item xs={3} key={idx}>
                    <Button
                        variant="contained"
                        href={item.url}
                        onClick=""
                    >
                      {item.label}
                    </Button>
                </Grid>
              )
            })}
            
      </Grid>
    </Container>
  );
};

export default TaxReport;
