import { forwardRef } from "react";
import { makeStyles, Box, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

import DepositSlipListing from "./deposit-slip-listing";
import SignatureSection from "./signature-section";
import Footer from "./footer";
import { removeAllSpaceFromString } from "../../../utility";

const useStyles = makeStyles({
  pdfWrapper: {

  },
  container: {
    margin: "0 auto",
    fontSize: 11,
    position: "relative",
  },
  header: {
    textAlign: "center",
    top: 0,
    width: "100%",
  },
  logo: {
    width: 110, 
    position: "absolute", 
    left: 0, 
  },
  body: {
  },
  pagebreak: {
    pageBreakBefore: "always",
  },
  headerWrpper: {
    width: "65%",
    display: "inline-block",
  },
});

// max-height: 976px

const DepositSlipTemplate = ({ data, contentHeights, footerHeight, signatureHeight, printScale=false, image=null }, ref) => {
  const classes = useStyles();
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const { company } = JSON.parse(localStorage.getItem("companyInfo"));
  

  let footerPadding = [], signaturePadding = [], allHeight, pageHeight = 976;
  contentHeights && contentHeights.forEach((height, idx) => {
    allHeight = height + signatureHeight + footerHeight;
    if (allHeight > pageHeight) {
      footerPadding[idx] = signaturePadding[idx] = 0;
      const exceedPage = allHeight = height % pageHeight + signatureHeight + footerHeight - pageHeight;
      if (exceedPage > 0) {
        if (exceedPage <= footerHeight) {
          footerPadding[idx] = footerHeight - exceedPage;
        }
        else signaturePadding[idx] = footerHeight + signatureHeight - exceedPage;
      }
    } 
  })

  return (
    <Box className={classes.pdfWrapper}>
      <div ref={ref} className={classes.testing}>
        {data.map((depositSlip, key) => {
          const streetaddress = depositSlip.contact?.addresses.filter(
            (address) => address.addressType === "STREET"
          )[0];
          const poboxaddress = depositSlip.contact?.addresses.filter(
            (address) => address.addressType === "POBOX"
          )[0];

          const streetaddressString = `${
            streetaddress?.addressLine1
              ? streetaddress.addressLine1
              : ""
          } ${
            streetaddress?.addressLine2
              ? streetaddress.addressLine2
              : ""
          } ${
            streetaddress?.addressLine3
              ? streetaddress.addressLine3
              : ""
          } ${
            streetaddress?.addressLine4
              ? streetaddress.addressLine4
              : ""
          }`;

          const poboxaddressString = `${
            poboxaddress?.addressLine1
              ? poboxaddress?.addressLine1
              : ""
          } ${
            poboxaddress?.addressLine2
              ? poboxaddress?.addressLine2
              : ""
          } ${
            poboxaddress?.addressLine3
              ? poboxaddress?.addressLine3
              : ""
          } ${
            poboxaddress?.addressLine4
              ? poboxaddress?.addressLine4
              : ""
          }`;

          return (
            <div
              key={key}
              className={clsx([classes.container, classes.pagebreak])}
            >
              <div>
                <Box className={classes.header}>
                  {image ? (
                    <img className={classes.logo}
                      src={image}
                      alt=""
                    />
                  ) : (
                    tenant.logo.length > 0 && (
                      <img className={classes.logo}
                        src={tenant.logo}
                        alt=""
                      />
                    )
                  )}
                    <Box className={classes.headerWrpper}>
                      <Typography variant="h1">{company?.name}</Typography>
                      <Typography style={{ textTransform: "uppercase" }}>
                        {tenant.tenantName}
                      </Typography>
                      { depositSlip?.taxId && (
                        <Typography variant="h3">
                          លេខអត្តសញ្ញាណកម្មសារពើពន្ធ {depositSlip.taxId}
                        </Typography>
                      )}
                      <Typography variant="h5">{company?.address}</Typography>
                      <Typography variant="h5">
                        {tenant.addresses[0]?.addressLine1}
                      </Typography>
                      <Typography variant="h5">
                        លេខទូរសព្ទ / Telephone No: (
                        {tenant.phones[0]?.phoneAreaCode ||
                          tenant.phones[0]?.phoneCountryCode}
                        ){" "}
                        {tenant.phones[0]?.phoneNumber.charAt(0) === "0"
                          ? tenant.phones[0]?.phoneNumber.substring(1)
                          : tenant.phones[0]?.phoneNumber}
                      </Typography>
                    </Box>
                  <Divider />
                  <Box style={{ textAlign: "center", margin: 15 }}>
                    <Typography variant="h1">
                      ប័ណ្ណកក់ប្រាក់
                    </Typography>
                    <Typography variant="h1">
                      DEPOSIT SLIP
                    </Typography>
                  </Box>
                </Box>
                <Grid container>            
                  {/* customer info */}
                  <Grid className={classes.body} container item xs={7} alignContent="flex-start" style={{ lineHeight: 1.5 }}>
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        ឈ្មោះក្រុមហ៊ុន ឬ អតិថិជន៖
                      </Grid>
                      <Grid item xs={7}>
                        {depositSlip.contact?.skypeUserName}                        
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        Company/Customer៖
                      </Grid>
                      <Grid item xs={7}>
                        {depositSlip.contact?.name}
                      </Grid>
                    </Grid>
                    { removeAllSpaceFromString(streetaddressString).length > 0 && (
                      <Grid container item xs={12}>
                        <Grid item xs={5}>
                          អាសយដ្ឋាន៖
                        </Grid>
                        <Grid item xs={7}>
                          {streetaddressString}
                        </Grid>
                    </Grid>
                    ) }
                    { removeAllSpaceFromString(poboxaddressString).length > 0 && (
                      <Grid container item xs={12}>
                        <Grid item xs={5}>
                          Address៖
                        </Grid>
                        <Grid item xs={7}>
                          {poboxaddressString}
                        </Grid>
                    </Grid>
                    ) }
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        ទូរសព្ទលេខ/Telephone No៖
                      </Grid>
                      <Grid item xs={7}>
                        {
                          depositSlip.contact?.phones[0]?.phoneNumber
                            ? `${depositSlip.contact?.phones[0]?.phoneCountryCode ? `( ${depositSlip.contact?.phones[0]?.phoneCountryCode} ) `:``}` +
                              depositSlip.contact?.phones[0]?.phoneNumber
                            : "-"
                        }
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        លេខអត្តសញ្ញាណកម្ម អតប/VAT TIN៖                   
                      </Grid>
                      <Grid item xs={7}>
                        {depositSlip.contact?.taxNumber}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Invoice Section */}
                  <Grid container item xs={5} justify="flex-end" alignContent="flex-start" style={{ lineHeight: 1.5, alignContent: 'flex-start' }}>
                    <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={7}>
                        ប័ណ្ណកក់ប្រាក់/Deposit Slip No.៖
                      </Grid>
                      <Grid item xs={5}>
                        <b>{depositSlip.invoiceNumber}</b>
                      </Grid>
                    </Grid>
                    {/* <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={7}>
                        ឯកសារយោង/Reference No.៖
                      </Grid>
                      <Grid item xs={5}>
                        <b>{depositSlip.reference}</b>
                      </Grid>
                    </Grid> */}
                    <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={7}>
                        កាលបរិច្ឆេទ/Date៖
                      </Grid>
                      <Grid item xs={5}>
                        {moment(depositSlip.date).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid>
                    {/* Prepayment don't have due date or expiry date */}
                    {/* <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={7}>
                        កាលកំណត់បង់ប្រាក់/Due Date៖
                      </Grid>
                      <Grid item xs={5}>
                        {moment(depositSlip.dueDate).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
                <DepositSlipListing depositSlip={depositSlip}/>
              </div>
              <div
                style={{
                  paddingTop: `${!printScale && signatureHeight !== 0 && signaturePadding[key]}px`
                }}
              >
                <SignatureSection company={company} />
              </div>
              <div
                style={{
                  paddingTop: `${!printScale && footerHeight !== 0 && footerPadding[key]}px`
                }}
              >
                <Footer />
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default forwardRef(DepositSlipTemplate);
