import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const user = JSON.parse(localStorage.getItem('user'));

// using createAsyncThunk when dealing with fetching api
export const handleLogout = createAsyncThunk('user/logout', async () => {
  await axios.get(`${process.env.REACT_APP_SERVER_URI}/users/revoke`, { withCredentials: true });
  // return payload
  return { isAuthenticated: false, isApproved: false };
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      isAuthenticated: false,
      isApproved: user ? user.isApproved : false,
    },
    loading: false,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = action.payload;
    },
  },
  // use extraReducers to response to createAsyncThunk action
  extraReducers: {
    [handleLogout.pending]: (state, action) => {
      state.loading = true;
    },
    [handleLogout.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [handleLogout.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default userSlice.reducer;

export const { loginSuccess, logoutSuccess } = userSlice.actions;

export const login = () => async dispatch => {
  try {
    /// load organization from DB
    const { isApproved } = JSON.parse(localStorage.getItem('user'));
    return dispatch(loginSuccess({ isAuthenticated: true, isApproved }));
  } catch (err) {
    return console.error('Failed to login', err);
  }
};

export const logout = async () => async dispatch => {
  try {
    await axios.get(`${process.env.REACT_APP_SERVER_URI}/users/revoke`, { withCredentials: true });
    localStorage.clear();
    return dispatch(logoutSuccess({ isAuthenticated: false, isApproved: false }));
  } catch (err) {
    return console.error('Failed to logout', err.response);
  }
};
