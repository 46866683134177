import ManualJournalTemplate from "../printing-page/default-template/manual-journal";

const getManualJournalTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  data,
  printScale,
  exchangeRate,
  manualJournalType,
  image,
}) => {
  return (
    <ManualJournalTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      data={data}
      printScale={printScale}
      exchangeRate={exchangeRate}
      manualJournalType={manualJournalType}
      image={image}
    />
  )
}


export { getManualJournalTemplate }