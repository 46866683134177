import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { roundDecimal, handleCalculateUnitPrice, handleCalculateAmount, convertPrice, getCurrency } from '../../../utility';
import useMeasure from '../../../../components/custom-hook/use-measure';
import { defaultStyles, invoiceListingMinHeight, khmerFontFamily, englishFontFamily } from '../config';

const useStyles = makeStyles((theme) =>
  ({
  table: {
    margin: `10px 0 0 0`,
    width: '100%',
    lineHeight: defaultStyles.lineHeight - 0.5,
    fontFamily: englishFontFamily,
    borderCollapse: 'collapse',
    '& tr': {
      // border: '2px solid black','
      border: 'none',
      padding: '0px',
      '& th': {
        // border: '1px solid black',
        padding: '1em 0.5em',
        border: 'none',
        fontSize: `${defaultStyles.fontSize}px`
      },
      '& td': {
        // border: '1px solid black',
        border: 'none',
        padding: '0.3em 0.5em',
        textAlign: 'center',
        fontSize: `${defaultStyles.fontSize}px`
        // '&$textRight': {
        //   textAlign: 'right',
        //   fontWeight: 'bold',
        // },
        // '&$noBorder': {
        //   border: '1px solid white',
        //   borderRight: '1px solid black',
        // },
      },
    },
  },
  textRight: {},
  noBorder: {},
  tableBorder:{
    ...defaultStyles.border
  },
  tableBodyContentList: {
    ...defaultStyles.border,
    borderWidth: `${defaultStyles.border.borderWidth}px ${defaultStyles.border.borderWidth}px 0px ${defaultStyles.border.borderWidth}px`,
  },
  tableBodyEmptySpace: {
    ...defaultStyles.border,
    borderWidth: `0px ${defaultStyles.border.borderWidth}px`,
    '& tr:first-child': {
      '& td': {
        padding: 0
      },
    },
  },
  tableFooterRow: {
    backgroundColor: 'black',
    fontWeight: 'bold'
  },
  khmerStyle: {
    fontFamily: khmerFontFamily,
  },
  englishStyle: {
    fontFamily: englishFontFamily,
  },
}));

const InvoiceListing = ({
  invoice,
  exchangeRate,
  invoiceType,
}) => {
  const classes = useStyles();
  // const ref = useRef(null);
  const { ref: tableBodyRef, size } = useMeasure(invoice.invoiceID);

  const additionalHeight = invoiceListingMinHeight - (size.height || 0);

  const renderInvoiceItems = amountTypes =>
    invoice.lineItems.map((item, key) => {
      return (
        <tr key={key} style={{ padding: 8 }}>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'left',
            }}
          >
            {item?.item?.name}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.description}
          </td>
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {item.quantity}
          </td>
          {/* unit price field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {convertPrice({
              price: handleCalculateUnitPrice({
                amountTypes,
                invoiceType,
                lineAmount: item.lineAmount,
                taxAmount: item.taxAmount,
                quantity: item.quantity,
              }),
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
          {/* amount field */}
          <td
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              // verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: handleCalculateAmount({
                amountTypes,
                invoiceType,
                lineAmount: item.lineAmount,
                taxAmount: item.taxAmount,
              }),
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
      );
    });

    const tableFooterArray = [
      {
        labelKH: 'តម្លៃមិនទាន់គិតអាករ',
        labelEN: 'Sub Total',
        value: convertPrice({
          price: roundDecimal({ value: invoice.subTotal}),
          currencyCode: invoice.currencyCode,
          convert: false,
          format: ({formattedPrice}) => formattedPrice
        }),
        currencyType: getCurrency({ currencyTypeOrCode: invoice.currencyCode  }),
        hide: !(invoiceType === 'tax')
      },
      {
        labelKH: 'អាករលើតម្លៃបន្ថែម១០%',
        labelEN: 'VAT 10%',
        value: convertPrice({
          price: roundDecimal({ value: invoice.totalTax}),
          currencyCode: invoice.currencyCode,
          convert: false,
          format: ({formattedPrice}) => formattedPrice
        }),
        currencyType: getCurrency({ currencyTypeOrCode: invoice.currencyCode  }),
        hide: !(invoiceType === 'tax')
      },
      {
        labelKH: invoice.currencyCode === 'USD' ? 'សរុបរួមបញ្ចូលអតបជាប្រាក់ដុល្លារ' : 'សរុបរួមបញ្ចូលអតបជាប្រាក់រៀល',
        labelEN: invoice.currencyCode === 'USD' ? 'Grand (Include VAT) USD' : 'Grand (Include VAT) Riel',
        value: convertPrice({
          price: roundDecimal({ value: invoice.total}),
          currencyCode: invoice.currencyCode,
          convert: false,
          format: ({formattedPrice}) => formattedPrice
        }),
        currencyType: getCurrency({ currencyTypeOrCode: invoice.currencyCode  }),
      },
      {
        labelKH: invoice.currencyCode === 'USD' ? 'សរុបរួមបញ្ចូលអតបជាប្រាក់រៀល' : 'សរុបរួមបញ្ចូលអតបជាប្រាក់ដុល្លារ',
        labelEN: invoice.currencyCode === 'USD' ? 'Grand (Include VAT) Riel' : 'Grand (Include VAT) USD',
        value: convertPrice({
          price: roundDecimal({ value: invoice.total}),
          currencyCode: invoice.currencyCode,
          exchangeRate,
          format: ({formattedPrice}) => 
            `${getCurrency({ 
              currencyTypeOrCode: invoice.currencyCode,
              convert: true,
              same: true 
            })} ${formattedPrice}`
        }),
        currencyType: '',
      }
  ]

  return (
    <table className={classes.table}>
      <thead className={classes.tableBorder}>
        <tr>
          <th style={{ width: '15%' }} className={clsx([classes.englishStyle])}>
            <span className={clsx([classes.khmerStyle])}>
             ទំនិញ/
            </span>
            <br />
            ITEMS
          </th>
          <th style={{ width: '35%' }} className={clsx([classes.englishStyle])}>
            <span className={clsx([classes.khmerStyle])}>
              បរិយាយ/
            </span>
            <br />
            DESCRIPTION
          </th>
          <th style={{ width: '10%' }} className={clsx([classes.englishStyle])}>
            <span className={clsx([classes.khmerStyle])}>
              បរិមាណ/
            </span>
            <br />
            QTY
          </th>
          <th style={{ width: '20%' }} className={clsx([classes.englishStyle])}>
            <span className={clsx([classes.khmerStyle])}>
              តម្លៃឯកតា/
            </span>
            <br />
            UNIT PRICE
          </th>
          <th style={{ width: '20%' }} className={clsx([classes.englishStyle])}>
            <span className={clsx([classes.khmerStyle])}>
              សរុប/
            </span>
            <br />
            TOTAL
          </th>
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderInvoiceItems(invoice?.lineAmountTypes)}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        <tr style={{height: additionalHeight > 0 ? additionalHeight : 0}}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        { tableFooterArray.map( (item,index) => {
          return( !(item.hide) &&
            <tr key={index} className={classes.tableFooterRow}>
              <td
                colSpan='4'
                className={clsx([classes.textRight])}
                style={{ color: 'white', textAlign: 'right' }}
              >
                <span className={clsx([classes.khmerStyle])}>
                  {item.labelKH}
                </span>
                <br />
                {item.labelEN}
              </td>
              <td className={classes.textRight} style={{ color: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span> { item.currencyType }</span>
                  <span> 
                    {item.value}
                  </span>
                </div>
              </td>
            </tr>
          );
        } ) }
      </tbody>
    </table>
  );
};

export default InvoiceListing;
