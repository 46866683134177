import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Box, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import axios from 'axios';
import RefreshIcon from '@material-ui/icons/Refresh';
import { handleLogout } from '../../redux-store/slices/user';
import { triggerFilter } from '../../redux-store/slices/filter'
import Sidebar from './drawer';
import OrganizationSelect from './organization-select-box';
import { useHistory } from 'react-router-dom';
import SessionTimeout from '../session-timeout';
import ButtonWithXeroImage from '../button-with-xero-image';
import { usePopup } from '../context/popup-context';
import DateTimeRangePickerPopup from '../date-time-range-picker-pop-up';
import UpdateIcon from '@material-ui/icons/Update';

const drawerWidth = process.env.REACT_APP_DRAWER_WIDTH;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    textAlign: 'end'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  orgBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Navbar = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.userReducer);
  const history = useHistory();
  const [date, setDate] = useState();
  const anchorSyncDateRef = useRef( null );

  const { hideSpinner, showSpinner, showAlertPopup, } = usePopup();

  const [open, setOpen] = useState(false);
  const [openSyncDatePopup, setOpenSyncDatePopup] = useState(false)
  const [dateError,setDateError] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDisconnect = async () => {
    try {
      await dispatch(handleLogout());
      localStorage.clear();
      history.push('/login');
    } catch (error) {
      console.error('error :>> ', error);
    }
  };

  const handleSync = async ({ dateProps }) => {
    try {
      showSpinner();
      const startDateProps = dateProps?.startDate?.getTime();
      const endDateProps = dateProps?.endDate?.getTime();
      let dateQuery = '';
      if(startDateProps && endDateProps){
        dateQuery = `startDate=${startDateProps}&endDate=${endDateProps}`;
      }
      // don't have to sync contacts because when sync invoice contacts will be sync automatically
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/accounts/sync?${dateQuery}`, { withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/items/sync?${dateQuery}`,{ withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/invoices/sync?type=ACCREC&${dateQuery}`, { withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/invoices/sync?type=ACCPAY&${dateQuery}`, { withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/deposit-slip/sync?${dateQuery}`, { withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/credit-note/sync?${dateQuery}`, { withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/quote/sync?${dateQuery}`,{ withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/manual-journal/sync?${dateQuery}`,{ withCredentials: true });
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/purchase-orders/sync?${dateQuery}`,{ withCredentials: true });
    } catch (err) {
      console.error(err)
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh All Date`,
        success: false
      });
    } finally {
      dispatch(triggerFilter());
      hideSpinner();
    }
  }

  const handleSyncWithDatePopup = () => {
    setOpenSyncDatePopup(true)
  }

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={clsx([classes.appBar, { [classes.appBarShift]: !open }])}>
        <Toolbar>
          <IconButton
            edge='start'
            className={clsx([classes.menuButton, { [classes.hide]: !open }])}
            color='inherit'
            aria-label='menu'
            onClick={handleDrawerClose}
          >
            <MenuIcon />
          </IconButton>
          <Box className={classes.orgBox}>
            <OrganizationSelect />
            <Button
              style={{ color: 'white' }}
              component='a'
              href={`${process.env.REACT_APP_SERVER_URI}/users/login`}
            >
              <img src='/xero_logo.svg' width='40px' style={{ paddingRight: '10px' }} alt='xero_logo' />
              Add Organization
            </Button>
          {/* Should make button with DateTimeRangePickerPopup into it make component if we use it a lot in difference place */}
          <IconButton
            ref={anchorSyncDateRef} 
            disableRipple 
            onClick={handleSyncWithDatePopup} 
            size="small"
            color='inherit'
          >
           <UpdateIcon/>
          </IconButton>
          <DateTimeRangePickerPopup
            open={openSyncDatePopup}
            anchorRef={anchorSyncDateRef} 
            date={date} 
            setDate={setDate}
            onClose={()=>{
              setOpenSyncDatePopup(false)
            }}
            onAfterChange={(range)=> {
              if(!range?.startDate && !range?.endDate){
                setDateError(true)
                return;
              }
              setDateError(false)
            }}
            applyButtonProps={{ startIcon: <RefreshIcon />, disabled: !date?.startDate?.getTime() && !date?.endDate?.getTime()}}
            applyButtonText="Refresh Data"
            onApplyButtonClick={() => handleSync({ dateProps: date })}
            error={dateError}
          />
            <Button
              variant="text"
              color="inherit"
              onClick={handleSync}
            >
               Refresh Data
            </Button>
          </Box>
          <SessionTimeout
            timeout={process.env.REACT_APP_IDLE_DURATION}
            coolDown={process.env.REACT_APP_SESSION_COOL_DOWN}
          />
          <Typography variant='h6' className={classes.title}>
            <ButtonWithXeroImage
              variant='text'
              onClick={handleDisconnect}
              style={{ color: 'white',  }}
              disabled={loading}
            >
              {loading ? 'disconnecting...' : 'disconnect'}
            </ButtonWithXeroImage>
          </Typography>
        </Toolbar>
      </AppBar>
      {/* sidebar area */}
      <Sidebar
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        drawerWidth={drawerWidth}
      />
      {/* main content area */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default Navbar;
