import { makeStyles } from '@material-ui/core';
import { roundDecimal, handleCalculateUnitPrice, handleCalculateAmount, getCurrency } from '../../../utility';
import useMeasure from '../../../../components/custom-hook/use-measure';
import { convertPrice } from '../../../utility';
import { defaultStyles, invoiceListingMinHeight } from './config'

const useStyles = makeStyles({
  table: {
    width: '100%',
    fontSize: defaultStyles.fontSize,
    lineHeight: 1.3,
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        // border: '1px solid black',
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
      },
    },
  },
  textRight: {
    textAlign: 'right',
    fontWeight: 'bold',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: '0px 1px 1px 1px', 
    padding: 5
  },
  noBorder: {
    border: '1px solid white',
    borderRight: '1px solid black',
  },
  haveBorder: {
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 1px', 
  },
  tableHead:{
    '& tr': {
      border: '1px solid black',
      padding: '0px',
      '& th': {
        border: '1px solid black',
        padding: '1em 0.5em',
      },
    },
  },
  tableBodyContentList: {
    '& tr': {
      borderWidth: '0px 1px 0px 1px',
      borderColor: 'black',
      borderStyle: 'solid',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 0px 1px',
      },
    },
  },
  tableBodyEmptySpace: {
    '& tr:first-child': {
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: '0px 1px 1px 1px',
      '& td': {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
      },
    },
  },
  tableCellBody: {
    paddingBottom: 8
  }
});

const InvoiceListing = ({ invoice, exchangeRate, invoiceType }) => {
  const classes = useStyles();
  const terms = JSON.parse(localStorage.getItem("terms"));  
  const { ref: tableBodyRef, size } = useMeasure(invoice.invoiceID);
  const minHeight = invoiceListingMinHeight;

  const additionalHeight = minHeight - (size.height || 0);

  let totalDiscount = 0

  const renderInvoiceItems = amountTypes =>
    invoice.lineItems.map((item, key) => {
      const lineAmount = handleCalculateAmount({
        amountTypes,
        invoiceType,
        lineAmount: item.lineAmount,
        taxAmount: item.taxAmount,
      });
      
      let  discount = Math.round(item.unitAmount * item.quantity - lineAmount); // making sure is positive number
      if(invoice?.lineAmountTypes?.toLowerCase() === "exclusive") {
        discount = (item.unitAmount * item.quantity)*(Number(item.discountRate ?? 0)/100)
      }

      totalDiscount += discount;

      return (
        <tr key={key} style={{ marginBottom: 16 }}>
          <td
            className={classes.tableCellBody}
            style={{
              textAlign: 'center',
              verticalAlign: 'top',
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
            }}
          >
            {key + 1}
          </td>
          <td
            className={classes.tableCellBody}
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              whiteSpace: "pre-wrap",
              
            }}
          >
            {item.description} 
          </td>
          <td
            className={classes.tableCellBody}
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.quantity}
          </td>
       
          {/* unit price field */}
          <td
            className={classes.tableCellBody}
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {item.unitAmount}
          </td>
          {/* Discount field */}
          <td
            className={classes.tableCellBody}
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: discount,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
          {/* amount field */}
          <td
            className={classes.tableCellBody}
            style={{
              // borderBottom: `1px solid ${key === invoice.lineItems.length - 1 ? 'black' : 'white'}`,
              textAlign: 'right',
              verticalAlign: 'top',
            }}
          >
            {convertPrice({
              price: lineAmount + discount,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
      );
    });

  return (
    <div style={{ padding: '1em 0', }}>
    <table className={classes.table}>
      <thead className={classes.tableHead}>
        <tr>
          <th style={{ width: 20 }}>
            ល.រ
            <br />
            No.
          </th>
          <th style={{ width: '48%' }}>
            មុខទំនិញ
            <br />
            Description
          </th>
          <th>
            ចំនួន
            <br />
            Quantity
          </th>
          <th>
            តម្លៃរាយ 
            <br />
            Unit Price
          </th>
          <th>
            បញ្ចុះតម្លៃ
            <br />
            Discount
          </th>
          {invoice.currencyCode === 'USD' ? (
            <th>
              តម្លៃសរុប (ដុល្លា)
              <br />
              Amount (USD)
            </th>
          ) : (
            <th>
              តម្លៃសរុប (រៀល)
              <br />
              Amount (KHR)
            </th>
          )}
        </tr>
      </thead>
      <tbody className={classes.tableBodyContentList} ref={tableBodyRef}>
        {renderInvoiceItems(invoice?.lineAmountTypes.toLowerCase())}
      </tbody>
      <tbody className={classes.tableBodyEmptySpace}>
        <tr style={{height: additionalHeight > 0 ? additionalHeight : 0}}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr >
          <td 
            style={{ 
              padding: '0.5em'
            }} 
            className={classes.haveBorder}
            rowSpan={5} 
            colSpan={2}
          >
          <p style={{ whiteSpace: "pre-line" }}>{terms}</p>
          </td>
        </tr>
        <tr >
          <td 
            style={{ 
              borderColor: 'black',
              borderStyle: 'solid',
              borderWidth: '0px 1px 1px 1px', 
            }}
            colSpan={2}
          >
            <b>សរុប/Sub Total</b>
          </td>
          <td 
            style={{ 
              padding: '0.3em'
            }}  
            className={classes.textRight}
          >
            {convertPrice({
              price: totalDiscount,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
          <td 
            style={{ 
              padding: '0.3em'
            }}  
            className={classes.textRight}
          >
            {convertPrice({
              price: invoice.total + totalDiscount,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
        <tr>
          <td colSpan='3' style={{ textAlign: 'left' }} className={classes.textRight}> 
            សរុបរួម/Grand Total
          </td>
          <td 
            style={{ 
              padding: '0.3em'
            }}  
            className={classes.textRight}
          >
            {convertPrice({
              price: invoice.total,
              currencyCode: invoice.currencyCode,
              convert: false
            })}
          </td>
        </tr>
        <tr>
          <td colSpan='3' style={{ textAlign: 'left' }} className={classes.textRight}>
            អត្រាប្តូរប្រាក់/Exchange Rate
          </td>
          <td 
            style={{ 
              padding: '0.3em'
            }}  
            className={classes.textRight}
          >
            {Number(exchangeRate)?.toLocaleString()}៛
          </td>
        </tr>
        <tr>
          <td colSpan='3' style={{ textAlign: 'left' }} className={classes.textRight}> 
            {`${invoice.currencyCode === 'USD' ? 'រៀល' : 'ដុល្លារ'}`} 
            /Amount ({`${getCurrency({currencyTypeOrCode: invoice.currencyCode, same: true, convert: true})}`})
          </td>
          <td 
            style={{ 
              padding: '0.3em'
            }}  
            className={classes.textRight}
          >
            {convertPrice({
              price: invoice.total,
              currencyCode: invoice.currencyCode,
              exchangeRate,
            })}
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default InvoiceListing;
