import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { useController } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  root: {
    '& textarea': {
      paddingTop: '5px',
    },
  },
}));

const RHFTextInput = ({
  name, // require
  control, // require, Optional when using FormProvider
  required,
  defaultValue = '',
  variant = 'standard',
  size = 'medium',
  label,
  fullWidth = true,
  className = '',
  rules,
  onChange,
  autoComplete = 'off',
  helperText= null,
  onAfterChange,
  disableUnderline = false,
  ...textFieldProps
}) => {

  const classes = useStyles();

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: ucOnChange, ...inputProps },
    meta: { invalid },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, ...rules },
    defaultValue,
  });

  const handleChange = e => {
    const {
      target: { value },
    } = e;
    ucOnChange(value);
    if( typeof onAfterChange === 'function' ) {
      onAfterChange(e)
    }
  };

  return (
    <TextField
      label={label}
      className={`${classes.root} ${className}`}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      value={value}
      onChange={onChange ? data => onChange(data, ucOnChange) : handleChange}
      inputRef={ref}
      error={invalid}
      autoComplete={autoComplete}
      helperText={invalid && helperText}
      {...textFieldProps}
      {...inputProps}
      InputProps={{ disableUnderline, ...inputProps?.InputProps }}
    />
  );
};

export default RHFTextInput;
