import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import SessionTimeoutDialog from './session-timeout-dialog';
import axios from 'axios';
import { handleLogout } from '../../redux-store/slices/user';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

let countdownIntervalId;

const SessionTimeout = ({ timeout, coolDown }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);

  const idleTimerRef = useRef(null);

  const clearSessionInterval = () => {
    clearInterval(countdownIntervalId);
  };

  const handleDisconnect = async () => {
    try {
      clearSessionInterval();
      await dispatch(handleLogout());
      setTimeoutModalOpen(false);
      localStorage.clear();
      history.push('/login');
    } catch (err) {
      console.error(err);
    }
  };

  const handleContinue = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/users/session/validation`, {
        withCredentials: true,
      });
      // if session is not expire user can continue
      clearSessionInterval();
      setTimeoutModalOpen(false);
    } catch (error) {
      console.error('error :>> ', error);
    }
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
    }
  };

  const onIdle = () => {
    if (!timeoutModalOpen) {
      setTimeoutModalOpen(true);
      let countDown = coolDown;
      setTimeoutCountdown(countDown);

      countdownIntervalId = setInterval(() => {
        if (countDown > 0) {
          setTimeoutCountdown(--countDown);
        } else {
          handleDisconnect();
        }
      }, 1000);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        // idle duration
        timeout={Number(timeout)}
      />
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={handleDisconnect}
        open={timeoutModalOpen}
      />
    </>
  );
};

export default SessionTimeout;
