import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useController } from 'react-hook-form';

const defaultLineAmountTypes = [
	{
	  value: 'Exclusive',
	  label: 'Tax Exclusive'
	},
	{
	  value: 'Inclusive',
	  label: 'Tax Inclusive'
	},
	{
	  value: 'NoTax', 
	  label: 'No Tax'
	},
  ];

const RHFSelectInput = ({ 
  name, 
  control,  //optional when use inside formContext
  label,
  options = defaultLineAmountTypes,
  required = false,
  rules,
  valueIdentifier = 'value', // field in array that display value
  labelIdentifier = 'label', // field in array that display label
  size = 'small',
  ...otherProps
}) => {
  const {
  	field: { onChange, value },
  	meta: { invalid },
  } = useController({
  	name,
  	control,
  	rules: {
  	  required,
  	  ...rules,
  	},
  }); 
  return (
  	<>
  	  <TextField
  	    label={label}
  	    variant='standard'
  	    size={size}
  	    select
  	    error={invalid}
  	    fullWidth 
  	    {...otherProps} // put here to make sure `otherProps` don't override `value, onChange`
		value={value}
		onChange={onChange}
  	  >
  	  	{options.map((option, index) => (
  	  	  <MenuItem
  	  	    key={index}
  	  	    value={option[valueIdentifier]}
  	  	    disabled={option.disable}
  	  	  >
			{}
  	  	  	{option[labelIdentifier]}
  	  	  </MenuItem>
  	  	))}
  	  </TextField>
  	</>
  );
};

export default RHFSelectInput;
