import axios from "axios";

// for when update organizations data but the localStorage data still the same
// call this function it will set new organizations data to localStorage
const SetOrganization = ({
  company = false,
  bank = false,
  term = false,
  voucherValidation = false,
}) => {
  const { tenantId } = JSON.parse(localStorage.getItem("tenant"));
  axios
    .get(`${process.env.REACT_APP_SERVER_URI}/organizations/${tenantId}`, {
      withCredentials: true,
    })
    .then((res) => {
      if (company) {
        localStorage.setItem(
          "companyInfo",
          JSON.stringify({ company: res.data.company })
        );
      }
      if (bank) {
        localStorage.setItem(
          "bankInfo",
          JSON.stringify({ company: res.data.bank })
        );
      }
      if (term) {
        localStorage.setItem(
          "terms",
          JSON.stringify(res.data.terms ? res.data.terms : "")
        );
      }
      if (voucherValidation) {
        localStorage.setItem(
          "voucherValidation",
          JSON.stringify(
            res.data.voucherValidation ? res.data.voucherValidation : {}
          )
        );
      }
    })
    .catch((err) => {
      console.error("failed to set organization data to localStorage", err.data)
    });
};

export default SetOrganization;
