import React, { useState } from 'react';
import {
  Popover, Box, Grid, InputBase, Divider, Button, Paper, makeStyles, Typography,
} from '@material-ui/core';
import { DateRangePicker } from 'materialui-daterange-picker';

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    '& > div > div:first-child > div:first-child': {
      '&:first-child': {
        width: '0 !important',
        height: '0 !important',
      },
    },
    '& ul.MuiList-root': {
      '&:not([role=listbox])': {
        display: 'none !important',
      },
    },
    '& .MuiGrid-root.MuiGrid-container.MuiGrid-wrap-xs-nowrap': {
      '& >:first-child': {
        justifyContent: 'flex-end',
        display: 'flex !important',
        flexDirection: 'column-reverse',
      },
      '& > div:first-child > .MuiGrid-align-items-xs-center':{
        padding: '10px 50px',
        color: theme.palette.primary.main
        // display: 'none',
      },
      
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& hr:not(.date-bottom-divider) + div > div': {
        '&:first-child': {
          width: '100%',
          '& .MuiGrid-justify-xs-center': {
            justifyContent: 'space-around',
          },
        },
        '&:not(first-child)': {
          display: 'none !important',
        },
      },
    },
  },
  popoverStyle: {
    zIndex: 1,
  },
  dateRangePickerWrapper:{
    '& .MuiPaper-elevation5': {
      boxShadow: 'none'
    }
  },
  errorValidationText: {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // zIndex: 1
    color: theme.palette.error.main,
    textAlign: 'center',
  }
} ) );

// problem: if `canChangeHour=true` it not working correct yet
const DateTimeRangePicker = ( {
  open = false,
  onClose = () => {},
  anchorRef,
  setDate, // require
  elevation = 8,
  maxDate,
  canChangeHour = false,
  date = {}, // Object example: { startDate: Date, endDate: Date }
  onAfterChange = ( range ) => {},
  popoverProps = {},
  onApplyButtonClick= () => {},
  applyButtonText = "Apply",
  applyButtonProps = {},
  error = false,
} ) => {
  const classes = useStyles();
  // const [open, setOpen] = useState( false );
  // const [empty, setEmtpy] = useState( true );
  // const formatString = 'DD/MM/yy';
  // const [dateRange, setDateRange] = useState( defaultDateRange );
  const [timeRange, setTimeRange] = useState(
    {
      startTime: '00:00',
      endTime: '23:59',
    },
  );

  const handleClose = ( clear = true ) => {
    setDate(); // clear date when close
    onAfterChange({});
    onClose()
  };

  // handle change date after start-date and end-date have been selected
  // Note: only when both start-date and end-date selected that this function will run
  const handleChangeRange = ( range ) => {
    const startTimeSplit = timeRange.startTime.split( ':' );
    const startRange = range.startDate;
    startRange.setHours( startTimeSplit[0], startTimeSplit[1], 0 ); // set start-date hour base on startTime
    const endTimeSplit = timeRange.endTime.split( ':' );
    const endRange = range.endDate;
    endRange.setHours( endTimeSplit[0], endTimeSplit[1], 0 ); // set end-date hour base on endTime
    setDate( { startDate: startRange, endDate: endRange } ); // update start-date and end-date 
    onAfterChange({ startDate: startRange, endDate: endRange });
  };

  // handle when startTime change
  const handleChangeStartTime = ( e ) => {
    const time = e.target.value;
    const newDateTime = new Date( date.startDate );
    const timeSplit = time.split( ':' );
    newDateTime.setHours( timeSplit[0], timeSplit[1], 0 );
    setDate( ( prev ) => ({ ...prev, startDate: newDateTime })); // update start-date hour
    setTimeRange( ( prev ) => ( {
      ...prev, startTime: time,
    } ) ); // update startTime
    onAfterChange({ ...date, startDate: newDateTime });
  };

    // handle when endTime change
  const handleChangeEndTime = ( e ) => {
    const time = e.target.value;
    const newDateTime = new Date( date.startDate );
    const timeSplit = time.split( ':' );
     newDateTime.setHours( timeSplit[0], timeSplit[1], 0 );
    setDate( ( prev ) => ({ ...prev, endDate: newDateTime }));  // update end-date hour
    setTimeRange( ( prev ) => ( {
      ...prev, endTime: time,
    } ) ); // update endTime
    onAfterChange({ ...date, endDate: newDateTime });
  };

  // check does start-date and end-date have value or not
  const checkIfHaveValue = ( ) => {
    if ( date?.startDate && date?.endDate ) {
      return true
    }
    return false;
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorRef?.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={elevation}
        {...popoverProps}
      >
        <Paper className={classes.root}>
          <Box overflow="hidden" position="relative">
            <DateRangePicker
              maxDate={maxDate}
              closeOnClickOutside={false}
              open
              initialDateRange={date}
              toggle={handleClose}
              onChange={handleChangeRange}
              wrapperClassName={classes.dateRangePickerWrapper}
            />
            {/* only when have both start-date and end-date then we able to change hour */}
            { canChangeHour && checkIfHaveValue() && (
              <Grid
                container
                style={{
                  // position: 'absolute', bottom: 0, zIndex: 1, marginBottom: -6,
                }}
              >
                <Grid item xs={12} container justify="space-around">
                  <Grid item xs="auto">
                    <InputBase
                      fullWidth
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={timeRange.startTime}
                      onChange={handleChangeStartTime}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <InputBase
                      fullWidth
                      type="time"
                      value={timeRange.endTime}
                      onChange={handleChangeEndTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />  
                  </Grid>
                </Grid>
              </Grid>
            ) }
            {error && <Typography variant='body2' className={classes.errorValidationText}>Need Both Start-Date and End-Date.</Typography>}
          </Box>
          <Divider className="date-bottom-divider" />
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <Button onClick={handleClose} fullWidth style={{ borderRadius: 0 }} variant="contained" disableElevation>Close</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={onApplyButtonClick}
                fullWidth
                style={{ borderRadius: 0 }}
                color="primary" 
                variant="contained"
                disableElevation
                {...applyButtonProps}
              >
                  {applyButtonText}
                </Button>
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </>
  );
};

export default DateTimeRangePicker;
