import React, { useCallback, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  Box,
  IconButton,
  Divider,
  makeStyles,
  Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ContainerWrapper from "../../../components/container-wrapper";
import RHFTextInput from "../../../components/rhf-controlled-input/text-input";
import RHFSelectInput from "../../../components/rhf-controlled-input/select-input";
import { usePopup } from "../../../components/context/popup-context";
import contactTypes from "../../../data/contact-types.json";

const useStyles = makeStyles((theme) => ({
  container: {
    // maxHeight: 500,
  },
  button: {
    textTransform: "capitalize",
    margin: `0px ${theme.spacing(1)}px`,
  },
  actionButton: {
    color: theme.palette.error.main,
  },
  avatarStyle: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "uppercase",
    marginRight: theme.spacing(1),
  },
  subSection: {
    margin: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    marginBottom: 10,
  },
  sectionLabel: {
    marginTop: "1rem",
  },
  textWithUnderline: {
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "end",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

const defaultPhone = [
  {
    phoneType: "DEFAULT",
    label: "Phone",
  },
  // {
  //   phoneType: 'FAX',
  //   label: 'Fax',
  // },
  // {
  //   phoneType: 'MOBILE',
  //   label: 'Mobile',
  // },
  // {
  //   phoneType: 'DDI',
  //   label: 'Direct dial',
  // },
];

const defaultAddresses = [
  {
    addressType: "POBOX",
    label: "Address in English",
  },
  {
    addressType: "STREET",
    label: "Address in Khmer",
  },
];

const CreateUpdateContact = () => {
  const classes = useStyles();
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const history = useHistory();
  // if have contactID mean we need to update it
  const { id: contactID } = useParams();

  const { errors, handleSubmit, register, control, getValues, reset } = useForm(
    {
      shouldFocusError: true,
      defaultValues: {
        accountNumber: "",
        name: "",
        type: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        skypeUserName: "",
        website: "",
        companyNumber: "",
        taxNumber: "",
        contactPersons: [],
        isSupplier: false,
        isCustomer: false,
        phones: defaultPhone,
        addresses: defaultAddresses,
      },
    },
  );

  useEffect(() => {
    const getData = async () => {
      try {
        showSpinner();
        if (contactID) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER_URI}/contacts/${contactID}`,
            { withCredentials: true },
          );
          // combine addressLine1, addressLine2, addressLine3, addressLine4 to create addressLine by "\n"
          data.addresses = data.addresses.map((address) => {
            // all field that need to check if it have value
            const arrayaddressLine = [
              "addressLine1",
              "addressLine2",
              "addressLine3",
              "addressLine4",
            ];
            const existingAddressLine = [];
            arrayaddressLine.forEach((item) => {
              // if it have value store it in `existingAddressLine`
              if (address[item]) {
                existingAddressLine.push(address[item]);
              }
            });
            // combine all the value to create one string for display
            address.addressLine = existingAddressLine.join("\n") ?? "";
            return address;
          });
          data.type = data?.type?.number ?? "";
          data.phones = data.phones.filter(
            (item) => item.phoneType === "DEFAULT",
          );
          reset(data);
        }
      } catch (err) {
        showAlertPopup({
          message: err.response?.data?.message
            ? err.response.data.message
            : "Sorry Something Went Wrong",
          success: false,
          onExiting: () => history.goBack(),
        });
      } finally {
        hideSpinner();
      }
    };
    getData();
  }, [contactID]);

  const {
    fields: contactPersonsFields,
    append: appendPerson,
    remove: removePerson,
  } = useFieldArray({
    control,
    name: "contactPersons",
  });
  const {
    fields: addressesFields,
    insert: insertAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: "addresses",
  });

  const { fields: phonesFields } = useFieldArray({
    control,
    name: "phones",
  });

  const onSubmit = async (data) => {
    try {
      const { addresses } = data;
      // convert addressLine to addressLine1, addressLine2, addressLine3, addressLine4 by "\n"
      addresses.map((address) => {
        const arrayAddressLine = address.addressLine.split("\n");
        address.addressLine1 = arrayAddressLine[0] ?? "";
        address.addressLine2 = arrayAddressLine[1] ?? "";
        address.addressLine3 = arrayAddressLine[2] ?? "";
        address.addressLine4 =
          arrayAddressLine.slice(3, arrayAddressLine.length + 1).join("\n") ??
          "";
        return address;
      });
      const typeObject = contactTypes.filter(
        (contact) => contact.number === data.type,
      );
      data.type = typeObject[0] ?? null;
      showSpinner();
      if (!contactID) {
        await axios.post(`${process.env.REACT_APP_SERVER_URI}/contacts`, data, {
          withCredentials: true,
        });
      } else {
        await axios.put(
          `${process.env.REACT_APP_SERVER_URI}/contacts/${contactID}`,
          data,
          { withCredentials: true },
        );
      }
      reset();
      showAlertPopup({
        success: true,
        onExiting: () => history.push("/contacts/all"),
      });
    } catch (err) {
      console.error(err.response, "err");
      showAlertPopup({
        message: err.response?.data?.message
          ? err.response.data.message
          : `Fail To ${contactID ? "Update" : "Create"} Contact`,
        success: false,
      });
    } finally {
      hideSpinner();
    }
  };

  const handleAddAnotherPerson = (event) => {
    event.preventDefault();
    appendPerson({
      firstName: "",
      lastName: "",
      emailAddress: "",
      includeInEmails: false,
    });
  };

  return (
    <Container>
      <h1>{!contactID ? "Create" : "Update"} Contact</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ContainerWrapper>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h2>Contact Information</h2>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <Grid item xs={12}>
                  <RHFTextInput 
                    name="accountNumber"
                    control={control}
                    label="Account Number"
                    fullWidth
                  />
                </Grid> */}
                  <Grid item xs={12}>
                    <RHFTextInput
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      label="Contact Name *"
                      fullWidth
                      error={!!errors.name}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextInput
                      name="skypeUserName"
                      control={control}
                      label="Contact Name Kh"
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  {/*** Phone Section ***/}
                  {phonesFields.map((phone, index) => {
                    return (
                      <Grid container item xs={12} key={phone.id} spacing={1}>
                        {register(`phones[${index}].phoneType`, {
                          setValueAs: () => phone.phoneType,
                        })}
                        <Grid
                          container
                          item
                          xs={2}
                          style={{ alignItems: "flex-end", padding: "6px 0" }}
                        >
                          <Typography className={classes.sectionLabel}>
                            {defaultPhone[index].label} :
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Country"
                            name={`phones[${index}].phoneCountryCode`}
                            inputRef={register()}
                            fullWidth
                            defaultValue={phone.phoneCountryCode}
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Area"
                            name={`phones[${index}].phoneAreaCode`}
                            inputRef={register()}
                            fullWidth
                            defaultValue={phone.phoneAreaCode}
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Number"
                            name={`phones[${index}].phoneNumber`}
                            inputRef={register()}
                            fullWidth
                            defaultValue={phone.phoneNumber}
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container item spacing={1}>
                    <Grid item xs={12}>
                      <RHFTextInput
                        name="taxNumber"
                        control={control}
                        label="Tax ID Number"
                        fullWidth
                        margin="dense"
                      />
                    </Grid>
                    {register(`isCustomer`)}
                    {register(`isSupplier`)}
                    <Grid item xs={12}>
                      <RHFSelectInput
                        label="Contact Type *"
                        name="type"
                        options={contactTypes}
                        control={control}
                        required
                        labelIdentifier="nameKH"
                        valueIdentifier="number"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <RHFTextInput
                      name="firstName"
                      control={control}
                      label="First"
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <RHFTextInput
                      name="lastName"
                      control={control}
                      label="Last"
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextInput
                      name="emailAddress"
                      control={control}
                      label={`Email ${
                        contactPersonsFields.length > 0 ? "*" : ""
                      }`}
                      fullWidth
                      rules={{ required: contactPersonsFields.length > 0 }}
                      error={!!errors.emailAddress}
                      margin="dense"
                    />
                  </Grid>

                  {/*** Contact Persons Section ***/}
                  {/* <Grid item xs={12}>
                  <Typography className={classes.sectionLabel}>Contact Persons :</Typography>
                </Grid>
                { contactPersonsFields.map( ( person, index ) => {
                  return (
                    <Box position="relative" key={person.id} width="100%">
                      <IconButton
                        aria-label="delete"
                        type="button"
                        className={classes.deleteIcon}
                        size="small"
                        onClick={(event) => {
                          removePerson(index);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                      <Box className={classes.subSection}>
                        <Grid item xs={12}>
                          <TextField
                            label="First Name"
                            name={`contactPersons[${index}].firstName`}
                            inputRef={register()}
                            fullWidth
                            // defaultValue={`${person.firstName}`}
                            defaultValue={person.firstName}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Last Name"
                            name={`contactPersons[${index}].lastName`}
                            inputRef={register()}
                            fullWidth
                            defaultValue={person.lastName}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Email"
                            name={`contactPersons[${index}].emailAddress`}
                            inputRef={register()}
                            fullWidth
                            defaultValue={person.emailAddress}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <label>Include in emails: </label>
                          <Controller
                            name={`contactPersons[${index}].includeInEmails`}
                            control={control}
                            defaultValue={person.includeInEmails}
                            render={({onChange, value, ref}) => (
                              <Checkbox
                                color="primary"
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                              />
                            )}
                          />
                        </Grid>
                      </Box>
                      <Divider style={{height: 2}}/>
                    </Box>
                  );
                }) } */}
                  {/* <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddAnotherPerson}
                  >
                    Add another person
                  </Button>
                </Grid> */}

                  {/* <Grid item xs={12}>
                  <RHFTextInput 
                    name="website"
                    control={control}
                    label="Website"
                    fullWidth
                  />
                </Grid> */}
                </Grid>

                {/*** Address Section ***/}
                <Grid item xs={12} sm={6}>
                  {/** Ensure that the addressType="POBOX" is alway in the first index **/}
                  {addressesFields.sort( item => item.addressType === "POBOX" ? -1 : 0 ).map((address, index) => {
                    return (
                      <Grid container item xs={12} key={address.id}>
                        {register(`addresses[${index}].addressType`, {
                          setValueAs: () => address.addressType,
                        })}
                        <Grid
                          container
                          item
                          xs={12}
                          justify="space-between"
                          // style={{ alignItems: 'flex-end', padding: '6px 0' }}
                        >
                          <Typography className={classes.sectionLabel}>
                            {defaultAddresses.filter(item => item.addressType === address.addressType)[0]?.label} :
                          </Typography>
                          {/* { index > 0 && (
                          <Typography
                            className={classes.textWithUnderline}
                            onClick={() => {
                               const { addresses } = getValues([
                                 "addresses[0].attentionTo",
                                 "addresses[0].city",
                                 "addresses[0].region",
                                 "addresses[0].country",
                                 "addresses[0].postalCode",
                                 "addresses[0].addressLine",
                                ]);
                              insertAddress(index, { ...addresses[0], addressType: address.addressType, label: address.label } )
                              removeAddress( index + 1 );
                            }}
                          >
                            Same as postal address
                          </Typography>
                          ) } */}
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.subSection}
                          spacing={1}
                        >
                          {/* <Grid item xs={12}>
                          <TextField
                            label="Attention"
                            name={`addresses[${index}].attentionTo`}
                            inputRef={register()}
                            fullWidth
                            defaultValue={address.attentionTo}
                          />
                          </Grid> */}
                          <Grid item xs={12}>
                            <TextField
                              placeholder="Address"
                              name={`addresses[${index}].addressLine`}
                              inputRef={register()}
                              fullWidth
                              multiline
                              rows={4}
                              defaultValue={address.addressLine}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                          <TextField
                            label="City/Town"
                            name={`addresses[${index}].city`}
                            inputRef={register()}
                            fullWidth
                            defaultValue={address.city}
                          />
                          </Grid> */}
                                                {/* <Grid item xs={8}>
                            <TextField
                              label="State/Region"
                              name={`addresses[${index}].region`}
                              inputRef={register()}
                              fullWidth
                              defaultValue={address.region}
                            />
                          </Grid> */}
                                                {/* <Grid item xs={4}>
                            <TextField
                              label="Postal/Zip Code"
                              name={`addresses[${index}].postalCode`}
                              inputRef={register()}
                              fullWidth
                              defaultValue={address.postalCode}
                            />
                          </Grid> */}
                                                {/* <Grid item xs={12}>
                            <TextField
                              label="Country"
                              name={`addresses[${index}].country`}
                              inputRef={register()}
                              fullWidth
                              defaultValue={address.country}
                            />
                          </Grid> */}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </ContainerWrapper>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={6}
            style={{ alignContent: "flex-start" }}
            spacing={1}
          >
            {/* <Grid item xs={12}>
              <h2>Financial Details</h2>
              <Divider />
            </Grid> */}
            {/* <Grid container item xs={12}>
              <RHFTextInput 
                name="companyNumber"
                control={control}
                label="Business Registration Number"
                fullWidth
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <Typography className={classes.sectionLabel}>Tax :</Typography>
            </Grid> */}
            {/* <Grid item xs={12}>{ renderAddress()[1] }</Grid> */}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "end" }}>
            <Button type="submit" color="primary" variant="contained">
              {!contactID ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CreateUpdateContact;
