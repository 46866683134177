import { combineReducers } from 'redux';

import organizationReducer from '../slices/organization';
import userReducer from '../slices/user';
import filterReducer from './filter';

const rootReducer = combineReducers({
    organizationReducer,
    userReducer,
    filterReducer,
});

export default rootReducer;