import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import ContainerWrapper from '../../components/container-wrapper';
import CompanyInfo from './company-information';
import BankInfo from './bank-information';
import SingleImageInput from '../../components/single-image-input';

import useS3 from '../../hooks/use-s3';

import settingApi from '../../api/setting';

const TenantInfoSection = () => {
	const methods = useForm();
	const { enqueueSnackbar } = useSnackbar();
	const { uploadImagesToS3, removeFilesFromS3 } = useS3();

	const [isLoading, setIsLoading] = useState(false);
	/**
	 * companyLogo & signature date shape
	 * {
	 *  file: {name, key, mimeType},
	 *  remove: {name, key, mimeType},
	 * }
	 */
	const [companyLogo, setCompanyLogo] = useState({ file: null, remove: null });
	const [companyLogoPreview, setCompanyLogoPreview] = useState(null);
	const [signature, setSignature] = useState({ file: null, remove: null });
	const [signaturePreview, setSignaturePreview] = useState(null);
	// right now we can't update data back to xero that related to tenant so we just display it
	const [uneditableData, setUneditableData] = useState({ taxNumber: '', phone: {}, companyRegistrationNumber: '' });

	useEffect(() => {
		const getTenant = async () => {
			try {
				const tenant = JSON.parse(localStorage.getItem('tenant'));
				setIsLoading(false);
				const { data: response } = await settingApi.getSingleTenant(tenant.tenantId);

				methods.reset({
					company: response.company,
					bank: response.bank,
				});
				const phone = Array.isArray(tenant?.phones) ? tenant?.phones[0] : {}
				setUneditableData({ taxNumber: response.taxNumber ?? '', phone, companyRegistrationNumber: response.companyRegistrationNumber })
				setCompanyLogo({ file: response?.logo || null, remove: null });
				setCompanyLogoPreview(response?.logo?.tempUrl || null);
				setSignature({ file: response?.signatureImage || null, remove: null });
				setSignaturePreview(response?.signatureImage?.tempUrl || null);
			} catch (error) {
				console.error('error get single tenant :>> ', error);
			} finally {
				setIsLoading(false);
			}
		};

		getTenant();
	}, []);

	const onSubmit = async data => {
		try {
			const tenant = JSON.parse(localStorage.getItem('tenant'));
			const bankInfo = JSON.parse(localStorage.getItem('bankInfo')) || {};
			const companyInfo = JSON.parse(localStorage.getItem('companyInfo')) || {};

			setIsLoading(true);

			// handle upload & delete image files
			const logo = await handleDeleteAndUploadFile(companyLogo, 'tenant-info-logo');
			data.logo = logo;
			const bank = data?.bank || {};
			const company = data.company || {};

			const signatureImage = await handleDeleteAndUploadFile(signature, 'tenant-info-signature');
			data.signatureImage = signatureImage;

			const { data: response } = await settingApi.updateTenant(tenant.tenantId, data);

			setCompanyLogo({ file: logo, remove: null });
			setSignature({ file: signatureImage, remove: null });
			enqueueSnackbar(response.message, { variant: 'success' });

			// update local storage
			localStorage.setItem('logo', response.logo || '');
			localStorage.setItem('signature', response.signatureImage || '');
			localStorage.setItem('bankInfo', JSON.stringify({ bank:{ ...bankInfo, ...bank }}) )
			localStorage.setItem('companyInfo', JSON.stringify({ company:{ ...companyInfo, ...company }}) )
			
		} catch (error) {
			console.error('error update tenant :>> ', error);
			enqueueSnackbar(error?.response?.data?.message || 'error update tenant', { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	/**
	 * handle upload and delete logo file from S3
	 * @param {Object} fileObject - {file, remove}
	 */
	const handleDeleteAndUploadFile = async (fileObject, folderName) => {
		let uploadedFile = null;

		if (fileObject.remove?.key) {
			await removeFilesFromS3([{ Key: fileObject.remove.key }]);
		}

		// if user add new image file
		if (fileObject.file && !fileObject.file?.key) {
			const [uploadedFileObj] = await uploadImagesToS3([fileObject.file], folderName, true);
			uploadedFile = uploadedFileObj;
		}

		// if no new image file return the old one
		if (fileObject.file?.key) {
			uploadedFile = fileObject.file;
		}

		return uploadedFile;
	};

	return (
		<ContainerWrapper boxProps={{ p: 4 }}>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Grid item container spacing={5}>
						<Grid item xs={12}>
							<Typography variant='h4' style={{ fontWeight: 'bold' }}>
								Organisation Details
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<h2>Company Logo</h2>
							<SingleImageInput
								onChange={setCompanyLogo}
								disabled={isLoading}
								defaultPreview={companyLogoPreview}
							/>
						</Grid>
						<Grid item xs={6}>
							<h2>Signature</h2>
							<SingleImageInput
								onChange={setSignature}
								disabled={isLoading}
								defaultPreview={signaturePreview}
							/>
						</Grid>
						<Grid item xs={6}>
							<CompanyInfo data={uneditableData}/>
						</Grid>
						<Grid item xs={6}>
							<BankInfo />
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
					</Grid>
					<Box mt={3} display='flex' justifyContent='flex-end'>
						{/* if fields not dirty disable save button */}
						<Button type='submit' color='primary' variant='contained' disabled={isLoading}>
							Save
						</Button>
					</Box>
				</form>
			</FormProvider>
		</ContainerWrapper>
	);
};

export default TenantInfoSection;
