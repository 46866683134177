import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme)=>({
  container: {
    // marginTop: "1em",
    pagebreakInside: "avoid",
  },
  informationContainer: {
    border: '1px black solid',
    margin: `${theme.spacing(1)}px 0px 0px ${theme.spacing(1)}px`
  },
  title: {
    marginLeft: theme.spacing(1)
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { bank } = JSON.parse(localStorage.getItem("bankInfo"));
  return (
    <Grid container className={classes.container} style={{ lineHeight: 1.5, }}>
      <Grid item xs={12} className={classes.title}>
        របៀបទូទាត់
      </Grid>
      <Grid container item xs={6} spacing={1} className={classes.informationContainer}>
        <Grid container item >
          <Grid item>Bank Name:</Grid>
          <Grid item>
            <b>{bank?.name}</b>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>Account Name:</Grid>
          <Grid item>
            <b>{bank?.accountName}</b>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>Account Number:</Grid>
          <Grid item>
            <b style={{ whiteSpace: "pre-line" }}>{bank?.accountNumber}</b>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
