import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PieChartIcon from '@material-ui/icons/PieChart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

const drawerMenu = [
  // { name: 'Invoices', path: '/invoices', Icon: ReceiptIcon },
  {
    name: 'Invoices',
    path: '/invoices',
    Icon: ReceiptIcon,
    submenu: [
      { name: 'All', path: '/invoices' },
      // { name: 'Create', path: '/invoices/create' },
    ],
  },
  { name: 'Quotes', path: '/quotes', Icon: InsertDriveFileIcon },
  { name: 'Prepayments', path: '/deposit-slip', Icon: LibraryBooksIcon },
  { name: 'Credit Notes', path: '/credit-note', Icon: PaymentIcon },
  {
    name: 'Bills',
    path: '/bills',
    Icon: DescriptionIcon,
    submenu: [
      { name: 'All', path: '/bills/generate-bills' },
      { name: 'Bills to Xero', path: '/bills/bills-to-xero' },
    ],
  },
  { name: 'Manual Journals', path: '/manual-journal', Icon: AssignmentIcon },
  { name: 'Purchase Orders', path: '/purchase-orders', Icon: ShoppingBasketIcon },
  {
    name: 'Contacts',
    path: '/contacts',
    Icon: PermContactCalendarIcon,
    submenu: [
      { name: 'All', path: '/contacts/all' },
      { name: 'Create', path: '/contacts/create' },
    ],
  },
  { name: 'Products & Services', path: '/products-and-services', Icon: LocalMallIcon },
  {
    name: 'Chart of Accounts',
    path: '/chart-of-accounts',
    Icon: DonutLargeIcon,
    submenu: [
      { name: 'All', path: '/chart-of-accounts/all' },
      { name: 'Create', path: '/chart-of-accounts/create' },
    ],
  },
  {
    name: 'Reports',
    path: '/reports',
    Icon: PieChartIcon,
    submenu: [
      { name: 'VAT Reports', path: '/reports/vat-reports' },
    ],
  },
  {
    name: 'Setting',
    path: '/settings',
    Icon: SettingsIcon,
    submenu: [
      { name: 'Organisation Details', path: '/setting/tenant-info' },
      { name: 'Exchange Rate', path: '/setting/api-key' },
    ],
  },
];

export default drawerMenu;
