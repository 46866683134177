import React, { Fragment, useState } from 'react'
import { ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, ListItemIcon, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack';
// Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';

import quoteAPI from '../../../api/quote';

const QuoteActionButton = ({ quoteData, query }) => {
  // Feedback Section
  const { enqueueSnackbar } = useSnackbar()

  // More Button Section
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  // Call api section
  const handleUpdateQuote = async (updateObj) => {
    try {
      // Close the popover
      setAnchorEl(null)
      const { data } = await quoteAPI.updateQuoteById(quoteData.quoteID, updateObj)
      query()
      enqueueSnackbar(data.message)
    } catch (error) {
      console.error(error)
    }
  }

  const acceptBtn = <MenuItem key={0}
    onClick={handleUpdateQuote.bind(null, { status: "ACCEPTED" })}
  >
    <ListItemIcon>
      <CheckIcon color="primary" />
    </ListItemIcon>
    <Typography>Mark as accepted</Typography>
  </MenuItem>
  const declineBtn = <MenuItem key={1}
    onClick={handleUpdateQuote.bind(null, { status: "DECLINED" })}
  >
    <ListItemIcon>
      <CloseIcon color="error" />
    </ListItemIcon>
    <Typography>Mark as declined</Typography>
  </MenuItem>
  const sendBtn = <MenuItem key={3}
    onClick={handleUpdateQuote.bind(null, { status: "SENT" })}
  >
    <ListItemIcon>
      <SendIcon color="primary" />
    </ListItemIcon>
    <Typography>Mark as sent</Typography>
  </MenuItem>
  const deleteBtn = <MenuItem key={4}
    onClick={handleUpdateQuote.bind(null, { status: "DELETED" })}
  >
    <ListItemIcon>
      <DeleteIcon color="error" />
    </ListItemIcon>
    <Typography>Delete</Typography>
  </MenuItem>
  const noAction = <MenuItem key={5}
    disabled
  >
    <Typography>No Action</Typography>
  </MenuItem>
  const statusAndItsButton = {
    draft: [sendBtn, deleteBtn],
    sent: [acceptBtn, declineBtn, deleteBtn],
    accepted: [noAction],
    declined: [noAction],
  }
  const { status } = quoteData

  return (
    <Fragment>
      <ButtonGroup>
        <Button size="small" onClick={handleClick} color='primary'>
          <MoreHorizIcon />
        </Button>
      </ButtonGroup>

      <Popper
        id={id} open={open} anchorEl={anchorEl} transition
        disablePortal
        style={{ zIndex: '999' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClick}>
                <MenuList>

                  {statusAndItsButton[status.toLowerCase()]}

                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>)}
      </Popper>
    </Fragment>
  )
}

export default QuoteActionButton