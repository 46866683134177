import CreditNoteTemplate from "../printing-page/default-template/credit-note";

const getCreditNoteTemplate = ({
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  image,
  exchangeRate,
}) => {
  return (
    <CreditNoteTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      ref={ref}
      data={data}
      image={image}
      exchangeRate={exchangeRate}
    />
  );
};

export { getCreditNoteTemplate }
  