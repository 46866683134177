import { makeStyles, TextField, debounce, Box, Typography, Paper, AppBar, Toolbar, ListItem, ListItemText, Chip, Grid  } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import { Controller } from 'react-hook-form';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useQuery from '../custom-hook/use-query';

const useStyles = makeStyles((theme) => ({
  root: {
    '& textarea': {
      paddingTop: '5px',
    },
  },
  avatarInputIcon: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    width: 24,
    height: 24,
    fontSize: '0.7rem'
  },
  avatarOptionIcon: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    width: 32,
    height: 32,
    fontSize: '0.9rem'
  },
  loading: {
    top: "calc(50% - 14px)",
    right: 0,
    position: "absolute",
  },
  listOption: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
    width: '100%'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    minHeight: 50
    // padding: 
  },
  toolbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
    paddingLeft: 0
  },
  paperPopup: {
    paddingBottom: 50.
  },
  itemLoading: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  shrink: {
    transform: "translate(32px, 24px) scale(1)",
    transformOrigin: 'top left',
  }

}));

const ItemAutocomplete = ({
  name, // require
  control, // require, Optional when using FormProvider
  required,
  error: formError = false,
  placeholder = "",
  defaultValue = null,
  createNewButton = false, // not working yet so keep it as false
  onAfterChange,
//   variant = 'standard',
//   size = 'medium',
  label,
//   fullWidth = true,
//   className = '',
  rules,
//   onChange,
//   helperText= null,
//   ...textFieldProps
}) => {

  const classes = useStyles();
  const limit = 100; // limit the item show in list. Note: if we render too many item at the same time it will lag
  const { data, error, query, loading } = useQuery(`${process.env.REACT_APP_SERVER_URI}/items`);
  const [value, setValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState('');
  const [canCreateNew, setCanCreateNew] = useState(false);

  const handleFetchContactData = async ( searchProps ) => {
    try{
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // when searchProps is 'null' or 'undefined' it won't be used.
        // so the searchProps stay the same. this effect happen when changing pagination
        search: searchProps,
        perPage: limit
      });
    } catch ( err ) {
      console.error( err );
    }
  }

  useEffect( ( ) => {
    handleFetchContactData();
  }, []);

  const handleFilterOptions = (options, params) => {
    // we don't we need filter since anything will be filter by backend 
    return options;
  }

  const handleRenderOption = ( option, state ) => {
    // const arrayDetail = [ `${option.firstName ?? ""} ${option.lastName ?? ""}`.trim() , option.emailAddress ].filter(
    //   ( detail ) => detail
    // )
    return (
        <Box className={classes.listOption} py={1/2}>
          <Grid container item xs={12} justify='space-between'>
            <Grid item xs="auto">
              <Chip size='small' variant="outlined" color="primary" label={option.code}/>
            </Grid>
            <Grid item xs="auto">
              {option?.salesDetails?.unitPrice}
            </Grid>
          </Grid>
          <Box style={{ alignSelf: 'normal' }} pt={1/2}>
          <Typography
              variant='body2'
              style={{
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {option.name}
            </Typography>
            <Typography
              component="span"
              variant='body2'
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
              >
                {option.description}
            </Typography>
            </Box>
        </Box>
    );
  }


  const PaperComponent = ( props, test ) => {
    const isCreatable =  !loading && canCreateNew && createNewButton;
    return(
      <>
        <Paper className={isCreatable ? classes.paperPopup : undefined}>
          { loading ? <div className="MuiAutocomplete-loading">Loading…</div> : props.children }
        </Paper> 
        
        { isCreatable && (
          <AppBar  position="fixed" className={classes.appBar} >
            <Toolbar variant="dense" className={classes.toolbar}>
            <ListItem button dense>
            <ListItemIcon>
              <AddIcon style={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Create as new Contact" />
          </ListItem>
            </Toolbar>
          </AppBar>
        ) }
      </>
    );
      
  }

  return (
    <Controller 
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={(props, { invalid }) => {
        return (
          <Autocomplete
            {...props}
            id="item-autocomplete"
            options={data?.docs ?? []}
            getOptionLabel={(option) =>  option.name}
            renderOption={handleRenderOption}
            getOptionSelected={(option, value) => {
              return option.name === value.name
            }}
            onChange={(_, data) => {
              props.onChange(data);
              if( typeof onAfterChange === 'function' ){
                onAfterChange(data);
              }
            }}
            onInputChange={debounce((event, newInputValue, reason ) => {
              setInputValue(newInputValue);
              const arrName = data?.docs.map( ( value ) => value?.name.toLowerCase() ); 
              if ( newInputValue !== '' && !arrName?.includes( newInputValue.toLowerCase() ) ) {
                  setCanCreateNew(true)
              } else {
                  setCanCreateNew(false)
              }
            
              // when selected a value we don't need to query it
              if (newInputValue !== "" && reason === "reset") {
                return;
              }
              handleFetchContactData(newInputValue);
            }, 500)}
            filterOptions={handleFilterOptions}
            PaperComponent={PaperComponent}
            renderInput={(params) => {
              return (
                <TextField 
                  {...params}
                  multiline
                  label={label}
                  error={invalid}
                  margin="none" 
                  placeholder={placeholder}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        loading ? <Box className="MuiAutocomplete-endAdornment"><CircularProgress color="primary" size={20}/></Box> : params.InputProps.endAdornment
                    ),
                    // startAdornment: (
                    //     !props?.value ? null : <Chip variant="outlined" size='small' color="primary" label={props?.value?.code} className={classes.codeFieldStyle}/>
                    // ),
                    disableUnderline: true

                  }}
                />
              )
            }}
           />
        )
      }}
    />
  );
};

export default ItemAutocomplete;
