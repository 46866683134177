import React from 'react';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { useController } from 'react-hook-form';

const RHFCheckboxInput = ({ name, control, label, required = false, rules, size = 'small' }) => {
	const { field } = useController({
		name,
		control, //optional when use inside formContext
		rules: {
			required,
			...rules,
		},
	});

	return (
		<FormControlLabel
			label={label}
			control={
				<Checkbox
					disableRipple
					color='primary'
					size={size}
					{...field}
					checked={field.value}
					onChange={e => field.onChange(e.target.checked)}
				/>
			}
		/>
	);
};

export default RHFCheckboxInput;
