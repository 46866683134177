import {
  TableCell,
  TableBody,
  TableRow,
  Checkbox,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

const useStyles = makeStyles(( theme ) => ({
  errorIcon: {
    fontSize: "7rem"
  },
  errorTableCell: {
    textAlign: "center",
    padding: "1rem 0",
    height: 300,
  },
  loadingTableCell: {
    textAlign: "center",
    padding: "1rem 0",
  },
  errorText :{
    fontSize: "1.5rem",
    fontWeight: "bold"
  }
}));

// we do this because bracket notation not working with nested object
// Example: test = { a: 2, b:{ c: 3 }};
// getValueFromObjByString( test, 'a' ) = test.a = 2
// getValueFromObjByString( test, 'b.c' ) = test.b.c = 3
const getValueFromObjByString = ( obj, string = '' ) => {
  return string.split( '.' ).reduce(
    (previousValue, currentValue) => previousValue?.[currentValue], obj
  )
}

const CustomTableBody = ({
  schema,
  dataList,
  handleSelect,
  handleClick = () =>{},
  selected,
  disableCheckBox,
  loading,
  handleCheckboxClick,
  handleShowCheck = () => false,
  checkboxProps = {},
  disableHover = false,
}) => {
  const classes = useStyles();

  return (
    <TableBody>
      {/* check for loading */}
      { !loading ? (
        // check is type of Array or not, if not display error
        Array.isArray( dataList ) ?
          // check is have result or not, if not display no result
          dataList.length > 0 ? 
            dataList.map(( row, rowIndex ) => {
              return (
                <TableRow
                  key={rowIndex}
                  hover={!disableHover}
                  onClick={(event) => handleClick(event, row)}
                >
                  {!(disableCheckBox) && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        className={classes.checkBoxStyle}
                        {...checkboxProps}
                        onClick={(e) => handleCheckboxClick(e, row)}
                        checked={handleShowCheck(row)}
                      />
                    </TableCell>
                  )}
                  {schema.map((itemSchema, index) => {
                    const currentCellValue = getValueFromObjByString( row, itemSchema.field );
                    return ( 
                      <TableCell key={index}  {...itemSchema.tableCellProps}>
                        {itemSchema.render ? itemSchema.render(row, rowIndex) : currentCellValue }
                      </TableCell>
                     )
                  })}
                </TableRow>
              );
            }) : (
            // display no result
            <TableRow>
              <TableCell colSpan={schema.length + 1} padding="none" className={classes.errorTableCell}>
                <FindInPageOutlinedIcon color='primary' className={classes.errorIcon}/>
                <Typography className={classes.errorText}>No Result Found!</Typography>
              </TableCell>
            </TableRow>
          ) : (
          // display error
          <TableRow>
            <TableCell colSpan={schema.length + 1} padding="none" className={classes.errorTableCell}>
              <ErrorOutlineIcon color='error' className={classes.errorIcon}/>
              <Typography className={classes.errorText}>Error</Typography>
            </TableCell>
          </TableRow>
        )
      ) : (
        // display loading
        <TableRow>
          <TableCell colSpan={schema.length + 1} className={classes.loadingTableCell}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}     
    </TableBody>
  );
};

export default CustomTableBody;
