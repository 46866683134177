import { useRef, useState, useCallback, } from 'react'
import ReactToPrint from 'react-to-print';
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import PrintIcon from "@material-ui/icons/Print";

const useStyles = makeStyles((theme) => ({
  printButtonContainer: {
    padding: 2,
  },
  pdfWrapper: {
    top: -40000,
    left: -40000,
    position: "absolute",
    visibility: "hidden",
  },
}));

export const pageStyle = `
@page {
    /* Remove browser default header (title) and footer (url) */
    margin: 0;
    size: a4;
}
@media print {
    body {
        /* Tell browsers to print background colors */
        -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */
        color-adjust: exact; /* Firefox */
    }
}
`;

/**
 * @param icon icon in front of the button, can be component or function that return component
 * @param {string} buttonLabel text display in button, default `'Print'`
 * @param {string} documentTitle file name when save, default `'document'`
 * @param {*} onBeforeGetContent function can be void  or promise if it a promise don't forget to throw err in catch so we can cancel print event
 * @param {*} onBeforePrint function can be void  or promise if it a promise don't forget to throw err in catch so we can cancel print event
 * @param {*} onAfterPrint function will trigger after print dialog is closed
 * @param {*} onPrintError function will trigger when error occurred
 * @param {boolean} disabled for disable button, default `false`
 * @param {boolean} removeAfterPrint for remove the print iframe after action, default `true`
 * @param {object} printComponent can be component or function that return component
 * @param {string} bodyClass class names to pass to the print window
 * @param {boolean} showTemplate for debug template style, default `false`
 */
const PrintButton = ({
  icon = <PrintIcon />,
  buttonLabel = "Print",
  documentTitle = "document",
  onBeforeGetContent = () => {}, // Note: throw err in catch if promise to cancel print event otherwise when error it will print black page
  onBeforePrint = () => {}, // Note: throw err in catch if promise to cancel print event otherwise when error it will print black page
  onAfterPrint = () => {},
  onPrintError = (errorLocation, error) => {}, // errorLocation: "onBeforeGetContent" | "onBeforePrint" | "print"
  disabled = false,
  removeAfterPrint = true,
  bodyClass,
  printComponent = null,
  showTemplate = false,
}) => {
  const componentRef = useRef(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const isDisabled = disabled || loading;

  // use for optimizing, only render element before print and remove it after print
  const [shouldRender, setShouldRender] = useState(false);

  const resetState = () => {
    setLoading(false);

    // hide element if true
    if (!showTemplate) {
      setShouldRender(false);
    }
  };

  // run before the printing even started
  const handleOnBeforeGetContent = useCallback(async () => {
    setLoading(true); // show loading
    setShouldRender(true); // show element
    try {
      await onBeforeGetContent();
    } catch (err) {
      throw err; // cancel print event
    }
  }, [onBeforeGetContent]);

  // run before printing
  const handleBeforePrint = useCallback(async () => {
    setLoading(false); // hide loading before print dialog load
    try {
      await onBeforePrint();
    } catch (err) {
      throw err; // cancel print event
    }
  }, [onBeforePrint]);

  // run after printing done
  const handleAfterPrint = useCallback(() => {
    onAfterPrint();

    // hide element if true
    if (!showTemplate) {
      setShouldRender(false);
    }
  }, [onAfterPrint, showTemplate]);

  // if print error reset here so we don't need to reset it in multiple catch
  const handleOnPrintError = (errorLocation, error) => {
    resetState();
    onPrintError(errorLocation, error);
  };

  // display button
  const reactToPrintTrigger = () => {
    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          pointerEvents: isDisabled ? "none" : "auto", // disable click event for div if isDisabled=true
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isDisabled}
          startIcon={typeof icon === "function" ? icon() : icon}
          style={{ position: "relative" }}
        >
          {buttonLabel}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
      </div>
    );
  };

  // max height of A4 paper
  const maxHeight = 1122;

  // // Note: in order for this to work make sure every element in you template have no margin use padding instead
  // const memoizedCallback = useCallback(
  //   () => {
  //     // get all template that about to print since sometime there are multiple template print at the same time
  //     const arrayElement = Array.from(componentRef.current?.children[0]?.children[0].children)
  //       // loop through each template and calculate it height
  //       arrayElement.forEach((element) => {

  //         const template = Array.from(element.children ?? []);
  //         let totalHeight =  0;

  //         // get each element that in the template
  //         for(var inner of template) {
  //           // get the element height
  //           let innerHeight = parseFloat(getComputedStyle(inner).height, 10);
  //           let tempTotalHeight = inner.className.includes("movableElement") ? totalHeight + innerHeight : (totalHeight + innerHeight) % maxHeight;
  //           // totalHeight + innerHeight
  //           if ( tempTotalHeight > maxHeight ) {
  //             const child = document.createElement('div');
  //             child.className = '123123123'
  //             child.style.height = `${maxHeight - totalHeight}px`;
  //             element.insertBefore(child, inner)
  //             break;
  //           }
  //           totalHeight = tempTotalHeight;
  //       }
  //       })
  //   },  
  //   [],
  // );

  // const removeElement = useCallback(( ) => {
  //   let nodeArray = Array.from(document.getElementsByClassName("123123123") ?? []);
  //   nodeArray.forEach((node) => {
  //     if (node.parentNode) {
  //       node.parentNode.removeChild(node);
  //     }
  //   })
  // }, [])

  const debug = false;
  
  const debugElement = ( ) => {
    return (
      <div style={{ width: '700px' }}>
        <div style={{ height: 222, backgroundColor: 'red' }}>
          <pre style={{ margin: 0 }}>{JSON.stringify(componentRef?.current?.children[0]?.children[0]?.getBoundingClientRect().height, null, 2)}</pre> 
        </div>
        <div style={{ height: 500, backgroundColor: 'green' }}>
          <pre style={{ margin: 0 }}>{JSON.stringify(componentRef?.current?.children[0]?.children[1]?.getBoundingClientRect().height, null, 2)}</pre> 
        </div>
        <div style={{ height: 200, backgroundColor: 'blue' }} className="movableElement">
          <pre style={{ margin: 0 }}>{componentRef?.current?.children[0]?.children[2]?.clientHeight}</pre> 
        </div>
        <div style={{ height: 200, backgroundColor: 'yellow' }} className="movableElement">
          <pre style={{ margin: 0 }}>{JSON.stringify(componentRef?.current?.children[0]?.children[3]?.getBoundingClientRect().height, null, 2)}</pre> 
        </div>
      </div>
    )
  }

  const getRef = useCallback((element)=> {
    if(element) {
      componentRef.current = element
      // removeElement()  
      // memoizedCallback()
    }
  }, [
    // memoizedCallback,
    // removeElement,
  ])

  return (
    <>
      <div style={{ wordWrap: 'break-word' }} className={!showTemplate ? classes.pdfWrapper : ''}>
        { shouldRender && ( 
          <div ref={getRef} style={{ padding: 0 }}>
            {debug ? debugElement() : typeof printComponent === 'function' ? printComponent(): printComponent}
          </div>
        )}
      </div>
      <div className={classes.printButtonContainer}>
        <ReactToPrint
          content={() => componentRef.current }
          documentTitle={documentTitle}
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          onPrintError={handleOnPrintError}
          removeAfterPrint={removeAfterPrint}
          trigger={reactToPrintTrigger}
          bodyClass={bodyClass}
          pageStyle={pageStyle}
        />
      </div>
    </>
  );
};

export default PrintButton;
