import { forwardRef } from "react";
import { makeStyles, Box, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

import CreditNoteListing from "./credit-note-listing";
import SignatureSection from "./signature-section";
import Footer from "./footer";
import { removeAllSpaceFromString } from "../../../utility";
// import commaNumber from "comma-number";

const useStyles = makeStyles((theme) => ({
  pdfWrapper: {},
  container: {
    margin: "0 auto",
    fontSize: 11,
    position: "relative",
  },
  header: {
    textAlign: "center",
    top: 0,
    width: "100%",
  },
  logo: {
    width: 110,
    position: "absolute",
    left: 0,
  },
  body: {},
  pagebreak: {
    pageBreakBefore: "always",
  },
  headerWrpper: {
    width: "65%",
    display: "inline-block",
  },
  bottomDescription: {
    // textAlign: 'right',
    paddingRight: theme.spacing(1/2),
  },
}));

// max-height: 976px

const CreditNoteTemplate = (
  {
    data,
    contentHeights,
    footerHeight,
    signatureHeight,
    printScale = false,
    image = null,
    exchangeRate,
  },
  ref,
) => {
  const classes = useStyles();
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const { company } = JSON.parse(localStorage.getItem("companyInfo"));

  let footerPadding = [],
    signaturePadding = [],
    allHeight,
    pageHeight = 976;
  contentHeights &&
    contentHeights.forEach((height, idx) => {
      allHeight = height + signatureHeight + footerHeight;
      if (allHeight > pageHeight) {
        footerPadding[idx] = signaturePadding[idx] = 0;
        const exceedPage = (allHeight =
          (height % pageHeight) + signatureHeight + footerHeight - pageHeight);
        if (exceedPage > 0) {
          if (exceedPage <= footerHeight) {
            footerPadding[idx] = footerHeight - exceedPage;
          } else
            signaturePadding[idx] = footerHeight + signatureHeight - exceedPage;
        }
      }
    });

  const invoiceDetailSection1Label = 4;
  const invoiceDetailSection1Value = 8;

  const invoiceDetailSection2Label = 6;
  const invoiceDetailSection2Value = 6;

  return (
    <Box className={classes.pdfWrapper}>
      <div ref={ref} className={classes.testing}>
        {data.map((creditNote, key) => {
          const streetaddress = creditNote.contact?.addresses.filter(
            (address) => address.addressType === "STREET",
          )[0];
          const poboxaddress = creditNote.contact?.addresses.filter(
            (address) => address.addressType === "POBOX",
          )[0];

          const streetaddressString = `${
            streetaddress?.addressLine1 ? streetaddress.addressLine1 : ""
          } ${streetaddress?.addressLine2 ? streetaddress.addressLine2 : ""} ${
            streetaddress?.addressLine3 ? streetaddress.addressLine3 : ""
          } ${streetaddress?.addressLine4 ? streetaddress.addressLine4 : ""}`;

          const poboxaddressString = `${
            poboxaddress?.addressLine1 ? poboxaddress?.addressLine1 : ""
          } ${poboxaddress?.addressLine2 ? poboxaddress?.addressLine2 : ""} ${
            poboxaddress?.addressLine3 ? poboxaddress?.addressLine3 : ""
          } ${poboxaddress?.addressLine4 ? poboxaddress?.addressLine4 : ""}`;

          return (
            <div
              key={key}
              className={clsx([classes.container, classes.pagebreak])}
            >
              <div>
                <Box className={classes.header}>
                  {image ? (
                    <img className={classes.logo} src={image} alt="" />
                  ) : (
                    tenant.logo.length > 0 && (
                      <img className={classes.logo} src={tenant.logo} alt="" />
                    )
                  )}
                  <Box className={classes.headerWrpper}>
                    <Typography variant="h1">{company?.name}</Typography>
                    <Typography style={{ textTransform: "uppercase" }}>
                      {tenant.tenantName}
                    </Typography>
                    {creditNote?.taxId && (
                      <Typography variant="h3">
                        លេខអត្តសញ្ញាណកម្មសារពើពន្ធ {creditNote.taxId}
                      </Typography>
                    )}
                    <Typography variant="h5">{company?.address}</Typography>
                    <Typography variant="h5">
                      {tenant.addresses[0]?.addressLine1}
                    </Typography>
                    <Typography variant="h5">
                      លេខទូរសព្ទ / Telephone No: (
                      {tenant.phones[0]?.phoneAreaCode ||
                        tenant.phones[0]?.phoneCountryCode}
                      ){" "}
                      {tenant.phones[0]?.phoneNumber.charAt(0) === "0"
                        ? tenant.phones[0]?.phoneNumber.substring(1)
                        : tenant.phones[0]?.phoneNumber}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box style={{ textAlign: "center", margin: 15 }}>
                    <Typography variant="h1">ឥណទានអតប</Typography>
                    <Typography variant="h1">CREDIT NOTE</Typography>
                  </Box>
                </Box>
                <Grid container spacing={1}>
                  {/* customer info */}
                  <Grid
                    className={classes.body}
                    container
                    item
                    xs={8}
                    alignContent="flex-start"
                    style={{ lineHeight: 1.5 }}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={invoiceDetailSection1Label}>
                        ឈ្មោះក្រុមហ៊ុន ឬ អតិថិជន៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection1Value}>
                        {creditNote.contact?.skypeUserName}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={invoiceDetailSection1Label}>
                        Company/Customer៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection1Value}>
                        {creditNote.contact?.name}
                      </Grid>
                    </Grid>
                    {removeAllSpaceFromString(streetaddressString).length >
                      0 && (
                      <Grid container item xs={12}>
                        <Grid item xs={invoiceDetailSection1Label}>
                          អាសយដ្ឋាន៖
                        </Grid>
                        <Grid item xs={invoiceDetailSection1Value}>
                          {streetaddressString}
                        </Grid>
                      </Grid>
                    )}
                    {removeAllSpaceFromString(poboxaddressString).length >
                      0 && (
                      <Grid container item xs={12}>
                        <Grid item xs={invoiceDetailSection1Label}>
                          Address៖
                        </Grid>
                        <Grid item xs={invoiceDetailSection1Value}>
                          {poboxaddressString}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container item xs={12}>
                      <Grid item xs={invoiceDetailSection1Label}>
                        ទូរសព្ទលេខ៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection1Value}>
                        {creditNote.contact?.phones[0]?.phoneNumber
                          ? `${
                              creditNote.contact?.phones[0]?.phoneCountryCode
                                ? `( ${creditNote.contact?.phones[0]?.phoneCountryCode} ) `
                                : ``
                            }` + creditNote.contact?.phones[0]?.phoneNumber
                          : ""}
                      </Grid>
                      <Grid item xs={invoiceDetailSection1Label}>
                        Telephone No.៖
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={invoiceDetailSection1Label}>
                        លេខអត្តសញ្ញាណកម្ម អតប៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection1Value}>
                        {creditNote.contact?.taxNumber}
                      </Grid>
                      {/* <Grid item xs={invoiceDetailSection1Label}>
                        VAT TIN៖
                      </Grid> */}
                    </Grid>
                  </Grid>

                  {/* Invoice Section */}
                  <Grid
                    container
                    item
                    xs={4}
                    alignContent="flex-start"
                    style={{ lineHeight: 1.5 }}
                  >
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={classes.bottomDescription}
                      >
                        លេខរៀងឥណទានអតប៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection2Value}>
                        <b>{creditNote.creditNoteNumber}</b>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={classes.bottomDescription}
                      >
                        Credit Note No.៖
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={classes.bottomDescription}
                      >
                        ឯកសារយោង៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection2Value}>
                        <b>{creditNote.reference}</b>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={classes.bottomDescription}
                      >
                        Reference No.៖
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={classes.bottomDescription}
                      >
                        កាលបរិច្ឆេទ៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection2Value}>
                        {moment(creditNote.date).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={invoiceDetailSection2Label}
                        className={classes.bottomDescription}
                      >
                        Date៖
                      </Grid>
                    </Grid>
                    {/* <Grid container item xs={12}>
                      <Grid item xs={invoiceDetailSection2Label} className={classes.bottomDescription}>
                        អាត្រាប្ដូរប្រាក់/Exchange rate៖
                      </Grid>
                      <Grid item xs={invoiceDetailSection2Value}>
                        {exchangeRate && `${commaNumber(exchangeRate)} ៛`}
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
                <CreditNoteListing
                  creditNote={creditNote}
                  exchangeRate={exchangeRate}
                />
              </div>
              <div
                style={{
                  paddingTop: `${
                    !printScale &&
                    signatureHeight !== 0 &&
                    signaturePadding[key]
                  }px`,
                }}
              >
                <SignatureSection company={company} />
              </div>
              <div
                style={{
                  paddingTop: `${
                    !printScale && footerHeight !== 0 && footerPadding[key]
                  }px`,
                }}
              >
                <Footer />
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default forwardRef(CreditNoteTemplate);
