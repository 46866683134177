import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { Box } from '@material-ui/core';

import { login } from '../../redux-store/slices/user';
import { switchOrganizationSuccess } from '../../redux-store/slices/organization';
import { usePopup } from '../../components/context/popup-context';
import { defaultExchangeRate } from '../../data/default-value';

const LoginSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showSpinner, hideSpinner } = usePopup();

  useEffect(() => {
    showSpinner();
    axios
      .get(`${process.env.REACT_APP_SERVER_URI}/organizations`, {
        withCredentials: true,
      })
      .then(res => {
        localStorage.setItem('tenant', JSON.stringify(res.data.tenants[0]));
        localStorage.setItem('logo', res.data.logo?.tempUrl || '');
        localStorage.setItem('signature', res.data.signatureImage?.tempUrl || '');
        localStorage.setItem('tenantIndex', 0);
        localStorage.setItem('companyInfo', JSON.stringify({ company: res.data.company }));
        localStorage.setItem('invoiceName', JSON.stringify(res.data.invoiceName || ''));
        localStorage.setItem('bankInfo', JSON.stringify({ bank: res.data.bank }));
        localStorage.setItem('terms', JSON.stringify(res.data.terms ? res.data.terms : ''));
        localStorage.setItem('deliveryTerms', JSON.stringify(res.data.deliveryTerms ? res.data.deliveryTerms : ''));
        localStorage.setItem('quotesTerms', JSON.stringify(res.data.quotesTerms ? res.data.quotesTerms : ''));
        localStorage.setItem(
          'voucherValidation',
          JSON.stringify(res.data.voucherValidation ? res.data.voucherValidation : {})
        );
        localStorage.setItem('user', JSON.stringify(res.data.user ? res.data.user : {}));
        localStorage.setItem('apiKey', JSON.stringify(res.data.apiKey ? res.data.apiKey : ''));
        localStorage.setItem('exchangeRate', JSON.stringify(Number(res.data?.exchangeRate) || defaultExchangeRate));
        dispatch(switchOrganizationSuccess({ apiKey: res.data.apiKey || '' }));
        dispatch(login());
        history.push('/dashboard');
      })
      .catch(err => {
        console.error('failed to fetch organization', err);
      }).finally(() => hideSpinner());
  }, [history, dispatch]);

  return <Box>Redirecting...</Box>;
};

export default LoginSuccess;
