import { Typography } from "@material-ui/core";
import { Grid, makeStyles, Box } from "@material-ui/core";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";

const useStyles = makeStyles({
  container: {
    marginTop: "1em",
  },
  position: {
    color: "black",
  },
  positionBorder: {
    borderTop: "1px solid",
    borderBottom: "3px double",
  },
  border: {
    borderBottom: "1px solid",
    textAlign: "center",
  },
  signature: {
    alignSelf: "flex-end",
  },
  policy: {
    backgroundColor: "lightgray",
    border: "1px solid",
    padding: "10px",
    width: "100%",
  },
  document: {
    textAlign: "end",
    paddingRight: "50px",
  },
  iconWithText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

const AttachedDocuments = () => {
  const classes = useStyles();
  const voucherValidation = JSON.parse(
    localStorage.getItem("voucherValidation")
  );
  const column = [3, 2, 2, 2, 2];
  
  const checkBoxGourp = () => (
    <Box display="flex" justifyContent="flex-end">
      <Box className={classes.iconWithText}>
        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
        <span>Yes</span>
      </Box>
      <Box className={classes.iconWithText}>
        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
        <span>No</span>
      </Box>
      <Box className={classes.iconWithText}>
        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
        <span>NA</span>
      </Box>
    </Box>
  );
  return (
    <Grid container className={classes.container} style={{ lineHeight: 1.5 }}>
      <Grid item xs={12}>
        <Typography>Attached Supporting Documents:</Typography>
      </Grid>
      <Grid container item xs={6} className={classes.document}>
        <Grid container item spacing={1}>
          <Grid item>1.Purchase Request</Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>2.Purchase Order</Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>3.Delivery Note</Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>4.Goods Received Note</Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={6} className={classes.document}>
        <Grid container item spacing={1}>
          <Grid item>5.Invoice/Debit Note</Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>6.Official Receipt</Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>7.Cheque/Bank Transfer Slip</Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>8.Copied ID Card </Grid>
          <Grid item xs={true}>
            {checkBoxGourp()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AttachedDocuments;
