import { forwardRef } from "react";
import { makeStyles, Box, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ToWords } from "to-words";
import commaNumber from "comma-number";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import { numberToWord } from "number_to_khmer_word";

import VoucherListing from "./voucher-listing";
import Footer from "./footer";
import { roundDecimal } from "../../../utility";
import moment from "moment";
import AttachedDocuments from "./attached-document";
import Signature from "./signature";
// import InvoiceListing from "../invoice-listing";

const useStyles = makeStyles({
  pdfWrapper: {},
  container: {
    margin: "0 auto",
    fontSize: 11,
  },
  header: {
    textAlign: "center",
  },
  pagebreak: {
    pageBreakBefore: "always",
    position: "relative",
  },
  headerWrpper: {
    width: "65%",
    display: "inline-block",
  },
  logo: {
    textAlign: "center",
  },
  iconWithText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  pageNumber: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

// max height of page: 976px

const PaymentVoucherTemplate = (
  {
    data,
    invoiceType,
    contentHeights,
    footHeights,
    exchangeRate,
    payment,
    printScale = false,
    image = null,
  },
  ref,
) => {
  const classes = useStyles();
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const { company } = JSON.parse(localStorage.getItem("companyInfo"));
  const toWords = new ToWords({
    localeCode: "en-US",
    converterOptions: {
      currency: true,
    },
  });

  let allHeight,
    pageHeight = 950,
    exceedPage;
  let footerPadding = [];
  let attachedHeight = 0,
    signatureHeight = 0,
    footerHeight = 0;
  let twoHeights = signatureHeight + footerHeight;
  let threeHeights = attachedHeight + signatureHeight + footerHeight;

  if (contentHeights) {
    for (let i = 0; i < contentHeights.length; i++) {
      footerPadding[i] = [0, 0, 0];
      allHeight = contentHeights[i] + threeHeights;
      if (allHeight > pageHeight) {
        exceedPage =
          (contentHeights[i] % pageHeight) + threeHeights - pageHeight;
        if (exceedPage > 0) {
          if (exceedPage <= footerHeight)
            footerPadding[i][2] = footerHeight - exceedPage;
          else if (exceedPage <= twoHeights)
            footerPadding[i][1] = twoHeights - exceedPage;
          else footerPadding[i][0] = threeHeights - exceedPage;
        }
      }
    }
  }

  return (
    <Box className={classes.pdfWrapper}>
      <div ref={ref} className={classes.pdf}>
        {data.map((invoice, key) => {
          const poboxaddress = invoice.contact?.addresses.filter(
            (address) => address.addressType === "POBOX",
          )[0];
          const contactPhone = invoice.contact?.phones?.filter(
            (phone) => phone?.phoneType === "DEFAULT",
          )[0];
          const trackingArray = [];
          invoice.lineItems.forEach((item) => {
            if (Array.isArray(item.tracking)) {
              item.tracking.forEach((value) => {
                if (value.name && !trackingArray.includes(value.name)) {
                  trackingArray.push(value.name);
                }
              });
            }
          });
          const header = (
            <Box>
              {image ? (
                <img
                  style={{ width: 110, position: "absolute" }}
                  src={image}
                  alt=""
                />
              ) : (
                tenant.logo.length > 0 && (
                  <img
                    style={{ width: 110, position: "absolute" }}
                    src={tenant.logo}
                    alt=""
                  />
                )
              )}
              <Box className={classes.header}>
                <Box className={classes.headerWrpper}>
                  <Typography variant="h1">{company?.name}</Typography>
                  <Typography style={{ textTransform: "uppercase" }}>
                    {tenant.tenantName}
                  </Typography>
                  <Typography variant="h3">
                    លេខអត្តសញ្ញាណកម្មសារពើពន្ធ {invoice.taxId}
                  </Typography>
                  <Typography variant="h5">{company?.address}</Typography>
                  <Typography variant="h5">
                    {`${
                      poboxaddress?.addressLine1
                        ? poboxaddress?.addressLine1
                        : ""
                    } ${
                      poboxaddress?.addressLine2
                        ? poboxaddress?.addressLine2
                        : ""
                    } ${
                      poboxaddress?.addressLine3
                        ? poboxaddress?.addressLine3
                        : ""
                    } ${
                      poboxaddress?.addressLine4
                        ? poboxaddress?.addressLine4
                        : ""
                    }`}
                  </Typography>
                  <Typography variant="h5">
                    លេខទូរសព្ទ / Telephone No: (
                    {tenant.phones[0]?.phoneAreaCode ||
                      tenant.phones[0]?.phoneCountryCode}
                    ){" "}
                    {tenant.phones[0]?.phoneNumber.charAt(0) === "0"
                      ? tenant.phones[0]?.phoneNumber.substring(1)
                      : tenant.phones[0]?.phoneNumber}
                  </Typography>
                </Box>
                <Typography
                  variant="h2"
                  align="right"
                  style={{ paddingRight: "15%" }}
                >
                  RV#:
                </Typography>
              </Box>
              <Divider />
              <Box style={{ textAlign: "center", margin: 15 }}>
                <Typography variant="h1">ប័ណ្ណទទួលប្រាក់</Typography>
                <Typography variant="h1" style={{ fontWeight: "bold" }}>
                  RECEIPT VOUCHER
                </Typography>
              </Box>
            </Box>
          );
          return (
            <div
              key={key}
              className={clsx([classes.container, classes.pagebreak])}
            >
              <div id="contentWrapper">
                {header}
                <Grid container style={{ lineHeight: 1.5 }}>
                  <Grid container item xs={6}>
                    <Grid container item>
                      <Grid item xs={4}>
                        Company:
                      </Grid>
                      <Grid item xs={7}>
                        {invoice.contact?.name}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={4}>
                        Phone:
                      </Grid>
                      <Grid item xs={7}>
                        {contactPhone.phoneNumber}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid container item>
                      <Grid item xs={4}>
                        Payee:
                      </Grid>
                      <Grid item xs={7}>
                        {/* {invoice.contact?.name} */}
                        {`${invoice.contact?.firstName || ''} ${invoice.contact?.lastName || ''}`}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={4}>
                        VATTIN:
                      </Grid>
                      <Grid item xs={7}>
                        {invoice.contact?.taxNumber}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item style={{ alignItems: "center" }}>
                    <Grid item xs={2}>
                      Paid by:
                    </Grid>
                    <Grid item xs={4} style={{ paddingRight: "10px" }}>
                      <Box display="flex" justifyContent="space-around">
                        <Box className={classes.iconWithText}>
                          <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                          <span>Cash</span>
                        </Box>
                        <Box className={classes.iconWithText}>
                          <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                          <span>Cheque</span>
                        </Box>
                        <Box className={classes.iconWithText}>
                          <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                          <span>Bank Transfer</span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      Bill Reference #:
                    </Grid>
                    <Grid item xs={3}>
                      {invoice.invoiceNumber}
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={2}>
                      Payment Date :
                    </Grid>
                    <Grid item xs={2}>
                      {Array.isArray(invoice?.payments) &&
                      invoice?.payments.length > 0
                        ? moment(
                            invoice?.payments[invoice?.payments.length - 1]
                              .date,
                          ).format("DD-MM-YYYY")
                        : ""}
                    </Grid>
                    <Grid item xs={1} >
                      Paid From :
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: 5 }}>
                      {payment.map((value) => {
                        if (
                          Array.isArray(invoice?.payments) &&
                          invoice?.payments.length > 0 &&
                          value.paymentID ===
                            invoice?.payments[invoice?.payments.length - 1]
                              .paymentID
                        )
                          return value.account.name ? value.account.name : "";
                        return "";
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      Payment Reference #:
                    </Grid>
                    <Grid item xs={2}>
                      {Array.isArray(invoice?.payments) &&
                      invoice?.payments.length > 0
                        ? invoice?.payments[invoice?.payments.length - 1]
                            .reference
                        : ""}
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={3}>
                      Payee Bank Account Name:
                    </Grid>
                    <Grid item xs={3}>
                      {invoice.contact?.batchPayments?.bankAccountName}
                    </Grid>
                    <Grid item xs={3}>
                      Payee Account Number:
                    </Grid>
                    <Grid item xs={3}>
                      {invoice.contact?.batchPayments?.bankAccountNumber}
                    </Grid>
                  </Grid>

                  {(exchangeRate || invoice.currencyCode === "KHR") && (
                    <Grid container item>
                      <Grid item xs={3}>
                        Amount in KHR :
                      </Grid>
                      <Grid item xs={3}>
                        {invoice.currencyCode === "USD"
                          ? `${commaNumber(
                              roundDecimal({
                                value: invoice.total * Number(exchangeRate),
                              }),
                            )} ៛`
                          : `${commaNumber(
                              roundDecimal({
                                value: invoice.total,
                              }),
                            )} ៛`}
                      </Grid>
                      {exchangeRate && (
                        <Grid item xs={3}>
                          Exchange rate:
                        </Grid>
                      )}
                      {exchangeRate && (
                        <Grid item xs={3}>
                          {commaNumber(exchangeRate)} ៛
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {(exchangeRate || invoice.currencyCode === "USD") && (
                    <Grid container item>
                      <Grid item xs={3}>
                        Amount in USD :
                      </Grid>
                      <Grid item xs={7}>
                        {invoice.currencyCode === "USD"
                          ? `${commaNumber(invoice.total)} $`
                          : `${commaNumber(
                              roundDecimal({
                                value: invoice.total / Number(exchangeRate),
                              }),
                            )} $`}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item>
                    <Grid item xs={3}>
                      Amount in words:
                    </Grid>
                    <Grid item xs={7}>
                      {invoice.currencyCode === "USD"
                        ? toWords.convert(invoice.total).replace("Only", "")
                        : numberToWord(invoice.total)}
                    </Grid>
                  </Grid>
                </Grid>
                <VoucherListing
                  invoice={invoice}
                  currencyType={invoice.currencyCode === "USD" ? "$" : "៛"}
                  invoiceType={invoiceType}
                  tracking={trackingArray}
                />
              </div>
              <div
                style={{
                  paddingTop: `${
                    !printScale && attachedHeight !== 0 && footerPadding[key][0]
                  }px`,
                }}
              >
                <AttachedDocuments />
              </div>
              <div
                style={{
                  paddingTop: `${
                    !printScale &&
                    signatureHeight !== 0 &&
                    footerPadding[key][1]
                  }px`,
                  // paddingTop: `100px`
                }}
              >
                <Signature />
              </div>
              <div
                style={{
                  paddingTop: `${
                    !printScale && footerHeight !== 0 && footerPadding[key][2]
                  }px`,
                }}
              >
                <Footer />
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default forwardRef(PaymentVoucherTemplate);
