const taxExclusive = {
  commercial: ({ lineAmount, taxAmount }) => {
    return lineAmount + taxAmount;
  },
  tax: ({ lineAmount }) => {
    return lineAmount;
  },
};

const taxInclusive = {
  commercial: ({ lineAmount }) => {
    return lineAmount;
  },
  tax: ({ lineAmount, taxAmount }) => {
    return lineAmount - taxAmount;
  },
  quote: ({ lineAmount, taxAmount }) => {
    return lineAmount + taxAmount;
  },
};

const handleCalculateAmount = ({ amountTypes, invoiceType, lineAmount, taxAmount }) => {
  if (amountTypes.toLowerCase() === 'exclusive') {
    return taxExclusive[invoiceType === 'quote' ? 'commercial' : invoiceType]({ lineAmount, taxAmount });
  } else {
    return taxInclusive[invoiceType]({ lineAmount, taxAmount });
  }
};

export default handleCalculateAmount;
