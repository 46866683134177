import { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
// import qs from 'qs';
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import {
  Grid,
  makeStyles,
  Container,
  Switch,
  Input,
  Typography,
  TextField,
  debounce,
  Link as MUILink,
  // Button
} from "@material-ui/core";
import axios from "axios";
// import RefreshIcon from '@material-ui/icons/Refresh';
import { usePopup } from "../../components/context/popup-context";
import CustomTable, { paginationConfig } from "../../components/custom-table";
import DateTimeRangePicker from "../../components/date-time-range-picker";
import useQuery from "../../components/custom-hook/use-query";
import settingApi from "../../api/setting";
import PrintButton from "../../components/print-button";
import { getCreditNoteTemplate } from "./getTemplates";

const useStyles = makeStyles((theme) => ({
  container: {},
  datePicker: {
    border: "none",
    borderBottom: "1px solid grey",
    padding: "0.42em",
    fontSize: 16,
    zIndex: 100000000000000,
    width: "100%",
  },
  dataPickerWrapper: {
    width: "100%",
  },
  dataPickerPopper: {
    zIndex: 9,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  scaleSwitch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  switchLabel: {
    lineHeight: "100%",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
  },
  loadingContainer: {
    borderBottom: "none",
  },
  printButtonContainer: {
    padding: 2,
  },
  dateFilter: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  printButtonSection: {
    marginTop: theme.spacing(1) / 2,
  },
}));

const CreditNote = () => {
  const classes = useStyles();
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const [creditNote, setCreditNote] = useState([]);
  const [selectedCreditNote, setSelectedCreditNote] = useState([]);
  // const [selectedAll, setSelectedAll] = useState(false);
  const [printScale, setPrintScale] = useState(false);
  // const exchangeRate = JSON.parse(localStorage.getItem("exchangeRate"));
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const [exchangeRate, setExchangeRate] = useState(
    Number(JSON.parse(localStorage.getItem("exchangeRateInvoicesBills"))) ||
      4000,
  );
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const textFieldRef = useRef(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const invoiceName = JSON.parse(localStorage.getItem("invoiceName"));
  const {
    data,
    error,
    query,
    loading: fetchLoading,
  } = useQuery(`${process.env.REACT_APP_SERVER_URI}/credit-note`);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });
  const [date, setDate] = useState();
  const shouldDisablePrintButton =
    selectedCreditNote.length <= 0 ||
    exchangeRate === null ||
    exchangeRate === "";

  const schema = [
    {
      label: "Credit Note No.",
      field: "creditNoteNumber",
    },
    {
      label: "Invoice Date",
      field: "date",
      render: (dataProps) =>
        new Date(dataProps.date).toLocaleDateString("en-GB"),
    },
    {
      label: "Contact Name",
      field: "contact.name",
      render: (invoice) => (
        <MUILink
          component={Link}
          to={`/contacts/create/${invoice?.contact?._id}`}
          replace
        >
          {invoice?.contact?.name}
        </MUILink>
      ),
    },
    {
      label: "Sub Totals",
      tableCellProps: { align: "center" },
      field: "subTotal",
      render: (dataProps) => dataProps.subTotal.toLocaleString(),
    },
    {
      label: "Tax Total",
      tableCellProps: { align: "center" },
      field: "totalTax",
      render: (dataProps) => dataProps.totalTax.toLocaleString(),
    },
    {
      label: "Total",
      tableCellProps: { align: "center" },
      field: "total",
      render: (dataProps) => dataProps.total.toLocaleString(),
    },
    {
      label: "Remaining Credit",
      tableCellProps: { align: "center" },
      field: "remainingCredit",
    },
    {
      label: "Status",
      tableCellProps: { align: "center" },
      field: "status",
      render: (dataProps) => (
        <Typography variant="body2" style={{ textTransform: "capitalize" }}>
          {dataProps.status === "AUTHORISED"
            ? "awaiting payment"
            : dataProps?.status?.toLowerCase()}
        </Typography>
      ),
    },
  ];

  const handleBeforeGetContentCreditNote = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/invoices/get-selected-invoices`,
        {
          withCredentials: true,
          params: {
            creditNoteIds: selectedCreditNote.map((inv) => inv.creditNoteID),
          },
          paramsSerializer: (params) => {
            let queryString = "";
            params.creditNoteIds.forEach((item) => {
              queryString += `creditNoteIds=${item}&`;
            });
            return queryString;
          },
        },
      );
      const { data: response } = await settingApi.getSingleTenant(
        tenant.tenantId,
      );
      setCompanyLogo(response?.logo?.tempUrl || null);
      setCreditNote(res.data.invoices);
    } catch (err) {
      console.error("Failed to fetch the selected credit note", err);
      showAlertPopup({
        message:
          err?.response?.data?.message ||
          `Failed to fetch the selected credit note`,
        success: false,
      });
      throw err; // cancel print event
    }
  };

  const handleFetchCreditNoteData = async ({
    searchProps = null,
    pageProps = pagination.page,
    perPageProps = pagination.rowsPerPage,
    startDateProps = date?.startDate,
    endDateProps = date?.endDate,
  } = {}) => {
    try {
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // preserve searchProps when changing pagination
        ...(isNullish ? {} : { search: searchProps }),
        page: pageProps,
        perPage: perPageProps,
        startDate: startDateProps?.getTime(),
        endDate: endDateProps?.getTime(),
      });
      setPagination((pre) => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  //handle when Exchange Rate Value change
  const handleExchangeChange = (values) => {
    setExchangeRate(values.value);
    localStorage.setItem(
      "exchangeRateInvoicesBills",
      JSON.stringify(values.value),
    );
  };

  const handlePrintScaleSwitch = (e) => {
    setPrintScale(e.target.checked);
  };

  const handleSyncCreditNote = async () => {
    try {
      showSpinner();
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/credit-note/sync`, {
        withCredentials: true,
      });
      handleFetchCreditNoteData({ searchProps: null, pageProps: 0 });
    } catch (err) {
      console.error(err?.response);
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Credit Note`,
        success: false,
      });
    } finally {
      hideSpinner();
    }
  };

  const handleChangeCreditNotePage = (event, page) => {
    handleFetchCreditNoteData({ searchProps: null, pageProps: page });
  };

  const handleChangeCreditNoteRowsPerPage = (event) => {
    handleFetchCreditNoteData({
      searchProps: null,
      pageProps: 0,
      perPageProps: parseInt(event.target.value, 10),
    });
  };
  useEffect(() => {
    handleFetchCreditNoteData();
  }, [organization, date, trigger]);

  useEffect(() => {
    if (data) {
      setPagination((pre) => ({ ...pre, count: data.totalDocs }));
    }
  }, [data]);

  // handle search
  const debouncedCreditNoteResults = useMemo(() => {
    return debounce((text) => {
      handleFetchCreditNoteData({ searchProps: text, pageProps: 0 });
    }, 500);
  }, []);
  return (
    <Container>
      <h1>CREDIT NOTE</h1>
      <Grid container spacing={1}>
        <Grid container item spacing={0} direction="column" sm={12} md={6}>
          <Grid item>
            <Typography>Exchange Rate</Typography>
            <NumberFormat
              customInput={Input}
              thousandSeparator={true}
              suffix={" ៛"}
              placeholder="Exchange Rate (៛)"
              style={{ width: "100%" }}
              value={exchangeRate}
              onValueChange={(values) => {
                handleExchangeChange(values);
              }}
              error={
                selectedCreditNote.length > 0 &&
                (exchangeRate === null || exchangeRate === "")
              }
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Search :</Typography>
            <TextField
              inputRef={textFieldRef}
              onChange={(event) =>
                debouncedCreditNoteResults(event.target.value)
              }
              fullWidth
              placeholder="Filter by No. and Contact"
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.dateFilter}>
            <DateTimeRangePicker date={date} elevation={2} setDate={setDate} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.printButtonSection}>
        <PrintButton
          buttonLabel="CREDIT NOTE"
          onBeforeGetContent={() => handleBeforeGetContentCreditNote()}
          disabled={shouldDisablePrintButton}
          printComponent={() =>
            getCreditNoteTemplate({
              contentHeights: [],
              footerHeight: 0,
              signatureHeight: 0,
              data: creditNote,
              name: invoiceName,
              image: companyLogo,
              exchangeRate: exchangeRate,
            })
          }
        />
      </Grid>
      {/* <Button
          variant="contained"
          color="primary"
          style={{ textTransform: 'capitalize', }}
          startIcon={<RefreshIcon />}
          onClick={handleSyncCreditNote}
        >
          Sync
        </Button> */}
      <Grid container>
        <Grid container item xs="auto">
          <Typography className={classes.switchLabel}>
            Print With Scale :
          </Typography>
          <Switch
            className={classes.switch}
            color="primary"
            onChange={handlePrintScaleSwitch}
            checked={printScale}
          />
        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs}
        loading={fetchLoading}
        error={error}
        tableSize="medium"
        disableCheckBoxSelectPopup
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeCreditNotePage,
          onChangeRowsPerPage: handleChangeCreditNoteRowsPerPage,
        }}
        getSelectedRow={(arrayValue) => setSelectedCreditNote(arrayValue)}
      />
    </Container>
  );
};

export default CreditNote;
