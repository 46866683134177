import InvoiceTemplate from "../printing-page/default-template/invoice";
import InvoicePLTTemplate from "../printing-page/default-template/invoice-PLT";
import KongchakPicturesInvoice from "../printing-page/kongchak-pictures-template/invoice";
import ReceiptVoucher from "../printing-page/default-template/receipt-voucher";
import OnionMobilityInvoice from "../printing-page/onion-mobility-template/invoice";
import DepoVanlinInvoice from "../printing-page/depo-vanlin-template/invoice";
import DeliveryNoteTemplate from "../printing-page/default-template/delivery-note";
import DepoVanlinDeliveryNote from "../printing-page/depo-vanlin-template/delivery-note";
import UnregisterInvoiceTemplate from "../printing-page/default-template/unregister-invoice";
import SRMInvoiceTemplate from "../printing-page/SRM/invoice";

/**
 * template for unregister invoice PLT
 */
const getUnregisterInvoiceTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  printScale,
  exchangeRate,
  invoiceType,
  image,
}) => {
  if (name === "SRM") {
      return (
        <SRMInvoiceTemplate
          contentHeights={contentHeights}
          footerHeight={footerHeight}
          signatureHeight={signatureHeight}
          ref={ref}
          data={data}
          printScale={printScale}
          exchangeRate={exchangeRate}
          invoiceType={invoiceType}
          image={image}
        />
      )
  }
  return (
    <UnregisterInvoiceTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      ref={ref}
      data={data}
      printScale={printScale}
      exchangeRate={exchangeRate}
      invoiceType={invoiceType}
      image={image}
    />
  );
}

/**
 * template for invoice
 */
const getInvoiceTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  printScale,
  exchangeRate,
  invoiceType,
  image,
}) => {
  if (name === "kongchak-pictures") {
    return (
      <KongchakPicturesInvoice
        contentHeights={contentHeights}
        footerHeight={footerHeight}
        signatureHeight={signatureHeight}
        ref={ref}
        data={data}
        printScale={printScale}
        exchangeRate={exchangeRate}
        invoiceType={invoiceType}
        image={image}
      />
    );
  } else if (name === "onion-mobility") {
    return (
      <OnionMobilityInvoice
        contentHeights={contentHeights}
        footerHeight={footerHeight}
        signatureHeight={signatureHeight}
        ref={ref}
        data={data}
        printScale={printScale}
        exchangeRate={exchangeRate}
        invoiceType={invoiceType}
        image={image}
      />
    );
  } else if (name === "depo-vanlin") {
    return (
      <DepoVanlinInvoice
        contentHeights={contentHeights}
        footerHeight={footerHeight}
        signatureHeight={signatureHeight}
        ref={ref}
        data={data}
        printScale={printScale}
        exchangeRate={exchangeRate}
        invoiceType={invoiceType}
        image={image}
      />
    );
  }
  return (
    <InvoiceTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      ref={ref}
      data={data}
      printScale={printScale}
      exchangeRate={exchangeRate}
      invoiceType={invoiceType}
      image={image}
    />
  );
};

/**
 * template for invoice PLT
 */
const getInvoicePLTTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  printScale,
  exchangeRate,
  invoiceType,
  image,
}) => {
  return (
    <InvoicePLTTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      ref={ref}
      data={data}
      printScale={printScale}
      exchangeRate={exchangeRate}
      invoiceType={invoiceType}
      image={image}
    />
  );
}

/**
 * template for receipt-voucher
 */
const getReceiptVoucherTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  image,
  exchangeRate,
  payment = []
}) => {
  return (
    <ReceiptVoucher
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      exchangeRate={exchangeRate}
      ref={ref}
      data={data}
      image={image}
      payment={payment}
    />
  );
};

/**
 * template for delivery-note
 */
const getDeliveryNoteTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  ref,
  data,
  image,
}) => {
  if (name === "depo-vanlin") {
    return (
      <DepoVanlinDeliveryNote
        contentHeights={contentHeights}
        footerHeight={footerHeight}
        signatureHeight={signatureHeight}
        ref={ref}
        data={data}
        image={image}
      />
    );
  }
  return (
    <DeliveryNoteTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      ref={ref}
      data={data}
      image={image}
    />
  );
};

export {
  getInvoiceTemplate,
  getInvoicePLTTemplate,
  getDeliveryNoteTemplate,
  getReceiptVoucherTemplate,
  getUnregisterInvoiceTemplate,
};
