import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    pagebreakInside: "avoid",
    paddingTop: 10,
    fontWeight: "bold",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  imageContainer: {
    marginBottom: 5,
    height: 100,
    textAlign: "center",
  },
  signatureContainer: {
    borderTop: "1px solid black",
    marginBottom: 5,
  },
});

const SignatureSection = ({ company }) => {
  const classes = useStyles();

  return (
    <Grid container justify="space-evenly">
      <Grid item xs={4} className={classes.container}>
        <Box className={classes.imageContainer}></Box>
        <Box className={classes.signatureContainer}></Box>
        <Box>ហត្ថលេខា និង ឈ្មោះគណនេយ្យករ</Box>
        <Box>Signature &amp; Name of Accountant</Box>
      </Grid>
      <Grid item xs={4} className={classes.container}>
        <Box className={classes.imageContainer}>
          {/* {company?.signatureImage && (
            <img
              className={classes.image}
              src={company.signatureImage.url}
              alt=""
            />
          )} */}
        </Box>
        <Box className={classes.signatureContainer}></Box>
        <Box>ហត្ថលេខា និង ប្រធានផ្នែកគណនេយ្យ</Box>
        <Box>Signature &amp; Name of Accounting Manager</Box>
      </Grid>
    </Grid>
  );
};

export default SignatureSection;
