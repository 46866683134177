import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    pagebreakInside: "avoid",
    paddingTop: 10,
    fontWeight: "bold",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  imageContainer: {
    marginBottom: 5,
    height: 100,
    textAlign: "center",
  },
  signatureContainer: {
    borderTop: "1px solid black",
    marginBottom: 5,
  },
});

const SignatureSection = ({ company }) => {
  const classes = useStyles();

  return (
    <Grid container justify="space-evenly">
      <Grid item xs={3} className={classes.container}>
        <Box className={classes.imageContainer}></Box>
        <Box className={classes.signatureContainer}></Box>
        <Box>សេវ៉ាអតិថិជន</Box>
        <Box>Customer Service</Box>
      </Grid>
      <Grid item xs={3} className={classes.container}>
        <Box className={classes.imageContainer}>
          {company?.signatureImage && (
            <img
              className={classes.image}
              src={company.signatureImage.url}
              alt=""
            />
          )}
        </Box>
        <Box className={classes.signatureContainer}></Box>
        <Box>ប្រធានក្រុម</Box>
        <Box>
          Team leader
        </Box>
      </Grid>
      <Grid item xs={3} className={classes.container}>
        <Box className={classes.imageContainer}></Box>
        <Box className={classes.signatureContainer}></Box>
        <Box>អ្នកបច្ចេកទេស</Box>
        <Box>Technician</Box>
      </Grid>
      <Grid item xs={2} className={classes.container}>
        <Box className={classes.imageContainer}></Box>
        <Box className={classes.signatureContainer}></Box>
        <Box>ផ្នែកគ្រឿងបន្លាស់</Box>
        <Box>Part</Box>
      </Grid>
    </Grid>
  );
};

export default SignatureSection;
