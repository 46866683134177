import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  Avatar,
  Typography,
  Box,
  IconButton,
  debounce,
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom'
// import { useSnackbar } from "notistack";
// import qs from 'qs';

import "react-datepicker/dist/react-datepicker.css";

import CustomTable, { paginationConfig } from '../../components/custom-table';
import { usePopup } from '../../components/context/popup-context';
// import { logout } from "../../redux-store/slices/user";
import {
  makeStyles,
  Container,
} from "@material-ui/core";
import useQuery from "../../components/custom-hook/use-query";
import axios from "axios";
import { generateLetterFromName } from "../utility";

const useStyles = makeStyles(( theme ) => ({
    container: {
      // maxHeight: 500,
    },
    button: {
      textTransform: 'capitalize',
      margin: `0px ${theme.spacing(1)}px`
    },
    actionButton:{
      color: theme.palette.error.main,
    },
    avatarStyle:{
      backgroundColor: theme.palette.primary.main,
      textTransform: 'uppercase',
      marginRight: theme.spacing(1),
    }
  }));

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showPromptPopup, showAlertPopup, hideSpinner, showSpinner } = usePopup();
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const { data, error, query, loading } = useQuery(`${process.env.REACT_APP_SERVER_URI}/contacts`)
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });

  const schema = [
    {
      label: 'Name',
      sortable: true,
      field: 'name',
      tableCellProps:{ width:"auto" },
      render: ( data ) => {
        return(
          <Box display="flex" alignItems="center">
            <Avatar
              className={classes.avatarStyle}
            >
              {generateLetterFromName(data.name)}
            </Avatar>
            <Box>
              <Typography>{data.name}</Typography>
              <Typography variant="body2" color="textSecondary">{data.emailAddress}</Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      label: 'Contact',
      tableCellProps:{ align:"center" },
      render: ( data ) => {
        // get "default" phoneType from array of phones
        const contact = Array.isArray(data.phones)
        ? data.phones.filter( phone => phone.phoneType === 'DEFAULT' )[0]
          : {};
        return(
          <Typography variant="body2" color="textSecondary">
            {`${contact.phoneCountryCode ?? ""} ${contact.phoneAreaCode ?? ""} ${contact.phoneNumber ?? ""}`}
          </Typography>
        );
      }
    },
    // {
    //   label: 'Type',
    //   field: 'type' 
    // },
    {
      label: 'Status',
      tableCellProps:{ align:"center" },
      field: 'contactStatus'
    },
    {
      label: 'Action',
      tableCellProps:{ align:"right" },
      render: ( data ) => {
        return(
          <Box display="flex" justifyContent="end">
            <IconButton 
              aria-label="edit" 
              color="primary"
              onClick={()=> { 
                handleUpdateContact( data._id );
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={classes.actionButton}
              onClick={()=> { 
                showPromptPopup({
                  handleConfirm: () => {
                    handleDeleteContact( data.contactID );
                  }
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      }
    },
  ];

  const handleFetchContactData = async ( searchProps, pageProps = pagination.page, perPageProps = pagination.rowsPerPage ) => {
    try{
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // when searchProps is 'null' or 'undefined' it won't be used.
        // so the searchProps stay the same. this effect happen when changing pagination
        ...( isNullish ? {} : { search: searchProps } ),
        page: pageProps,
        perPage: perPageProps,
      });
      setPagination( pre => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }) )
    } catch ( err ) {
      console.error( err );
    }
  }

  useEffect( ( ) => {
    handleFetchContactData();
  }, [organization, trigger]);
  
  // handle search
  const debouncedContactResults = useMemo(() => {
    return debounce((text) => {
      handleFetchContactData( text, 0 );
    //   setSearch(text);
    }, 300);
  }, []);

  // handle sync contact data from xero with DB
  const handleRefreshContact = async () =>{
    try {
      await axios.get(`${process.env.REACT_APP_SERVER_URI}/contacts/sync`, { withCredentials: true });
      handleFetchContactData();
    } catch ( err ) {
      console.error( err?.response )
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Contact`,
        success: false
      });
    }
  }

  const handleUpdateContact = ( id ) => {
    history.push(`/contacts/create/${id}`);
  }

  const handleCreateContact = ( ) => {
    history.push('/contacts/create')
  }

  const handleDeleteContact = async ( arrayId ) => {
    try {
      showSpinner();
      const tempArrayId = Array.isArray( arrayId ) ? arrayId : [arrayId]
      const querystring = `${(tempArrayId.map( ( id ) => `contactID=${id}` )).join("&")}`
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URI}/contact?${querystring}`,
        { withCredentials: true },
      );
      showAlertPopup({
        success: true,
      });
      handleFetchContactData();
    } catch ( err ) {
      showAlertPopup({
        message: err.response?.data?.message 
          ? err.response.data.message
            : `Fail To Delete Contact`,
        success: false
      });
      console.error( err?.response ?? err );
      
    } finally {
      hideSpinner();
    }
  }

  const handleDeleteMultipleContact = ( selectedData ) => {
    const arrayId = selectedData.map( ( item ) => item.contactID );
    return handleDeleteContact( arrayId );
  }

  useEffect( ( ) => {
    if( data ){
      setPagination( pre => ({ ...pre,  count: data.totalDocs  }) );
    }
  }, [data] )

  const handleChangeContactPage = ( event, page ) => {
    handleFetchContactData( null, page )
  }

  const handleChangeContactRowsPerPage = ( event ) => {
    handleFetchContactData( null, 0, parseInt(event.target.value, 10) )
  }

  return (
    <Container>
      <h1>Contacts</h1>
      <Grid container justify="space-between">
        <Grid container item  xs={12} sm={6}>
          <Grid item xs={12} >
            <TextField
              id="search"
              placeholder="Search..."
              variant="outlined"
              color="primary"
              size="small"
              fullWidth
              onChange={(event) => debouncedContactResults(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} justify="flex-end">
          {/* <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<RefreshIcon />}
              onClick={()=> { handleRefreshContact() }}
            >
              Sync
            </Button>
          </Grid> */}
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => handleCreateContact()}
            >
              New Contact
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs ?? []}
        loading={loading}
        error={error}
        handleDeleteMultiple={handleDeleteMultipleContact}
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeContactPage,
          onChangeRowsPerPage: handleChangeContactRowsPerPage,
        }}
      />
    </Container>
  );
};

export default Contacts;
