import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0167AD',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
      dark: red.A700,
    },
    background: {
      default: '#fff',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 85,
      [`(orientation: portrait)`]: {
        minHeight: 68,
      },
    },
  },
  typography: {
    fontFamily: [
      'poppins',
      'Roboto',
      'Siemreap',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      'khmer-os-battambang',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'Moul',
      fontSize: 14,
      margin: 5,
    },
    h2: {
      fontSize: 11,
      margin: 5,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 11,
      margin: 7.5,
    },
    h5: {
      fontSize: 9,
      margin: 5,
    },
  },
});

export default theme;
