import { useEffect, useRef, useState } from 'react';

const initSize = { with: 0, height: 0 };

// get measure when id change to avoid to many render.
const useMeasure = (id) => {
  const ref = useRef(null);
  const [size, setSize] = useState(initSize);
  const [windowSize, setWindowSize] = useState(initSize);
  useEffect(() => {
    if (ref.current) {
      setSize({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
    }
    if (typeof window !== 'undefined') {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  }, [id]);

  return { ref, size, windowSize };
};

export default useMeasure;