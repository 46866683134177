import commaNumber from "comma-number";
import { roundDecimal } from "../utility";

/**
 * for convert price and make sure it is not `NaN` and have correct currency type,
 * is the price is NaN it will return default price ( `defaultPrice = ""` but it can be change through prop) 
 * @param {string} currencyCode use for determine which currency type to use, default `''`
 * @param {number} price use to calculate with exchange rate, default `0`
 * @param {string} exchangeRate use for calculate with price, default `1`
 * @param {*} format is a callback function that have `convertedPrice` and `currencyType`
 * @param {string} defaultPrice this will return instead is the price not valid, default `''`
 * @param {boolean} convert if `false` don't convert price, use when want to make sure price is not `NaN` and have correct currency type, default `true`
 * 
 * `Note`: since there are only two currency that convert back and forth (`'KHR'` and `'USD'`) we don't need check 
 * which currency to convert to or from but if there more than two currency this function should be update accordingly
 */
const convertPrice = ({
  currencyCode = '',
  price = 0,
  exchangeRate = 1,
  format,
  defaultPrice = '',
  convert = true,
} = {}) => {

  // convert price to number and round it
  let convertedPrice = roundDecimal({ value: price});

  // get currency symbol from currency code
  let currencyType = getCurrency({currencyTypeOrCode: currencyCode});

  // check is should be convert
  if ( convert ) {
    // convert base on currencyCode
    if ( currencyCode === 'USD' ) {
      // 'USD' convert 'KHR' so the currencyType should be 'KHR'
      currencyCode = 'KHR';
      currencyType = getCurrency({currencyTypeOrCode: currencyCode});
      convertedPrice = roundDecimal({ value: (convertedPrice * exchangeRate)});
    } else if ( currencyCode === 'KHR' ) {
      // 'KHR' convert 'USD' so the currencyType should be 'USD'
      currencyCode = 'USD';
      currencyType = getCurrency({currencyTypeOrCode: currencyCode});
      convertedPrice = roundDecimal({ value: (convertedPrice / exchangeRate)});
    }
  }

  // format and check is the price is valid and add comma to convertedPrice
  const formatPrice = isNaN(convertedPrice) ? defaultPrice : `${commaNumber(convertedPrice)}${currencyType}` ;

  // if format have been provided and it valid return the the provided format function
  if ( typeof format === 'function' ) {
    // check if the price is valid before return provided format function
    return isNaN(convertedPrice) 
      ? format({convertedPrice: defaultPrice, formattedPrice: commaNumber(convertedPrice), currencyType, currencyCode}) 
      : format({convertedPrice, formattedPrice: commaNumber(convertedPrice), currencyType, currencyCode})
  }

  return formatPrice;
}

/**
 * use for getting normal or converted `currencyType` or `currencyTypeCode` 
 * @param {string} currencyTypeOrCode can be `currencyType` or `currencyCode`. default `''`
 * @param {boolean} same 
 * * if `false`, check if currencyTypeOrCode as a `type`('៛', '$') the function will return back as `code`('KHR', 'USD') 
 * and vice versa. 
 * * if `true` if receive currencyTypeOrCode as a `type` will return back as `type` and the same for `code`.
 * * default `false`
 * @param {boolean} convert
 * * if `false`, return currency as it is
 * * if `true`, convert to different currency
 * * default `false`
 * 
 * `Note`: since there are only two currency that convert back and forth (KHR and USD) we don't need check 
 * which currency to convert to or from but if there more than two currency this function should be update accordingly
 */
const getCurrency = ({currencyTypeOrCode = '', same = false, convert = false}) => {
  // availableCurrency can only have two element and it will convert back and froth between those two element
  const availableCurrency = { KHR: '៛', USD: '$' } 

  // convert to the opposite
  const getOpposite = (index) => {
    if(index === 0) return 1;
    if(index === 1) return 0;
    return;
  }

  // convert to [['KHR','៛'], ['USD', '$']]
  const arrayAvailableCurrency = Object.entries(availableCurrency) 
  let currencyIndex = -1
  let codeTypeIndex = -1

  // find which currency is being use 
  arrayAvailableCurrency.find(( availableItem ,availableIndex) => {
    const tempIndex = availableItem.indexOf(currencyTypeOrCode)
    if ( tempIndex >= 0 ) {
      // get currency
      currencyIndex = availableIndex;
      // get type or code of currency
      codeTypeIndex = tempIndex;
      return true
    }
    return false
  });

  // if have location and type or code of currency
  if( currencyIndex >= 0 && codeTypeIndex >= 0 )  {
    if(same) {
      return convert 
      ? arrayAvailableCurrency[getOpposite(currencyIndex)][codeTypeIndex] 
      : arrayAvailableCurrency[currencyIndex][codeTypeIndex]
    }
      return convert 
      ? arrayAvailableCurrency[getOpposite(currencyIndex)][getOpposite(codeTypeIndex)] 
      : arrayAvailableCurrency[currencyIndex][getOpposite(codeTypeIndex)]
  }

  // if write normally the condition below if have the functionality as the above
  // if(currency === 'KHR' ) {
  //   if(same) {
  //     return convert ? 'USD' : 'KHR'
  //   }
  //   return convert ? '$' : '៛'
  // }
  // if(currency === '៛' ) {
  //   if(same) {
  //     return convert ? '$' : '៛'
  //   }
  //   return convert ? 'KHR' : 'USD'
  // }
  // if(currency === 'USD' ) {
  //   if(same) {
  //     return convert ? 'KHR' : 'USD'
  //   }
  //   return convert ? '៛' : '$'
  // }
  // if(currency === '$' ) {
  //   if(same) {
  //     return convert ? '៛' : '$'
  //   }
  //   return convert ? 'KHR' : 'USD'
  // }

  // if currencyTypeOrCode being use not match availableCurrency
  return '';
}

export { 
  convertPrice as default, // use this as default component when import
  getCurrency, 
};