import { forwardRef } from "react";
import { makeStyles, Box, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

import InvoiceListing from "./invoice-listing";
import SignatureSection from "./signature-section";
import Footer from "./footer";
import { removeAllSpaceFromString } from "../../../utility";
import { defaultStyles } from './config'

const useStyles = makeStyles({
  pdfWrapper: {

  },
  container: {
    margin: "0 auto",
    fontSize: defaultStyles.fontSize,
    position: "relative",
  },
  header: {
    textAlign: "center",
    // position: "fixed",
    top: 0,
    width: "100%",
    // marginBottom: "300px",
  },
  logo: {
    width: 110,
    position: "absolute",
    left: 0,
  },
  body: {
    // marginTop: "200px",
  },
  pagebreak: {
    pageBreakBefore: "always",
  },
  headerWrpper: {
    width: "65%",
    display: "inline-block",
  },
  h1FontSize: {
    fontSize: defaultStyles.fontSize + 3
  },
  h5FontSize: {
    fontSize: defaultStyles.fontSize + 1
  },
});

// max-height: 976px

const SRMInvoiceTemplate = (
  {
    data,
    invoiceType,
    contentHeights,
    footerHeight,
    exchangeRate,
    signatureHeight,
    printScale = false,
    image = null,
  },
  ref,
) => {
  const classes = useStyles();
  const tenant = JSON.parse(localStorage.getItem("tenant"));
  const { company } = JSON.parse(localStorage.getItem("companyInfo"));

  const getInvoiceNo = (invoiceNo) => {
    const [, invoiceNumber] = invoiceNo.split("-");

    if (invoiceType === "commercial" && company?.commercialInvoicePrefix) {
      return `${company?.commercialInvoicePrefix}-${invoiceNumber}`;
    } else if (invoiceType === "tax" && company?.taxInvoicePrefix) {
      return `${company?.taxInvoicePrefix}-${invoiceNumber}`;
    }

    return invoiceNo;
  };

  let footerPadding = [],
    signaturePadding = [],
    allHeight,
    pageHeight = 976;
  contentHeights &&
    contentHeights.forEach((height, idx) => {
      allHeight = height + signatureHeight + footerHeight;
      if (allHeight > pageHeight) {
        footerPadding[idx] = signaturePadding[idx] = 0;
        const exceedPage = (allHeight =
          (height % pageHeight) + signatureHeight + footerHeight - pageHeight);
        if (exceedPage > 0) {
          if (exceedPage <= footerHeight) {
            footerPadding[idx] = footerHeight - exceedPage;
          } else
            signaturePadding[idx] = footerHeight + signatureHeight - exceedPage;
        }
      }
    });

  return (
    <Box className={classes.pdfWrapper}>
      <div ref={ref} className={classes.testing}>
        {data.map((invoice, key) => {
          const streetaddress = invoice.contact?.addresses.filter(
            (address) => address.addressType === "STREET",
          )[0];
          const poboxaddress = invoice.contact?.addresses.filter(
            (address) => address.addressType === "POBOX",
          )[0];

          const streetaddressString = `${
            streetaddress?.addressLine1 ? streetaddress.addressLine1 : ""
          } ${streetaddress?.addressLine2 ? streetaddress.addressLine2 : ""} ${
            streetaddress?.addressLine3 ? streetaddress.addressLine3 : ""
          } ${streetaddress?.addressLine4 ? streetaddress.addressLine4 : ""}`;

          const poboxaddressString = `${
            poboxaddress?.addressLine1 ? poboxaddress?.addressLine1 : ""
          } ${poboxaddress?.addressLine2 ? poboxaddress?.addressLine2 : ""} ${
            poboxaddress?.addressLine3 ? poboxaddress?.addressLine3 : ""
          } ${poboxaddress?.addressLine4 ? poboxaddress?.addressLine4 : ""}`;

          return (
            <div
              key={key}
              className={clsx([classes.container, classes.pagebreak])}
            >
              <div>
                <Box className={classes.header}>
                  {image ? (
                    <img className={classes.logo} src={image} alt="" />
                  ) : (
                    tenant.logo.length > 0 && (
                      <img className={classes.logo} src={tenant.logo} alt="" />
                    )
                  )}
                  <Box className={classes.headerWrpper}>
                    <Typography variant="h1" className={clsx([classes.h1FontSize])}>{company?.name}</Typography>
                    <Typography style={{ textTransform: "uppercase" }}>
                      {/* {tenant.tenantName} */}
                      {"Steel & Zinc Supply Warehouse"}
                    </Typography>
                    {/* <Typography variant="h3">
                      លេខអត្តសញ្ញាណកម្មសារពើពន្ធ {invoice.taxId}
                    </Typography>
                    <Typography variant="h5">{company?.address}</Typography>
                    <Typography variant="h5">
                      {tenant.addresses[0]?.addressLine1}
                    </Typography> */}
                    <Typography variant="h5" className={clsx([classes.h5FontSize])}>
                      លេខទូរសព្ទ / Telephone No: (
                      {tenant.phones[0]?.phoneAreaCode ||
                        tenant.phones[0]?.phoneCountryCode}
                      ){" "}
                      {tenant.phones[0]?.phoneNumber.charAt(0) === "0"
                        ? tenant.phones[0]?.phoneNumber.substring(1)
                        : tenant.phones[0]?.phoneNumber}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box style={{ textAlign: "center", margin: 15 }}>
                    <Typography variant="h1" className={clsx([classes.h1FontSize])}>
                      {invoiceType === "commercial"
                        ? "វិក្កយបត្រ"
                        : "វិក្កយបត្រអាករ"}
                    </Typography>
                    <Typography variant="h1" className={clsx([classes.h1FontSize])}>
                      {invoiceType === "commercial" ? "INVOICE" : "TAX INVOICE"}
                    </Typography>
                  </Box>
                </Box>
                <Grid container>
                  {/* customer info */}
                  <Grid
                    className={classes.body}
                    container
                    item
                    xs={7}
                    alignContent="flex-start"
                    style={{ lineHeight: 1.5 }}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        ឈ្មោះក្រុមហ៊ុន ឬ អតិថិជន៖
                      </Grid>
                      <Grid item xs={7}>
                        {invoice.contact?.skypeUserName}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        Company/Customer៖
                      </Grid>
                      <Grid item xs={7}>
                        {invoice.contact?.name}
                      </Grid>
                    </Grid>
                    {removeAllSpaceFromString(streetaddressString).length >
                      0 && (
                      <Grid container item xs={12}>
                        <Grid item xs={5}>
                          អាសយដ្ឋាន៖
                        </Grid>
                        <Grid item xs={7}>
                          {streetaddressString}
                        </Grid>
                      </Grid>
                    )}
                    {removeAllSpaceFromString(poboxaddressString).length >
                      0 && (
                      <Grid container item xs={12}>
                        <Grid item xs={5}>
                          Address៖
                        </Grid>
                        <Grid item xs={7}>
                          {poboxaddressString}
                        </Grid>
                      </Grid>
                    )}

                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        ទូរសព្ទលេខ៖
                      </Grid>
                      <Grid item xs={7}>
                        {invoice.contact?.phones[0]?.phoneNumber
                          ? `${
                              invoice.contact?.phones[0]?.phoneCountryCode
                                ? `( ${invoice.contact?.phones[0]?.phoneCountryCode} ) `
                                : ``
                            }` + invoice.contact?.phones[0]?.phoneNumber
                          : "-"}
                      </Grid>
                    </Grid>
                    {/* <Grid container item xs={12}>
                      <Grid item xs={5}>
                        លេខអត្តសញ្ញាណកម្ម អតប៖
                      </Grid>
                      <Grid item xs={7}>
                        {invoice.contact?.taxNumber}
                      </Grid>
                      <Grid item xs={4}>
                        VAT TIN៖
                      </Grid>
                    </Grid> */}
                  </Grid>
                  {/* Invoice Section */}
                  <Grid
                    container
                    item
                    xs={5}
                    justify="flex-end"
                    alignContent="flex-start"
                    style={{ lineHeight: 1.5 }}
                  >
                    <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={5}>
                        លេខរៀងវិក្កយបត្រ៖
                      </Grid>
                      <Grid item xs={7}>
                        <b>{getInvoiceNo(invoice.invoiceNumber)}</b>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={5}>
                        ឯកសារយោង៖
                      </Grid>
                      <Grid item xs={7}>
                        <b>{invoice.reference}</b>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={5}>
                        កាលបរិច្ឆេទ៖
                      </Grid>
                      <Grid item xs={7}>
                        {moment(invoice.date).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                      <Grid item xs={5}>
                        កាលកំណត់បង់ប្រាក់៖
                      </Grid>
                      <Grid item xs={7}>
                        {moment(invoice.dueDate).format("DD-MMMM-YYYY")}
                      </Grid>
                    </Grid>
                    {/* <Grid item container xs={12} justify="flex-end">
                      <Grid item xs={5}>
                        Due Date៖
                      </Grid>
                      <Grid item xs={7} />
                    </Grid> */}
                    {/* <Grid container item justify='flex-end'>
                                    <Grid item xs={3}>
                                        អត្រាប្តូរប្រាក់/Exchange Rate៖
                                    </Grid>
                                    <Grid item xs={2}>
                                        { company?.exchangeRate.toLocaleString() }
                                    </Grid>
                                </Grid> */}
                  </Grid>
                </Grid>
                <InvoiceListing
                  invoice={invoice}
                  exchangeRate={exchangeRate}
                  invoiceType={invoiceType}
                />
              </div>
              <div
                style={{
                  paddingTop: `${
                    !printScale &&
                    signatureHeight !== 0 &&
                    signaturePadding[key]
                  }px`,
                }}
                // className="movableElement"
              >
                <SignatureSection company={company} />
              </div>
              {/* <div
                style={{
                  paddingTop: `${
                    !printScale && footerHeight !== 0 && footerPadding[key]
                  }px`,
                }}
                // className="movableElement"
              >
                <Footer />
              </div> */}
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default forwardRef(SRMInvoiceTemplate);
