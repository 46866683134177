import { nanoid } from 'nanoid';

import S3 from '../api/s3';

const generateKey = () => {
	return `${nanoid(5)}_${new Date().getTime()}`;
};

const useS3 = () => {
	/**
	 * update image to S3.
	 * files accept array and non array (if single file)
	 * @param {*} files
	 * @param {*} folderName
	 * @param {*} isPrivate
	 */
	const uploadImagesToS3 = async (files, folderName, isPrivate = false) => {
		const filesFromParams = Array.isArray(files) ? files : [files];

		const { arrayOfKeys, arrayOfContentTypes, arrayOfFilesToUpload, dataToUpdate } = filesFromParams.reduce(
			(prev, current) => {
				const fileType = current.type;
				const fileExtension = current.name.split('.')[1];
				const key = `${isPrivate ? 'private' : 'public'}/${folderName}/${generateKey()}`;
				const fileKey = `${key}.${fileExtension}`;

				const newArrayOfKeys = [...prev.arrayOfKeys, fileKey];
				const newArrayOfContentTypes = [...prev.arrayOfContentTypes, fileType];
				const newArrayOfFilesToUpload = [...prev.arrayOfFilesToUpload, current];
				const newDataToUpdate = [
					...prev.dataToUpdate,
					{ name: current.name, key: fileKey, mimeType: fileType },
				];

				return {
					arrayOfKeys: newArrayOfKeys,
					arrayOfContentTypes: newArrayOfContentTypes,
					arrayOfFilesToUpload: newArrayOfFilesToUpload,
					dataToUpdate: newDataToUpdate,
				};
			},
			{
				arrayOfKeys: [],
				arrayOfContentTypes: [],
				arrayOfFilesToUpload: [],
				dataToUpdate: [],
			}
		);

		// Get url to upload to
		const { data } = await S3.getFileUploadURL({
			ContentTypes: arrayOfContentTypes,
			Keys: arrayOfKeys,
			isPrivate,
		});

		// Upload to s3
		await Promise.all(
			data.map(async (item, index) => {
				return await S3.uploadToS3(item.uploadURL, arrayOfFilesToUpload[index], item.type);
			})
		);

		/**
		 * dataToUpdate = [
		 *  {
		 *    name,
		 *    key,
		 *    mimeType
		 *  }
		 * ]
		 */
		return dataToUpdate;
	};

	/**
	 * get file url from S3 by passing key
	 * @param {*} key
	 */
	const getPrivateFileURL = async key => {
		const { data } = await S3.getPrivateFileURL(key);
		return data.fileURL;
	};

	/**
	 * remove one or many file from S3
	 * @param {*} keys
	 */
	const removeFilesFromS3 = async keys => {
		// request body keys = [{ Key: 'key' }]
		await S3.removeFiles(keys);
	};

	return {
		uploadImagesToS3,
		getPrivateFileURL,
		removeFilesFromS3,
	};
};

export default useS3;
