import SthaniCommunicationsQuote from '../printing-page/kongchak-pictures-template/quote'
import QuoteTemplate from "../printing-page/default-template/quote";
import PickingSlipTemplate from '../printing-page/default-template/picking-slip';
import AKlasseAutoQuote from '../printing-page/a-klasse-auto/quote'

const getQuoteTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  data,
  printScale,
  exchangeRate,
  quoteType,
  image,
}) => {
  
  if (name === "a-klasse-auto") {
    return(
      <AKlasseAutoQuote
        contentHeights={contentHeights}
        footerHeight={footerHeight}
        signatureHeight={signatureHeight}
        data={data}
        printScale={printScale}
        exchangeRate={exchangeRate}
        quoteType={quoteType}
        image={image}
      />
    )
  }
  if (name === "kongchak-pictures") {
    return(
      <SthaniCommunicationsQuote
        contentHeights={contentHeights}
        footerHeight={footerHeight}
        signatureHeight={signatureHeight}
        data={data}
        printScale={printScale}
        exchangeRate={exchangeRate}
        quoteType={quoteType}
        invoiceType="tax"
        image={image}
      />
    )
  }
  return (
    <QuoteTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      data={data}
      printScale={printScale}
      exchangeRate={exchangeRate}
      quoteType={quoteType}
      image={image}
    />
  )
}

const getPickingSlipTemplate = ({
  name,
  contentHeights,
  footerHeight,
  signatureHeight,
  data,
  image,
}) => {
  return (
    <PickingSlipTemplate
      contentHeights={contentHeights}
      footerHeight={footerHeight}
      signatureHeight={signatureHeight}
      data={data}
      image={image}
    />
  )
}

export { getQuoteTemplate, getPickingSlipTemplate }