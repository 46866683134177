import React, { useRef, useState, useMemo, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Input, makeStyles, Switch, Button, TextField, Link as MUILink,debounce } from '@material-ui/core';
import NumberFormat from 'react-number-format';

// Icons
import RefreshIcon from '@material-ui/icons/Refresh';

import useQuery from '../../components/custom-hook/use-query';
import CustomTable, { paginationConfig } from '../../components/custom-table';
import DateTimeRangePicker from '../../components/date-time-range-picker';
import { usePopup } from '../../components/context/popup-context';
// import CreateDeliveryButton from '../invoice/components/create-delivery-button';
import settingApi from '../../api/setting';
import PrintButton from '../../components/print-button';
import { formatDate } from '../utility';

const useStyles = makeStyles( theme => ({
  container: {},
  datePicker: {
    border: "none",
    borderBottom: "1px solid grey",
    padding: "0.42em",
    fontSize: 16,
    zIndex: 100000000000000,
    width: "100%",
  },
  dataPickerWrapper: {
    width: "100%",
  },
  dataPickerPopper: {
    zIndex: 9,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  scaleSwitch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  switchLabel: {
    lineHeight: "100%",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
  },
  loadingContainer: {
    borderBottom: 'none'
  },
  printButtonContainer: {
    padding: 2
  },
  dateFilter: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  printButtonSection: {
    marginTop: theme.spacing(1) / 2, // theme.spacing(1) = 8px
  },
}));

const PurchaseOrders = () => {
  const classes = useStyles();
  const { data, error, query, loading: fetchLoading, } = useQuery(`${process.env.REACT_APP_SERVER_URI}/purchase-orders`)
  const { showAlertPopup, showSpinner, hideSpinner } = usePopup();
  const { organization } = useSelector((state) => state.organizationReducer);
  const { trigger } = useSelector((state) => state.filterReducer);
  const [companyLogo, setCompanyLogo] = useState(null);
  const tenant = JSON.parse(localStorage.getItem('tenant'));
  const invoiceName = JSON.parse(localStorage.getItem("invoiceName") || '""');  // prevent error when don't have "invoiceName" in local storage
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState([])
  const [exchangeRate, setExchangeRate] = useState(Number(JSON.parse(localStorage.getItem("exchangeRateInvoicesBills"))) || 4000);
  const [printScale, setPrintScale] = useState(false);
  const [date, setDate] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });
  const textFieldRef = useRef(null)  
  
  const shouldDisablePrintButton = selectedPurchaseOrder.length <= 0 || exchangeRate === null || exchangeRate === "";

  const handlePrintScaleSwitch = (e) => {
    setPrintScale(e.target.checked);
  }

  const handleExchangeRateChange = (values) => {
    setExchangeRate(values.value);
    localStorage.setItem("exchangeRateInvoicesBills", JSON.stringify(values.value));
  };

  const handleFetchPurchaseOrderData = async ({
    searchProps = null,
    pageProps = pagination.page,
    perPageProps = pagination.rowsPerPage,
    startDateProps = date?.startDate,
    endDateProps = date?.endDate,
  } = {}) => {
    try {
      const isNullish = searchProps === null || searchProps === undefined;
      await query({
        // preserve searchProps when changing pagination 
        ...(isNullish ? {} : { search: searchProps }),
        page: pageProps,
        perPage: perPageProps,
        startDate: startDateProps?.getTime(),
        endDate: endDateProps?.getTime(),
      });
      setPagination(pre => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }))
    } catch (err) {
      console.error(err);
    }
  }

  const debouncedPurchaseOrderResults = useMemo(() => {
    return debounce((text) => {
      handleFetchPurchaseOrderData({ searchProps: text, pageProps: 0 });
    }, 500);
  }, []);

  const handleRefreshPurchaseOrder = async () => {
    try {
      showSpinner();
      const test = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/purchase-orders/sync?deepSync`,
        {
          withCredentials: true,
        }
      );
      console.error(test.data)
      handleFetchPurchaseOrderData({ searchProps: null, pageProps: 0 })
    } catch (err) {
      console.error(err?.response);
      showAlertPopup({
        message: err?.response?.data?.message || `Fail To Refresh Invoice`,
        success: false
      });
    } finally {
      hideSpinner();
    }
  }
  const handleChangePurchaseOrderPage = (event, page) => {
    handleFetchPurchaseOrderData({ searchProps: null, pageProps: page })
  }
  const handleChangePurchaseOrderRowsPerPage = (event) => {
    handleFetchPurchaseOrderData({ searchProps: null, pageProps: 0, perPageProps: parseInt(event.target.value, 10) })
  }

  const handleBatchInvoiceContent = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URI}/purchase-orders/get-selected-purchase-orders`,
        {
          withCredentials: true,
          params: {
            purchaseOrderID: selectedPurchaseOrder.map((purchaseOrder) => purchaseOrder.purchaseOrderID),
          },
          paramsSerializer: (params) => {
            let queryString = "";
            params.purchaseOrderID.forEach((item) => {
              queryString += `purchaseOrderID=${item}&`;
            });
            return queryString;
          },
        }
      );
      const { data: response } = await settingApi.getSingleTenant(tenant.tenantId);
      setCompanyLogo(response?.logo?.tempUrl || null);
      setPurchaseOrderData(res.data.purchaseOrders);
    } catch (err) {
      console.error("Failed to fetch the selected Purchase Orders", err);
      throw err; // cancel print event
    }
  };

  const schema = [
    {
      label: 'Number',
      field: 'purchaseOrderNumber',
    },
    {
      label: 'Reference',
      field: 'reference',
    },
    {
      label: 'Supplier',
      field: 'contact.name',
    },
    {
      label: 'Date Raised',
      field: 'date',
      render: (purchaseOrder) => formatDate({ date: purchaseOrder.date})

    },
    {
      label: 'Delivery Date',
      field: 'deliveryDate',
      render: (purchaseOrder) => formatDate({ date: purchaseOrder.deliveryDate})
    },
    {
      label: 'Amount',
      field: 'total',
    },
    {
      label: 'Status',
      tableCellProps: { align: "center" },
      field: 'status',
    },
  ];

  useEffect(() => {
    handleFetchPurchaseOrderData();
  }, [organization, date, trigger]);
  useEffect(() => {
    if (data) {
      setPagination(pre => ({ ...pre, count: data.totalDocs }));
    }
  }, [data])

  return (
    <Container>
      <h1>Purchase Orders</h1>
      <Grid container spacing={1}>
        <Grid container item spacing={0} direction="column" sm={12} md={6}>
          <Grid item>
            <Typography>Exchange Rate</Typography>
            <NumberFormat
              customInput={Input}
              thousandSeparator={true}
              suffix={" ៛"}
              placeholder="Exchange Rate (៛)"
              style={{ width: "100%" }}
              value={exchangeRate}
              onValueChange={(values) => {
                handleExchangeRateChange(values);
              }}
              error={
                selectedPurchaseOrder.length > 0 &&
                (exchangeRate === null || exchangeRate === "")
              }
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Search :</Typography>
            <TextField
              inputRef={textFieldRef}
              onChange={(event) => debouncedPurchaseOrderResults(event.target.value)}
              fullWidth
              placeholder="Filter Number, Reference, Supplier"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.dateFilter}>
            <DateTimeRangePicker
              date={date}
              elevation={2}
              setDate={setDate}
              placeholder="Filter Date Raised, Delivery Date"
              onAfterChange={(props) => {
                return handleFetchPurchaseOrderData({
                  pageProps: 0,
                  startDateProps: props?.startDate,
                  endDateProps: props?.endDate,
                })
              }}
            />
          </Grid>
        
          {/* <Grid container item spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: 'capitalize', }}
                startIcon={<RefreshIcon />}
                onClick={handleRefreshPurchaseOrder}
              >
                Sync
              </Button>
            </Grid>
          </Grid> */}

        </Grid>
      </Grid>
      <CustomTable
        schema={schema}
        dataList={data?.docs}
        loading={fetchLoading}
        error={error}
        tableSize="medium"
        disableCheckBoxSelectPopup
        disableCheckBox
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangePurchaseOrderPage,
          onChangeRowsPerPage: handleChangePurchaseOrderRowsPerPage,
        }}
        getSelectedRow={(arrayValue) => setSelectedPurchaseOrder(arrayValue)}
      />
    </Container>
  )
}

export default PurchaseOrders