import { Grid, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    marginTop: "1em",
  },
  position: {
    color: "black",
  },
  positionBorder: {
    borderTop: "1px solid",
    borderBottom: "3px double",
  },
  border: {
    borderBottom: "1px solid",
    textAlign: "center",
  },
  signature: {
    alignSelf: "flex-end",
  },
  policy: {
    backgroundColor: "lightgray",
    border: "1px solid",
    padding: "10px",
    width: "100%",
  },
  document: {
    textAlign: "end",
    paddingRight: "50px",
  },
  iconWithText: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

const Signature = () => {
  const classes = useStyles();
  const voucherValidation = JSON.parse(
    localStorage.getItem("voucherValidation")
  );
  const column = [3, 2, 2, 2, 2];
  const signatureSectionData = [
    { name: "Signature" },
    {
      name: "Name",
      preparedBy: voucherValidation.preparedBy,
      reviewedBy: voucherValidation.reviewedBy,
      approvedBy: voucherValidation.approvedBy,
      receivedBy: voucherValidation.receivedBy,
    },
    { name: "Date" },
  ];
  const signatureSection = () =>
    signatureSectionData.map((signature, index) => (
      <Grid key={index} container item spacing={1} style={{ marginTop: "8px" }}>
        <Grid item xs={column[0]}>
          {signature.name}:
        </Grid>
        <Grid item xs={column[1]} className={classes.signature}>
          <Box className={classes.border}>
            <b>{signature.preparedBy && signature.preparedBy}</b>
          </Box>
        </Grid>
        <Grid item xs={column[2]} className={classes.signature}>
          <Box className={classes.border}>
            <b>{signature.reviewedBy && signature.reviewedBy}</b>
          </Box>
        </Grid>
        <Grid item xs={column[3]} className={classes.signature}>
          <Box className={classes.border}>
            <b>{signature.approvedBy && signature.approvedBy}</b>
          </Box>
        </Grid>
        <Grid item xs={column[4]} className={classes.signature}>
          <Box className={classes.border}>
            <b>{signature.receivedBy && signature.receivedBy}</b>
          </Box>
        </Grid>
      </Grid>
    ));
  return (
    <Grid container>
        <Grid
          container
          item
          spacing={1}
          style={{ textAlign: "center", marginTop: "8px" }}
        >
          <Grid item xs={column[0]}></Grid>
          <Grid item xs={column[1]}>
            <Box className={classes.positionBorder}>
              <b>
                Prepared by:
                <br />
                <span className={classes.position}>
                  {voucherValidation.preparedByPosition}
                </span>
              </b>
            </Box>
          </Grid>
          <Grid item xs={column[2]}>
            <Box className={classes.positionBorder}>
              <b>
                Reviewed by: <br />
                <span className={classes.position}>
                  {voucherValidation.reviewedByPosition}
                </span>
              </b>
            </Box>
          </Grid>
          <Grid item xs={column[3]}>
            <Box className={classes.positionBorder}>
              <b>
                Approved by: <br />
                <span className={classes.position}>
                  {voucherValidation.approvedByPosition}
                </span>
              </b>
            </Box>
          </Grid>
          <Grid item xs={column[4]}>
            <Box className={classes.positionBorder}>
              <b>
                Received by: <br />
                <span className={classes.position}>
                  {voucherValidation.receivedByPosition}
                </span>
              </b>
            </Box>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: "16px" }}></Grid>
        <Grid container item>
          {signatureSection()}
        </Grid>
      </Grid>
  );
};

export default Signature;
