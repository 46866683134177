import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, useController } from 'react-hook-form';
import {
  DatePicker,
} from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/styles';
  
const materialTheme = createMuiTheme({
  typography: {
    h5: {
      // selected year is 'h5' since we change 'h5: fontSize = 9px' in 'theme.js'
      // so we change it back to bigger fontSize 
      fontSize: '1.5rem',
    },
  }
});


const useStyles = makeStyles(() => ({
  root: {
    '& textarea': {
      paddingTop: '5px',
    },
  },
  yearList: {
    height: '50px',
    fontSize: '5rem'
  }
}));

const RHFDatePicker = ({
  name, // require
  control, // require, Optional when using FormProvider
  required,
  variant = 'standard',
  size = 'medium',
  label = "Date picker",
  fullWidth = true,
  defaultValue= '',
  className = '',
  rules,
  onChange,
  helperText= null,
  ...textFieldProps
}) => {

  const classes = useStyles();

  return (
    <Controller 
      name={name}
      control={control}
      rules={rules}
      render={(props, { invalid }) => {
        return (
          <ThemeProvider theme={materialTheme}>
            <DatePicker
              // disableToolbar
              autoOk
              fullWidth={fullWidth}
              format="DD MMM yyyy"
              margin="dense"
              label={label}
              value={props.value}
              className={classes.yearList}
              onChange={(data) => props.onChange(data)}
              error={invalid}
              okLabel=""
              cancelLabel=""
            />
          </ThemeProvider>
        );
      }}
    />
  );
};

export default RHFDatePicker;
