import { Container, Grid, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Terms from './terms';

import VoucherValidation from './voucher-validation';
import DeliveryTerms from './delivery-terms';
import ContainerWrapper from '../../components/container-wrapper';
import TenantInfoSection from './tenant-info-section';
import TermsAndConditions from './terms-and-conditions';

const TenantInfo = () => {
	const { organization } = useSelector(state => state.organizationReducer);

	const { company = {} } = JSON.parse(localStorage.getItem('companyInfo'));
	const voucherValidation = JSON.parse(localStorage.getItem('voucherValidation'));
	const { bank = {} } = JSON.parse(localStorage.getItem('bankInfo'));

	return (
		<Box mt={2} component={Container}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<TenantInfoSection />
				</Grid>
				<Grid item xs={12}>
					<ContainerWrapper>
						<TermsAndConditions field='terms' placeholder='Terms & Payment Advice' title='Terms & Payment Advice' />
					</ContainerWrapper>
				</Grid>
				<Grid item xs={12}>
					<ContainerWrapper>
						<TermsAndConditions field='quotesTerms' placeholder='Quotes Terms & Conditions' title='Quotes Terms & Conditions' />
					</ContainerWrapper>
				</Grid>
				<Grid item xs={12}>
					<ContainerWrapper>
						<TermsAndConditions field='deliveryTerms' placeholder='Delivery Terms & Condition' title='Delivery Terms & Condition' />
					</ContainerWrapper>
				</Grid>
				<Grid item xs={12}>
					<ContainerWrapper>
						<VoucherValidation voucherValidation={voucherValidation} />
					</ContainerWrapper>
				</Grid>
			</Grid>
		</Box>
	);
};

export default TenantInfo;
