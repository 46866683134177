import React, { useState } from 'react';

const ErrorContext = React.createContext({
  status: null,
  modifyStatus: () => {},
  errMessage: '',
  modifyErrMessage: () => {},
});

export const ErrorContextProvider = props => {
  const [status, setStatus] = useState(null);
  const [errMessage, setErrMessage] = useState(null);

  const handleStatus = status => {
    setStatus(status);
  };

  const handleErrMessage = errMessage => {
    setErrMessage(errMessage);
  };

  return (
    <ErrorContext.Provider
      value={{
        status: status,
        modifyStatus: handleStatus,
        errMessage: errMessage,
        modifyErrMessage: handleErrMessage,
      }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
