import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Drawer, IconButton, Divider, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { version } from '../../../package.json'

import DrawerMenu from './menu';

const drawerWidth = process.env.REACT_APP_DRAWER_WIDTH;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: `${drawerWidth}px`,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: `${drawerWidth}px`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    "& span":{
      visibility: 'hidden'
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const Sidebar = ({ handleDrawerOpen, open }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { user } = useSelector(state => state.userReducer);

  const getEnvironment = ( ) => {
    if ( process.env.NODE_ENV === "development" ) {
      return "Dev - "
    }

    if ( process.env.NODE_ENV === "test" ) {
      return "Test - "
    }

    return "";
  }

  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: !open,
        [classes.drawerClose]: open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !open,
          [classes.drawerClose]: open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <img src={'/logo.png'} style={{ width: '70%' }} alt='' />
        <IconButton onClick={handleDrawerOpen}>
          {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Divider />
      {/* {user.isApproved && <DrawerMenu />} */}
      <DrawerMenu />
      <Typography 
        variant='caption'
        color='textSecondary'
        style={{ 
          position: 'absolute',
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        Version: {getEnvironment()}{version}
      </Typography>
    </Drawer>
  );
};

export default Sidebar;
